import { useState } from 'react';

const useSort = () => {
  const [sortOrder, setSortOrder] = useState('');
  const [sortField, setSortField] = useState('');

  const sort = (sortBy) => {
    const newSortOrder = sortBy !== sortField ? 'asc' : sortOrder === 'asc' ? 'desc' : sortOrder === 'desc' ? '' : 'asc';
    setSortField(newSortOrder ? sortBy : '');
    setSortOrder(newSortOrder);
  };

  const dropSort = () => {
    setSortField('');
    setSortOrder('');
  };

  return { sort, sortOrder, sortField, dropSort };
};

export default useSort;
