const defaultProduct = {
  tags: [],
  title: '',
  sub_title: '',
  card_colour: 'doc-green',
  description: '',
  points: [],
  footer: '',
  discount_exempt: false,
  vat_exempt: false,
  shippable: false,
  bookable: false,
  pre_book: 0,
};

export default defaultProduct;
