import React, { useState, useContext, useEffect } from 'react';
import { get } from 'lodash';
import moment from 'moment';
import { Grid } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { ToastsStore } from 'react-toasts';
import BigWhiteContainer from '../../components/Containers/BigWhiteContainer';
import adminService from '../../services/adminService';
import { AuthContext } from '../../context/AuthContext';
import getURLParams from '../../helpers/getURLParams';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import ReceiptsTable from '../../components/SAComponents/Tables/ReceiptsTable';

const DropboxReceipts = ({ token, isAuthenticated, role }) => {
  const { id } = useParams();
  const params = getURLParams(window.location.href);
  const defaultDate = params['date'];
  const { logout } = useContext(AuthContext);
  const [dropbox, setDropbox] = useState();
  const [receipts, setReceipts] = useState();
  const [date, setDate] = useState(!!defaultDate ? new Date(defaultDate) : new Date());
  const dropboxName = `${get(dropbox, 'facility.name', '')} - ${get(dropbox, 'facility.city', '')}`;
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();


  const getDropbox = async () => {
    setIsLoading(true);
    await adminService
      .getDropbox(id, token)
      .then(data => {
        if (data.success) {
          setDropbox(data.dropbox);
        } else if (!data.authenticated) {
          logoutUser();
        } else {
          ToastsStore.error('Error fetching Drop Boxes');
        }
      })
      .catch(() => ToastsStore.error('Error fetching Drop Boxes'));
    await getReceipts();
    setIsLoading(false);
  };

  const getReceipts = async () => {
    setIsLoading(true);
    await adminService
      .getDropboxReceipts(id, moment.utc(date).startOf('day').format(), token)
      .then(data => {
        if (data.success) {
          setReceipts(data.receipts || []);
        } else {
          ToastsStore.error('Error fetching receipts');
        }
      })
      .catch(() => ToastsStore.error('Error fetching receipts'));
    setIsLoading(false);
  };

  const logoutUser = () => {
    logout();
    history.push('/login');
  };

  if (isAuthenticated !== true && role !== 'super_admin') {
    logoutUser();
  }

  useEffect(() => {
    if (!dropbox) {
      getDropbox();
    }
  }, []);

  useEffect(() => {
    setReceipts([]);
    getReceipts();
  }, [date]);

  if (isLoading) {
    return (
      <BigWhiteContainer>
        <div className='row center'>
          <LoadingSpinner />
        </div>
      </BigWhiteContainer>
    );
  }

  return (
    <Grid container justify="space-between">
      <Grid item xs={12}>
        <ReceiptsTable
          date={date}
          setDate={setDate}
          dropboxName={dropboxName}
          receipts={receipts}
        />
      </Grid>
    </Grid>
  );
};

export default DropboxReceipts;
