import { useEffect, useState, useRef } from 'react';
import { Client } from '@twilio/conversations';
import chatService from '../../services/chatService';

const getToken = async (identity) => {
  const data = await chatService.getToken({ identity });
  return data.token;
};

const useTwilioClient = (identity) => {
  const [client, setClient] = useState();
  const clientRef = useRef();

  clientRef.current = client;

  const initiateClient = async () => {
    const token = await getToken(identity);
    const tmpClient = new Client(token);
    tmpClient.on('stateChanged', (state) => {
      if (state === 'initialized')
        setClient(tmpClient);
    });
  };

  const updateToken = async () => {
    clientRef.current.on('tokenAboutToExpire', async () => {
      const token = await getToken(identity);
      clientRef.current.updateToken(token);
    });
    clientRef.current.on('tokenExpired', async () => {
      const token = await getToken(identity);
      clientRef.current.updateToken(token);
    });
  };

  useEffect(() => {
    initiateClient();
  }, []);

  useEffect(() => {
    if (client) {
      updateToken();
    }
  }, [client]);

  return { client };
};

export default useTwilioClient;