import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import SendIcon from '@material-ui/icons/Send';
import CloseIcon from '@material-ui/icons/Close';
import CircleIcon from '@material-ui/icons/Brightness1';
import { Grid, InputAdornment, IconButton } from '@material-ui/core';
import useTwilioChat from '../../helpers/hooks/useTwilioChat';
import Input from '../FormComponents/Input';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import useFetchChats from '../../helpers/hooks/useFetchChats';
import './TwilioChatRoom.scss';

const TwilioChatRoom = ({ token, client, userName, userId, closeRoom, chat }) => {
  const roomId = get(chat, 'id').toString();
  const type = get(chat, 'type');
  const messagesEndRef = useRef();
  const [newMessage, setNewMessage] = useState('');
  const [preparedMessages, setPreparedMessages] = useState([]);
  const participantId = get(get(chat, 'room_members', []).find(({ user_id }) => user_id !== userId), 'user_id');
  const {
    chats: previousRooms,
  } = useFetchChats({
    token,
    entity: 'member',
    entityValue: participantId,
    status: 'closed',
    type,
  });
  const {
    isLoading,
    messages,
    sendMessage,
    channel,
    isParticipantOnline,
  } = useTwilioChat({
    client,
    roomId,
    previousRooms,
  });
  // eslint-disable-next-line no-unused-vars
  const channelMembers = Array.from(get(channel, 'participants', []), ([key, value]) => value);
  const participant = channelMembers && channelMembers.length <= 2
    ? channelMembers.find(({ state: { identity }}) => identity !== userName)
    : { state: { identity: 'General Chat' }};
  const participantName = get(participant, 'state.identity');
  const handleNewMessageChange = (event) => {
    setNewMessage(event.target.value);
  };

  const handleSendMessage = () => {
    sendMessage(newMessage);
    setNewMessage('');
  };

  const handleCloseRoom = () => {
    sendMessage(`${userName} closed conversation`);
    closeRoom();
  };

  const prepareMessages = async () => {
    setPreparedMessages(await Promise.all(
      messages.map(async (message) => {
        const messageDate = moment(message.dateCreated);
        const wasSendToday = messageDate.isSame(new Date(), 'day');
        const formattedDate = wasSendToday ? messageDate.format('hh:mm A') : messageDate.format('dd/MM/yy hh:mm A');
        const ownedByCurrentUser = type === 'external' ?  message.author === 'practitioner' : message.author === userName;
        let images = message.attachedMedia.map((item) => item.getCachedTemporaryUrl());
        images = await Promise.all(images);
        return {
          body: message.body,
          ownedByCurrentUser,
          date: formattedDate,
          author: message.author,
          images,
        };
      })
    ));
  };

  useEffect(() => {
    if (!isLoading && messagesEndRef.current) {
      prepareMessages();
      messagesEndRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }
  }, [messages, isLoading]);

  return (
    <div className="chat-room-container">
      <div className="row space-between no-margin">
        <div className="row no-margin">
          <h1 className="room-name">
            {participantName ? participantName : `Chat ID:${roomId}`}
          </h1>
          <CircleIcon className={isParticipantOnline ? 'online' : 'offline'} />
        </div>
        {closeRoom && (
          <IconButton onClick={handleCloseRoom}>
            <CloseIcon />
          </IconButton>
        )}
      </div>
      <Grid container className="chat-container">
        <Grid
          item
          xs={12}
          justify="center"
          alignContent="center"
          container={isLoading}
          className="messages-container"
        >
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <ol className="messages-list">
              {preparedMessages.map(({
                body,
                ownedByCurrentUser,
                date,
                images,
                author,
              }, i) => (
                <li
                  key={i}
                  className={`message-item ${
                    ownedByCurrentUser ? 'my-message' : 'received-message'
                  }`}
                >
                  {ownedByCurrentUser ? (
                    <p className="message-title my-message-title">
                      <time className="time">
                        {date}
                      </time>
                    </p>
                  ) : (
                    <p className="message-title">
                      <span className="message-author">{author}</span>&nbsp;
                      <time className="time">
                        {date}
                      </time>
                    </p>
                  )}
                  <>
                    {images.map((image) => (
                      <img key={image} className="message-image" src={image} />
                    ))}
                    {body}
                  </>
                </li>
              ))}
              <div ref={messagesEndRef} />
            </ol>
          )}
        </Grid>
        <Grid container item xs={12} style={{ alignSelf: 'flex-end', marginTop: 5 }} spacing={0}>
          <Input
            label="Message"
            id="discount-value"
            type="text"
            multiline
            maxRows={5}
            disabled={isLoading}
            variant="filled"
            placeholder="Write message..."
            value={newMessage}
            className="new-message-input-field"
            onChange={handleNewMessageChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton disabled={isLoading} onClick={handleSendMessage}>
                    <SendIcon />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default TwilioChatRoom;
