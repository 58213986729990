import axios from 'axios';
import { format } from 'date-fns-tz';
import { get, startCase } from 'lodash';
import React, { useEffect, useState, useRef } from 'react';
import {
  AppBar,
  Box,
  Grid,
  Paper,
  Container,
  Typography,
  Tooltip,
  Divider,
  CircularProgress,
  Toolbar,
  IconButton,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  List,
  ListItem,
  ListItemText,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { ToastsStore } from 'react-toasts';
import CloseIcon from '@material-ui/icons/Close';
import DocModal from '../DocModal/DocModal';
import DocButton from '../DocButton/DocButton';
import LinkButton from '../DocButton/LinkButton';
import adminService from '../../services/adminService';
import bookingService from '../../services/bookingService';
import copyToClipboard from '../../helpers/copyToClipboard';
import CertificatesAaron from '../Certificates/CertificatesAaron';
import AppointmentNotes from '../AppointmentView/AppointmentNotes';
import TextInputElement from '../../components/FormComponents/TextInputElement';
import { differenceInHours } from 'date-fns/esm';
import nurseSvc from '../../services/nurseService';
import {
  FULFILLMENT_USER_NAMES,
  PRODUCT_USER_NAMES,
} from '../../helpers/permissions';
import { useUser } from '../../context/AuthContext';
import UpdateFulFillmentControl from '../FormComponents/UpdateFulFillmentControl';
import ProductsAutocomplete from '../FormComponents/ProductsAutocomplete';
import UpdateProductQuantityControl from '../FormComponents/UpdateProductQuantityControl';
import TwilioVoiceCall from '../VoiceCall/TwilioVoiceCall';
import useTwilioVoiceCall from '../../helpers/hooks/useTwilioVoiceCall';

const orderUrl = process.env.REACT_APP_API_URL;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    marginTop: 65,
  },
  appBarTitle: {
    flexGrow: 1,
    color: 'var(--doc-white)',
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: 'var(--doc-white)',
  },
  title: {
    flexGrow: 1,
  },
  table: {
    minWidth: 650,
  },
  container: {
    marginTop: 10,
  },
  smallText: {
    fontSize: 12,
    color: 'grey',
  },
  note: {
    lineHeight: 1.2,
    whiteSpace: 'break-spaces',
  },
  notesBlock: {
    maxHeight: 235,
    overflowY: 'scroll',
  },
  appointmentStatusItem: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  appointmentItem: {
    '&:nth-child(odd)': {
      backgroundColor: 'var(--doc-light-grey)',
    },
  },
}));

const READABLE_STATUSES = {
  ND: 'Not Detected',
  DT: 'Detected',
  IDT: 'Indetermined',
};

const OrderDetails = ({
  user,
  token,
  order,
  role,
  closeHandler,
  shortInfo = false,
}) => {
  const transactionRef = useRef();
  const classes = useStyles();
  const orderId = get(order, 'id', '');
  const orders = [
    orderId,
  ];
  const userName = `${user.first_name} ${user.last_name}`;
  const isUpdateFulfillmentAvailable = role === 'super_admin' && !!user && FULFILLMENT_USER_NAMES.includes(userName);
  const isUpdateProductsAvailable = role === 'super_admin' && !!user && PRODUCT_USER_NAMES.includes(userName);
  if (orderId.slice(-4) === '_x_x')
    orders.push(orderId.substring(0, orderId.length - 2), orderId.substring(0, orderId.length - 4));
  else if (orderId.slice(-2) === '_x')
    orders.push(orderId.substring(0, orderId.length - 2), orderId + '_x');
  else orders.push(orderId + '_x', orderId + '_x_x');
  const [orderDetails, setOrderDetails] = useState({});
  const [discountValue, setDiscountValue] = useState();
  const [appointments, setAppointments] = useState([]);
  const [products, setProducts] = useState([]);
  const [approvedTestKits, setApprovedTestKits] = useState([]);
  const [reloadInfo, setReloadInfo] = useState(false);
  const [addingNote, setAddingNote] = useState(false);
  const [notesStatus, setNotesStatus] = useState();
  const [notes, setNotes] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(<></>);
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
  const refetchData = () => setReloadInfo((value) => !value);
  const swabbingMethod = get(orderDetails, 'items', []).find(({ product: { type } }) => type === 'Virtual');
  const orderItems = get(orderDetails, 'basket', []);
  const isBookableInBasket = !!orderItems.filter(productItem => !!get(productItem, 'product.bookable')).length;
  const paymentStatus = get(orderDetails, 'payment_flag');
  const transactionId = get(orderDetails, 'transaction_id');
  const source = orderDetails.source;
  const wasPayment = source !== 'Pharmacy' && source !== 'euro';
  const isUs = source === 'dochq-us';
  const currency = isUs ? '$' : '£';
  const discountType = String(discountValue?.type).toLowerCase();
  const tableDiscountValue = discountValue?.value ? (`${discountType === 'percentage' ? discountValue.value : discountValue.value.toFixed(2)}${discountType === 'percentage' ? '%' : currency}`) : '';
  const fetchData = async () => {
    if (orderId) {
      await adminService.getOrderDetails(orderId, token).then(res => {
        if (res.success && res.data) {
          setOrderDetails(res.data);
          if (!!res.data.discount) {
            adminService.validateDiscountCode(res.data.discount)
              .then(result => {
                if (result.success && result.data && result.data.value) {
                  setDiscountValue(result.data);
                }
              }).catch(() => console.log('error'));
          }
        } else {
          setError(<>Something bad happened</>);
          closeHandler();
          ToastsStore.error('Order hasn\'t been found');
        }
      }).catch(res => {
        setError(<>{res.message}</>);
        closeHandler();
        ToastsStore.error('Order hasn\'t been found');
      });
      const appointmentsArr = [];
      orders.forEach(async (orderItemId) => {
        const result = await bookingService.getAppointmentsByShortToken(orderItemId);
        if (result.success && result.appointments)
          appointmentsArr.push(...result.appointments);
        else console.log(result.error);
      });
      setAppointments(appointmentsArr);
      await adminService.getApprovedProducts()
        .then(result => {
          if (result.success && result.kits) {
            setApprovedTestKits([...result.kits].sort(({ name: nameA }, { name: nameB }) => nameA < nameB ? -1 : nameA > nameB ? 1 : 0));
          } else {
            setApprovedTestKits([]);
          }
        }).catch(() => {
          setApprovedTestKits([]);
        });
      await adminService.getProducts()
        .then(result => {
          if (result.success && result.products) {
            setProducts(result.products);
          }
        }).catch(err => console.log(err));
    }
    setLoading(false);

  };

  useEffect(() => {
    fetchData();
  }, [reloadInfo]);

  const updateNotes = (notes) => {
    adminService.updateOrder({
      ...orderDetails,
      order_notes: [
        {
          created_by: `${user.first_name} ${user.last_name}`,
          note: notes,
        },
        ...(!!orderDetails.order_notes ? orderDetails.order_notes : [])
      ],
    }, orderDetails.id, token).then(result => {
      if (result.success) {
        setNotesStatus({ severity: 'success', message: 'Note added successfully' });
        setNotes();
      } else {
        setNotesStatus({
          severity: 'error',
          message: result.message,
        });
      }
    })
      .catch((err) => {
        setNotesStatus({
          severity: 'error',
          message: err.error,
        });
      });
  };

  useEffect(() => {
    if (notesStatus && notesStatus.severity === 'success') {
      const timer = setTimeout(() => {
        setNotesStatus();
        refetchData();
        setAddingNote(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [notesStatus]);

  const handleCancelDialogToggle = () => {
    setCancelDialogOpen(!cancelDialogOpen);
  };

  return loading ? (
    <div className={classes.root}>
      <Grid container spacing={10} direction="column" justify="center" alignItems="center">
        <Grid item></Grid>
        <Grid item>
          <CircularProgress />
        </Grid>
        {error}
      </Grid>
    </div>
  ) : (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={closeHandler}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.appBarTitle}>
            Order Details for {get(orderDetails, 'billing_detail.first_name', '')} {get(orderDetails, 'billing_detail.last_name', '')}
          </Typography>
        </Toolbar>
      </AppBar>
      <Container className={classes.container}>
        <Grid container spacing={3}>
          <Grid item container xs={12}>
            <Grid item xs={10} md={6}>
              <Typography variant="h6" className={classes.title}>
                Purchase Details
              </Typography>
              <List>
                <ListItem>
                  <ListItemText>
                    <b>Customer name</b>: {get(orderDetails, 'billing_detail.first_name', '')} {get(orderDetails, 'billing_detail.last_name', '')}
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>
                    <b>Email</b>: {get(orderDetails, 'billing_detail.email', '')}
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>
                    <b>Phone</b>: {get(orderDetails, 'billing_detail.telephone', '') || get(orderDetails, 'shipping_address.telephone', '')}
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>
                    <b>Purchase Date</b>: {format(new Date(orderDetails.created_at * 1000), 'dd/MM/yyyy p')}
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>
                    <b>Order number</b>: {orderDetails.short_token}
                  </ListItemText>
                </ListItem>
                {transactionId && (
                  <ListItem>
                    <ListItemText>
                      <b>Transaction ID</b>:
                      <Typography
                        noWrap
                        ref={transactionRef}
                        style={{ maxWidth: 300, overflow: 'hidden', cursor: 'pointer' }}
                        onClick={() => copyToClipboard(transactionRef)}
                      >
                        {transactionId}
                      </Typography>
                    </ListItemText>
                  </ListItem>
                )}
                <ListItem>
                  <ListItemText>
                    <b>Payment status</b>: {orderDetails.payment_flag}
                    {(orderDetails.payment_flag === 'Failed' && isUpdateFulfillmentAvailable) && (
                      <DocButton
                        text="Update to Complete"
                        color="green"
                        style={{ marginLeft: 10 }}
                        onClick={async () => {
                          await adminService.updateOrder({
                            ...orderDetails,
                            payment_flag: 'Complete',
                            shipping_flag: 'Processing',
                          }, orderDetails.id, token)
                            .then((result) => {
                              if (result.success) refetchData();
                            })
                            .catch((err) => ToastsStore.error(err.error));
                        }}
                      />
                    )}
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>
                    <b>Source</b>: {source}
                  </ListItemText>
                </ListItem>
                {discountValue && (
                  <ListItem>
                    <ListItemText>
                      <b>Discount code</b>: {discountValue.code}
                    </ListItemText>
                  </ListItem>
                )}
              </List>
            </Grid>
            <Grid item xs={10} md={6}>
              <div className="row">
                <Typography variant="h6" className={classes.title}>
                  Order Notes
                </Typography>
                {!addingNote && (
                  <DocButton
                    color="green"
                    onClick={() => setAddingNote(!addingNote)}
                    text="Add note"
                  />
                )}
              </div>
              <div className={classes.notesBlock}>
                {!!orderDetails.order_notes && orderDetails.order_notes.map(({ note, id, created_by, created_at }) => (
                  <div key={id}>
                    <Typography className={classes.note}>
                      {note}
                    </Typography>
                    <div className="row space-between">
                      <Typography className={classes.smallText}>
                        {created_by}
                      </Typography>
                      <Typography className={classes.smallText}>
                        {format(new Date(created_at * 1000), 'dd/MM/yyyy p')}
                      </Typography>
                    </div>
                  </div>
                ))}
              </div>
              {addingNote && (
                <>
                  <Typography>
                    New Note
                  </Typography>
                  <TextInputElement
                    rows={4}
                    multiline
                    id='notes'
                    value={notes}
                    onChange={setNotes}
                  />
                  <div className='row space-between'>
                    <DocButton
                      color="pink"
                      onClick={() => setAddingNote(!addingNote)}
                      text="Cancel"
                    />
                    <DocButton
                      color='green'
                      text='Submit'
                      onClick={() => {
                        updateNotes(notes);
                      }}
                    />
                  </div>
                  {!!notesStatus && !!notesStatus.severity && !!notesStatus.message && (
                    <div className='row center'>
                      <Alert
                        variant="outlined"
                        severity={notesStatus.severity}
                      >
                        {notesStatus.message}
                      </Alert>
                    </div>
                  )}
                </>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" className={classes.title}>
              Products
            </Typography>
            {shortInfo ? (
              <>
                {orderItems.map((row) => (
                  <Typography>
                    <b>{row.quantity}x</b> - {row.product.title}
                  </Typography>
                ))}
              </>
            ) : (
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell align="right">Type</TableCell>
                      <TableCell align="right">Quantity</TableCell>
                      <TableCell align="right">Return</TableCell>
                      <TableCell align="right">Price</TableCell>
                      {discountValue && (
                        <>
                          <TableCell align="right">Discount Value</TableCell>
                          <TableCell align="right">Discount Price</TableCell>
                        </>
                      )}
                      <TableCell align="right">Fulfilled</TableCell>
                      {isUpdateFulfillmentAvailable && (
                        <TableCell align="right">Update Fulfillment</TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orderItems.map((row) => (
                      <TableRow key={row.order_id + row.product_id}>
                        <TableCell component="th" scope="row">
                          {isUpdateProductsAvailable ? (
                            <ProductsAutocomplete
                              product={row.product}
                              token={token}
                              products={products}
                              order_id={orderDetails.id}
                              reload={refetchData}
                              bundle_id={row.bundle_id}
                            />
                          ) : row.product.title}
                        </TableCell>
                        <TableCell align="right">{row.product.type}</TableCell>
                        <TableCell align="right">
                          {isUpdateProductsAvailable ? (
                            <UpdateProductQuantityControl
                              orderDetails={orderDetails}
                              product={row}
                              token={token}
                              reload={refetchData}
                            />
                          ) : row.quantity}
                        </TableCell>
                        <TableCell align="right">{currency}{(get(row, 'return_method.price', 0) * row.quantity).toFixed(2)}</TableCell>
                        <TableCell align="right">{currency}{row.product.price.toFixed(2)}</TableCell>
                        {discountValue && (
                          <>
                            <TableCell align="right">
                              {tableDiscountValue}
                            </TableCell>
                            <TableCell align="right">
                              {(discountValue.type === 'percentage')
                                ? `${currency}${!row.product.discount_exempt ? (row.product.price - (row.product.price * (discountValue.value / 100))).toFixed(2) : row.product.price.toFixed(2)}`
                                : tableDiscountValue}
                            </TableCell>
                          </>
                        )}
                        <TableCell align="right">{get(row, 'fulfilled', 0)}</TableCell>
                        {isUpdateFulfillmentAvailable && (
                          <TableCell align="right">
                            <UpdateFulFillmentControl
                              token={token}
                              order_id={row.order_id}
                              product_id={row.product_id}
                              bundle_id={row.bundle_id}
                              fulfilled={row.fulfilled || 0}
                              reload={refetchData}
                            />
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell align="left">Total</TableCell>
                      <TableCell align="right"></TableCell>
                      <TableCell align="right"></TableCell>
                      <TableCell align="right">{orderItems.reduce((sum, { quantity }) => (sum + quantity), 0)}</TableCell>
                      <TableCell align="right">{currency}{orderItems.reduce((sum, { quantity, return_method }) => (sum + (get(return_method, 'price', 0) * quantity)), 0).toFixed(2)}</TableCell>
                      <TableCell align="right">{currency}{discountValue ? get(orderDetails, 'original_price', 0).toFixed(2) : (orderDetails.price || 0).toFixed(2)}</TableCell>
                      {discountValue && (
                        <>
                          <TableCell align="right">
                            {discountValue.value}{discountValue.type === 'percentage' ? '%' : currency}
                          </TableCell>
                          <TableCell align="right">
                            {currency}{(orderDetails.price || 0).toFixed(2)}
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Grid>
          {wasPayment && (
            <Grid item xs={10} md={6}>
              <Typography variant="h6" className={classes.title}>
                Billing Details
              </Typography>
              <List>
                <ListItem>
                  <ListItemText>
                    <b>Address Line 1</b>: {orderDetails.billing_address.address_1}
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>
                    <b>Address Line 2</b>: {!!orderDetails.billing_address.address_2 ? orderDetails.billing_address.address_2 : '-'}
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>
                    <b>Post Code</b>: {orderDetails.billing_address.postcode}
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>
                    <b>County</b>: {orderDetails.billing_address.county}
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>
                    <b>Town</b>: {orderDetails.billing_address.town}
                  </ListItemText>
                </ListItem>
              </List>
            </Grid>
          )}
          <Grid item xs={10} md={6}>
            <Typography variant="h6" className={classes.title}>
              {!wasPayment ? 'Customer Address' : 'Shipping Address'}
            </Typography>
            <List>
              <ListItem>
                <ListItemText>
                  <b>Address Line 1</b>: {orderDetails.shipping_address.address_1}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  <b>Address Line 2</b>: {!!orderDetails.shipping_address.address_2 ? orderDetails.shipping_address.address_2 : '-'}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  <b>Post Code</b>: {orderDetails.shipping_address.postcode}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  <b>County</b>: {orderDetails.shipping_address.county}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  <b>Town</b>: {orderDetails.shipping_address.town}
                </ListItemText>
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={10} md={12}>
            <Grid container justify="space-between" alignItems="center">
              <Grid item xs={7}>
                <Typography variant="h6" className={classes.title}>
                  <b>Shipping status</b>: {orderDetails.shipping_flag}
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <DocButton
                  color="green"
                  style={{ marginRight: 10 }}
                  text="Send Order Confirmation"
                  onClick={() => adminService.resendMessages({
                    organisation_id: '1',
                    event: 'order.payment.complete',
                    context: {
                      'order_id': orderDetails.id.toString(),
                    },
                  }, token).then(result => {
                    if (result.success) {
                      ToastsStore.success('Message has been resent successfully!');
                    } else {
                      ToastsStore.error('Something went wrong!');
                    }
                  }).catch(() => ToastsStore.error('Something went wrong!'))}
                />
                {(paymentStatus === 'Complete' && !shortInfo && isBookableInBasket) && (
                  <LinkButton
                    color="green"
                    linkSrc={`/b2c/book-appointment?short_token=${order.id}&service=video_gp_dochq`}
                    text="Book an Appointment"
                    newTab
                  />
                )}
                {!shortInfo && (
                  <DocButton
                    color="pink"
                    style={{ marginLeft: 10 }}
                    onClick={handleCancelDialogToggle}
                    text="Cancel order"
                  />
                )}
                <CancelOrder
                  order={orderDetails}
                  open={cancelDialogOpen}
                  onClose={handleCancelDialogToggle}
                  loading={loading}
                  token={token}
                  setLoading={setLoading}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {(!!appointments.length && !shortInfo) && (
          <Grid container spacing={3}>
            <Grid item xs={10} md={12}>
              <Divider style={{ margin: '20px 0' }} />
              <Typography variant="h6" className={classes.title}>
                Appointments Details
              </Typography>
              <div className={classes.appointmentBox}>
                {appointments.map((row, appointmentIndx) => (
                  <AppointmentDetails
                    key={row.id}
                    id={row.id}
                    token={token}
                    products={products}
                    reloadInfo={reloadInfo}
                    orderItems={get(orderDetails, 'items', []).filter(({ product: { type } }) => type !== 'Virtual')}
                    shortToken={order.id}
                    appointment={row}
                    refetchData={refetchData}
                    swabbingMethod={swabbingMethod}
                    appointmentIndx={appointmentIndx}
                    approvedTestKits={approvedTestKits}
                  />
                ))}
              </div>
            </Grid>
          </Grid>
        )}
      </Container>
    </div>
  );
};

const PatientDetails = ({
  patient,
  appointmentId,
  refetchData,
  isCompleted,
  approvedTestKits,
}) => {
  const [isEditShow, setIsEditShow] = useState(false);
  const bookingId = get(patient, 'id', '');
  const firstName = get(patient, 'metadata.forename', '') || patient.first_name;
  const lastName = get(patient, 'metadata.surname', '') || patient.last_name;
  const email = get(patient, 'metadata.email', '') || patient.email;
  const sex = get(patient, 'metadata.sex', '') || patient.sex;
  const date_of_birth = get(patient, 'metadata.date_of_birth', '') || patient.date_of_birth;
  const testType = get(patient, 'metadata.test_type', '');
  const phone = get(patient, 'phone', '');
  const ethnicity = get(patient, 'ethnicity', '');
  const passportNumber = get(patient, 'metadata.passport_number', '') || get(patient, 'metadata.passportId', '');
  const result = get(patient, 'metadata.result', '');
  const rejectedNotes = get(patient, 'metadata.reject_notes', '');
  const invalidNotes = get(patient, 'metadata.invalid_notes', '');
  const sampleTaken = get(patient, 'metadata.sample_taken', '');
  const kitId = get(patient, 'metadata.kit_id', '');
  const kitProvider = get(patient, 'metadata.kitProvider', '');
  const samplingDate = get(patient, 'metadata.date_sampled', '');
  const reportedDate = get(patient, 'metadata.date_reported', '');
  const dateOfReceipt = get(patient, 'metadata.date_of_receipt', '');

  return (
    <>
      <List component="div" disablePadding>
        <ListItem>
          <ListItemText>
            <b>Booking user ID</b>: {bookingId}
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            <b>Name</b>: {firstName} {lastName}
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            <b>Email</b>: {email}
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            <b>Phone Number</b>: {phone}
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            <b>Date of Birth</b>: {format(new Date(date_of_birth), 'dd/MM/yyyy')}
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            <b>Ethnicity</b>: {ethnicity}
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            <b>Sex</b>: {sex}
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            <b>Passport/National identity card number</b>: {passportNumber}
          </ListItemText>
        </ListItem>
        {!!samplingDate && (
          <>
            <Divider style={{ margin: '20px 0', width: '20%' }} />
            <ListItem>
              <ListItemText>
                <b>Sampling Date and Time: </b>{new Date(samplingDate).toUTCString()}
              </ListItemText>
            </ListItem>
          </>
        )}
        {!!reportedDate && (
          <ListItem>
            <ListItemText>
              <b>Reported Date and Time: </b>{new Date(reportedDate).toUTCString()}
            </ListItemText>
          </ListItem>
        )}
        <Box>
          {!!kitProvider && (
            <ListItem>
              <ListItemText>
                <b>KIT provider</b>: {kitProvider}
              </ListItemText>
            </ListItem>
          )}
          {!!sampleTaken && (
            <ListItem>
              <ListItemText>
                <b>Sample: </b>
                <span className={sampleTaken.toLowerCase()}>{sampleTaken}</span>
              </ListItemText>
            </ListItem>
          )}
          {!!kitId && (
            <ListItem>
              <ListItemText>
                <b>Kit ID: </b>
                <span>{kitId}</span>
              </ListItemText>
            </ListItem>
          )}
          {!!dateOfReceipt && (
            <ListItem>
              <ListItemText>
                <b>Received by Lab: </b>{new Date(dateOfReceipt).toUTCString()}
              </ListItemText>
            </ListItem>
          )}
          {!!result && (
            <ListItem>
              <Grid container justify="space-between">
                <Grid item>
                  <ListItemText>
                    <b>Test Result: </b>
                    <span className={result.toLowerCase()}>{!!READABLE_STATUSES[result] ? READABLE_STATUSES[result] : result}</span>
                  </ListItemText>
                </Grid>
                {(testType === 'Antigen' && isCompleted && !isEditShow) && (
                  <Grid item>
                    <DocButton
                      text="Reissue Certificate"
                      color="green"
                      style={{ marginTop: 0, marginLeft: 10 }}
                      onClick={() => setIsEditShow(!isEditShow)}
                    />
                  </Grid>
                )}
              </Grid>
            </ListItem>
          )}
          {!!rejectedNotes && (
            <ListItem>
              <ListItemText>
                <b>Rejection Notes: </b>{rejectedNotes}
              </ListItemText>
            </ListItem>
          )}
          {!!invalidNotes && (
            <ListItem>
              <ListItemText>
                <b>Invalid Notes: </b>{invalidNotes}
              </ListItemText>
            </ListItem>
          )}
        </Box>
      </List>
      {isEditShow && (
        <CertificatesAaron
          patient_data={patient}
          appointmentId={appointmentId}
          approvedTestKits={approvedTestKits}
          submitCallback={() => {
            setIsEditShow(false);
            refetchData();
          }}
          cancelBtn={
            <DocButton
              text="Cancel"
              color="pink"
              onClick={() => setIsEditShow(!isEditShow)}
            />
          }
        />
      )}
    </>
  );
};

const AppointmentDetails = ({
  id,
  reloadInfo,
  appointment,
  appointmentIndx,
  refetchData,
  token,
  products,
  swabbingMethod,
  orderItems = [],
  shortToken,
  approvedTestKits,
}) => {
  const classes = useStyles();
  const user = useUser();
  const first_name = get(user, 'first_name', '');
  const last_name = get(user, 'last_name', '');
  const identity = `${first_name}${last_name}`;
  const { token: TVCToken } = useTwilioVoiceCall(identity);
  const linkRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [practitionerInfo, setPractitionerInfo] = useState();
  const timezone = get(Intl.DateTimeFormat().resolvedOptions(), 'timeZone', 'local time');
  const notes = get(appointment, 'notes', []);
  const [isVisible, setIsVisible] = useState(false);
  const appointmentStatus = appointment.status;
  const statusChanges = get(appointment, 'status_changes', []) || [];
  const isCompleted = appointmentStatus === 'COMPLETED';
  const flightDate = get(appointment, 'booking_user.metadata.travel_date');
  const product_id = get(appointment, 'booking_user.metadata.product_id');
  const location = get(appointment, 'booking_user.tz_location');
  const practitionerName = get(practitionerInfo, 'user_name');
  const practitionerPhoneNumber = get(practitionerInfo, 'user_number');

  const fetchData = async () => {
    if (!!id) {
      await nurseSvc.getAppointmentDetails(id, token, true)
        .then(result => {
          if (result.success && result.appointment) {
            setPractitionerInfo(result.appointment);
          }
        }).catch(err => console.log(err));
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [reloadInfo, id]);

  return loading ? (
    <Grid container spacing={10} direction="column" justify="center" alignItems="center">
      <Grid item>
        <CircularProgress />
      </Grid>
    </Grid>
  ) : (
    <div className={classes.appointmentItem}>
      <List>
        <ListItemText>
          <b>Appointment {appointmentIndx + 1}</b>
        </ListItemText>
        {!!location && (
          <ListItem>
            <ListItemText>
              <b>Location</b>: {location}
            </ListItemText>
          </ListItem>
        )}
        {!!flightDate && (
          <ListItem>
            <ListItemText>
              <b>Flight Date</b>: {format(new Date(flightDate), 'dd/MM/yyyy p')} ({timezone})
            </ListItemText>
          </ListItem>
        )}
        {!!product_id && (
          <ListItem>
            <ListItemText>
              <b>Selected Product</b>: {get(orderItems.find(({ product_id }) => product_id === appointment.booking_user.product_id), 'product.title', '') || get(products.find(({ id }) => id === appointment.booking_user.product_id), 'title', '')}
            </ListItemText>
          </ListItem>
        )}
        <ListItem>
          <ListItemText>
            <b>Test Type</b>: <b className="green-text">{appointment.booking_user.metadata.test_type}</b>
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            {!!appointment.start_time ? (
              <>
                <b>Appointment Date</b>: {format(new Date(appointment.start_time), 'dd/MM/yyyy p')}  ({timezone})
              </>
            ) : (
              <>
                <b>Swabbing Method</b>: {swabbingMethod.product.title}
              </>
            )}
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            <b>Appointment Joining link</b>:&nbsp;
            <Tooltip title="Click to copy">
              <Typography
                noWrap
                ref={linkRef}
                onClick={() => copyToClipboard(linkRef)}
                className='tab-row-text patient-link-text'
              >
                https://{process.env.REACT_APP_JOIN_LINK_PREFIX}.dochq.co.uk/appointment?appointmentId={appointment.id}
              </Typography>
            </Tooltip>
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            <div className="row center no-margin">
              {practitionerName && (
                <>
                  <b>Practitioner</b><span style={{ width: 200 }}>: {practitionerName}</span>
                </>
              )}
              {(practitionerPhoneNumber && TVCToken) && (
                <TwilioVoiceCall
                  isTable
                  noBtnClass
                  token={TVCToken}
                  phoneNumber={practitionerPhoneNumber}
                />
              )}
            </div>
          </ListItemText>
        </ListItem>
        {appointment.booking_users.map((patient, indx) => (
          <>
            <Divider style={{ margin: '20px 0', width: '30%' }} />
            <div key={indx}>
              <ListItemText>
                <b>Details of Passenger {indx + 1}</b>
              </ListItemText>
              <PatientDetails
                patient={patient}
                isCompleted={isCompleted}
                refetchData={refetchData}
                appointmentId={appointment.id}
                approvedTestKits={approvedTestKits}
              />
            </div>
          </>
        ))}
        <Divider style={{ margin: '20px 0', width: '30%' }} />
        <ListItem>
          <ListItemText>
            <b>Appointment Status</b>: {startCase(appointmentStatus === 'LOCKED' ? 'Locked' : appointmentStatus.replace('_', ' ').toLowerCase())}
          </ListItemText>
        </ListItem>
        {!!notes.length && (
          <ListItem>
            <AppointmentNotes notes={notes} />
          </ListItem>
        )}
        {!!statusChanges.length && (
          <ListItem>
            <ListItemText>
              <b>Appointment Status Changes</b>:
              {statusChanges.map(({ changed_to, created_at }, indx) => (
                <ListItem key={indx} className={classes.appointmentStatusItem}>
                  <ListItemText>
                    <b>{startCase(changed_to.replace('_', ' ').toLowerCase())}</b> - {format(new Date(created_at), 'dd/MM/yyyy pp')} ({timezone})
                  </ListItemText>
                </ListItem>
              ))}
            </ListItemText>
          </ListItem>
        )}
      </List>
      <Grid container justify="space-between">
        <Grid item xs={9}>
          {!isCompleted && (
            <DocButton
              color="green"
              style={{ marginLeft: 10 }}
              text="Send Appointment Confirmation"
              onClick={() => adminService.resendMessages({
                organisation_id: '1',
                event: 'appointment.booked',
                context: {
                  'appointment_id': appointment.id,
                },
              }, token).then(result => {
                if (result.success) {
                  ToastsStore.success('Message has been resent successfully!');
                } else {
                  ToastsStore.error('Something went wrong!');
                }
              }).catch(() => ToastsStore.error('Something went wrong!'))}
            />
          )}
          <DocButton
            color="pink"
            style={{ marginLeft: 10 }}
            text="Buy Appointment"
            onClick={() => adminService.resendMessages({
              organisation_id: '1',
              event: 'appointment.rebook',
              context: {
                'appointment_id': appointment.id,
              },
            }, token).then(result => {
              if (result.success) {
                ToastsStore.success('Message has been resent successfully!');
              } else {
                ToastsStore.error('Something went wrong!');
              }
            }).catch(() => ToastsStore.error('Something went wrong!'))}
          />
        </Grid>
        {!isCompleted && (
          <Grid item xs={2}>
            <LinkButton
              color="green"
              linkSrc={`/booking/edit?appointmentId=${appointment.id}&short_token=${shortToken}&service=video_gp_dochq`}
              text="Edit"
            />
            <DocButton
              color="pink"
              style={{ marginLeft: 10 }}
              text="Delete"
              onClick={() => setIsVisible(true)}
            />
          </Grid>
        )}
      </Grid>
      <DocModal
        isVisible={isVisible}
        onClose={() => setIsVisible(false)}
        content={
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {differenceInHours(new Date(appointment.start_time), new Date()) <= 24 && (
              <p className="no-margin">This appointment is due to start in less than 24h from now.</p>
            )}
            <p>Are you sure you want to delete this appointment?</p>
            <div className="row space-between">
              <DocButton
                color='green'
                text='No'
                onClick={() => setIsVisible(false)}
                style={{ marginRight: '5px' }}
              />
              <DocButton
                color='pink'
                text='Yes'
                onClick={async () => {
                  await bookingService.deleteBooking(appointment.id, token, 'practitioner', 'delete').catch(() => ToastsStore.error('Something went wrong'));
                  refetchData();
                  setIsVisible(false);
                }}
              />
            </div>
          </div>
        }
      />
      <Divider style={{ margin: '20px 0' }} />
    </div>
  );
};

const CancelOrder = ({ order, open, onClose, loading, setLoading, token }) => {
  const classes = useStyles();
  const [emailAddress, setEmailAddress] = useState('');
  const [error, setError] = useState(<></>);

  const cancelOrder = () => {
    if (emailAddress === order.billing_detail.email) {
      setLoading(true);
      const apiCall = new Promise((res, rej) => {
        axios({
          method: 'delete',
          url: `${orderUrl}/v1/order/${order.id}`,
          headers: { Authorization: `Bearer ${token}` },
        }).then(res)
          .catch(rej);
      });
      apiCall.then(res => {
        if (res.status === 200 && typeof res.data) {
          setLoading(false);
          window.location.reload(false);
        } else {
          setError(<>Something bad happened</>);
          setLoading(false);
        }
      })
        .catch(res => {
          setError(<>{res.message}</>);
          setLoading(false);
        });
    }
  };

  return loading ? (
    <div className={classes.root}>
      <Grid container spacing={10} direction="column" justify="center" alignItems="center">
        <Grid item></Grid>
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    </div>
  ) : (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{'Cancel order?'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Upon canceling an order a refund will be issued to the customer for the amount on the order
          any shipping manifests will be called off and the order will not ship.
          If you are certain you want to cancel the order, please enter the order's email address ({order.billing_detail.email})
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Email Address"
          type="email"
          value={emailAddress}
          onChange={(e) => setEmailAddress(e.target.value)}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
        <Button onClick={cancelOrder} color="primary">
          Submit
        </Button>
      </DialogActions>
      {error}
    </Dialog>
  );
};

export default OrderDetails;
