import React, { useState } from 'react';
import { get } from 'lodash';
import { Box } from '@material-ui/core';
import { ToastsStore } from 'react-toasts';
import { Autocomplete } from '@material-ui/lab';
import DocButton from '../DocButton/DocButton';
import adminService from '../../services/adminService';
import Input from './Input';


const ProductsAutocomplete = ({
  order_id,
  product,
  bundle_id,
  products,
  reload,
  token,
}) => {
  const [value, setValue] = useState(product);
  const newProductId = get(value, 'id');
  const productId = product.id;
  const isValueUpdated = (productId !== newProductId) && newProductId;

  const updateProduct = async () => {
    await adminService.updateProduct({
      token,
      id: order_id,
      data: {
        order_id,
        bundle_id,
        product_id: productId,
        new_product_id: newProductId,

      },
    }).then(result => {
      if (result.success) {
        reload();
      } else {
        ToastsStore.error(result.error);
      }
    })
      .catch((err) => ToastsStore.error(err.error));
  };

  return (
    <Box display="flex" justifyContent={isValueUpdated ? 'space-between' : 'flex-start'} alignItems="center">
      <Autocomplete
        value={value}
        options={products}
        getOptionLabel={({ title, id }) => `${id}: ${title}`}
        style={{ width: 350 }}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        closeIcon={false}
        renderInput={(params) => <Input
          {...params}
        />}
      />
      {isValueUpdated && (
        <DocButton
          color="green"
          onClick={updateProduct}
          text="Save"
        />
      )}
    </Box>
  );
};

export default ProductsAutocomplete;