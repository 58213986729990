import React from 'react';
import { get } from 'lodash';
import { ToastsStore } from 'react-toasts';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DocButton from '../DocButton/DocButton';
import useFetchChats from '../../helpers/hooks/useFetchChats';
import chatService from '../../services/chatService';
import './Tables.scss';

const styles = {
  tableText: {
    fontSize: 16,
  },
  smallCol: {
    width: '15%',
    maxWidth: '15%',
    fontSize: 16,
  },
  medCol: { width: '25%', maxWidth: '25%' },
  bntStyles: {
    marginLeft: '10px',
    marginTop: '0px',
    marginRight: '10px',
    boxSizing: 'border-box',
    maxWidth: '40%',
  },
  mainContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
};

const ClaimableChatsTable = ({ goToLiveChats, token, user }) => {
  const user_id = get(user, 'id', 0);
  const {
    chats,
  } = useFetchChats({
    token,
    entity: 'status',
    entityValue: 'claimable',
  });

  const claimChat = async (chat) => {
    await chatService.updateRoom({
      token,
      id: chat.id,
      data: {
        ...chat,
        status: 'live',
        room_members: [
          ...get(chat, 'room_members', []),
          {
            user_id,
          },
        ],
      },
    }).then(data => {
      if (data.success) {
        goToLiveChats();
      } else ToastsStore.error(data.error);
    })
      .catch((err) => ToastsStore.error(err.error));
  };

  return (
    <div className='doc-container tables' style={{ height: '100%', justifyContent: 'unset' }}>
      <div style={styles.mainContainer}>
        <h2>Claimable Chats</h2>
      </div>
      <TableContainer
        style={{
          maxHeight: '500px',
          marginBottom: '40px',
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align='left' style={styles.tableText}>Chat ID</TableCell>
              <TableCell align='right' style={styles.tableText}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {chats.length > 0 ? chats.map(chat => (
              <TableRow key={chat.id}>
                <TableCell align='left' style={{ ...styles.tableText }}>
                  {chat.id}
                </TableCell>
                <TableCell align='right' style={{ ...styles.tableText }}>
                  <DocButton
                    text='Claim'
                    color='green'
                    onClick={() => claimChat(chat)}
                  />
                </TableCell>
              </TableRow>
            )) : (
              <TableRow>
                <TableCell style={styles.tableText}>
                  <p>No chats to display</p>
                </TableCell>
                <TableCell />
                <TableCell />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ClaimableChatsTable;
