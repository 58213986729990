import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import {
  AppBar,
  Tabs,
  Tab,
  Box,
} from '@material-ui/core';
import indexToProps from '../../helpers/indexToProps';
import useAppBarStyles from '../../helpers/hooks/useAppBarStyles';

const LiveAppBar = ({ value, children }) => {
  const classes = useAppBarStyles();

  return (
    <>
      <AppBar position="static">
        <Tabs value={value} aria-label="simple tabs example">
          <Tab
            label="DocHQ"
            {...indexToProps(0)}
            classes={{
              root: classes.tab,
              selected: classes.selected,
            }}
            component={Link}
            to="/shift_manager/live/dashboard"
          />
          <Tab
            label="My Rooms"
            {...indexToProps(1)}

            classes={{
              root: classes.tab,
              selected: classes.selected,
            }}
            component={Link}
            to="/shift_manager/live/my-rooms"
          />
        </Tabs>
      </AppBar>
      <Box className={classes.tabBox}>
        {children}
      </Box>
    </>
  );
};

export default memo(LiveAppBar);
