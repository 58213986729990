import React, { memo } from 'react';
import BigWhiteContainer from '../../components/Containers/BigWhiteContainer';

const B2CDashboard = () => (
  <React.Fragment>
    <BigWhiteContainer>
      <div />
    </BigWhiteContainer>
  </React.Fragment>
);

export default memo(B2CDashboard);
