import React, { useState } from 'react';
import {
  Box,
  TextField,
} from '@material-ui/core';
import { ToastsStore } from 'react-toasts';
import DocButton from '../DocButton/DocButton';
import adminService from '../../services/adminService';


const UpdateFulFillmentControl = ({
  order_id,
  product_id,
  bundle_id,
  fulfilled,
  token,
  reload,
}) => {
  const oldFulfilled = +fulfilled;
  const [newFulfillment, setNewFulfillment] = useState(oldFulfilled);

  return (
    <Box display="flex" justifyContent="space-around" alignItems="center">
      <TextField
        autoFocus
        margin="dense"
        id="fulfillment"
        label="New Fulfillment"
        type="number"
        inputProps={{
          min: 0,
        }}
        style={{ width: 115 }}
        value={newFulfillment}
        onChange={(e) => setNewFulfillment(e.target.value)}
      />
      {oldFulfilled !== newFulfillment && (
        <DocButton
          text="Update"
          color="green"
          onClick={async () => await adminService.updateFulfillment({
            orderId: order_id,
            data: {
              order_id,
              product_id,
              bundle_id,
              fulfilled: +newFulfillment,
            },
            token,
          }).then((result) => {
            if (result.success) {
              ToastsStore.success('Fulfillment has been updated successfully!');
              reload();
            }
          }).catch(err => ToastsStore.error(err.error))}
        />
      )}
    </Box>
  );
};

export default UpdateFulFillmentControl;
