import React, { useEffect, useState }  from 'react';
import { get } from 'lodash';
import {
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  TableSortLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { ToastsStore } from 'react-toasts';
import LinkButton from '../../DocButton/LinkButton';
import DocButton from '../../DocButton/DocButton';
import adminService from '../../../services/adminService';
import DocModal from '../../DocModal/DocModal';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import '../../Tables/Tables.scss';
import useSort from '../../../helpers/hooks/useSort';

const styles = {
  tableText: {
    fontSize: 16,
  },
  tableTextHeader: {
    fontSize: 16,
    cursor: 'pointer',
  },
  bntStyles: {
    marginLeft: '10px',
    marginTop: '0px',
    marginRight: '10px',
    boxSizing: 'border-box',
    maxWidth: '40%',
  },
  mainContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  snackbar: {
    color: '#FFF',
  }
};

const TABLE_HEADERS = [
  {
    titleName: 'ID',
    propertyName: 'id',
    isSortable: true,
    align: 'left',
  },
  {
    titleName: 'Title',
    propertyName: 'title',
    isSortable: true,
    align: 'center',
  },
  {
    titleName: 'Price',
    propertyName: 'price',
    isSortable: true,
    align: 'center',
  },
  {
    titleName: 'SKU',
    propertyName: 'sku',
    isSortable: true,
    align: 'center',
  },
  {
    titleName: 'Type',
    propertyName: 'type',
    isSortable: true,
    align: 'center',
  },
  {
    titleName: 'Tags',
    propertyName: 'tags',
    isSortable: false,
    align: 'center',
  },
  {
    titleName: 'Actions',
    propertyName: 'actions',
    isSortable: false,
    align: 'right',
  },
];

const ProductsTable = ({
  token,
}) => {
  const [products, setProducts] = useState([]);
  const [allTags, setAllTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterTags, setFilterTags] = useState([]);
  const [productId, setProductId] = useState();
  const [isVisible, setIsVisible] = useState(false);
  const getProducts = async () => {
    setLoading(true);
    await adminService
      .getProducts(filterTags)
      .then(data => {
        if (data.success) {
          setProducts(data.products);
        } else {
          ToastsStore.error(data.error);
        }
      })
      .catch((err) => ToastsStore.error(err.error));
    setLoading(false);
  };

  const reload = async () => {
    await adminService
      .getProducts(filterTags)
      .then(data => {
        if (data.success) {
          setProducts(data.products);
        } else {
          ToastsStore.error(data.error);
        }
      })
      .catch((err) => ToastsStore.error(err.error));
  };
  const { sort, sortField, sortOrder } = useSort(products, setProducts, getProducts);

  const closeModal = () => {
    setProductId();
    setIsVisible(false);
  };

  const tagsHandleChange = (event) => {
    setFilterTags(event.target.value);
  };

  const getAll = async () => {
    setLoading(true);
    await adminService
      .getProducts(filterTags)
      .then(data => {
        if (data.success) {
          setProducts(data.products);
        } else {
          ToastsStore.error(data.error);
        }
      })
      .catch((err) => ToastsStore.error(err.error));
    await adminService.getTags(token)
      .then((result) => {
        if (result.success && result.tags)
          setAllTags(result.tags);
      }).catch((err) => console.log(err.error));
    setLoading(false);
  };

  useEffect(() => {
    getAll();
  }, []);

  useEffect(() => {
    getProducts();
  }, [filterTags]);

  return (
    <>
      <DocModal
        isVisible={isVisible}
        onClose={closeModal}
        content={
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <p>
              Are you sure you want to delete this product?
            </p>
            <div className="row space-between">
              <DocButton
                color='green'
                text='No'
                onClick={closeModal}
              />
              <DocButton
                color='pink'
                text='Yes'
                onClick={async () => {
                  await adminService.deleteProduct(token, productId);
                  reload();
                  closeModal();
                }}
              />
            </div>
          </div>
        }
      />
      <div className='doc-container tables' style={{ justifyContent: 'unset' }}>
        <div style={styles.mainContainer}>
          <h2>Product Table</h2>
          <div style={{ width: 300 }}>
            <FormControl
              variant='filled'
              style={{ width: '100%' }}
            >
              <InputLabel
                htmlFor="grouped-select"
              >
                Filter by tags
              </InputLabel>
              <Select
                multiple
                disabled={loading}
                placeholder="Filter by tags"
                value={filterTags}
                onChange={tagsHandleChange}
              >
                {allTags.map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                  >
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <TableContainer
          style={{
            marginBottom: '40px',
          }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {TABLE_HEADERS.map(({titleName, propertyName, isSortable, align}) => (
                  <TableCell key={titleName} align={align} style={isSortable ? styles.tableTextHeader : styles.tableText}>
                    {isSortable ? (
                      <TableSortLabel
                        active={sortField === propertyName}
                        direction={sortOrder}
                        onClick={() => sort(propertyName)}
                        style={isSortable && align === 'center' ? { paddingLeft: '25px' } : {}}
                      >
                        {titleName}
                      </TableSortLabel>
                    ) : titleName}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell>
                    <LoadingSpinner />
                  </TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                </>
              ) : !!products.length > 0 ?
                products.map(product => (
                  <TableRow key={product.id}>
                    <TableCell
                      align='left'
                      style={{ ...styles.tableText }}
                    >
                      {get(product, 'id', '')}
                    </TableCell>
                    <TableCell
                      align='center'
                      style={{ ...styles.tableText }}
                    >
                      {get(product, 'title', '')}
                    </TableCell>
                    <TableCell
                      align='center'
                      style={{ ...styles.tableText }}
                    >
                      {product.country === 'US' ? '$' : '£'}{get(product, 'price', '0')}
                    </TableCell>
                    <TableCell
                      align='center'
                      style={{ ...styles.tableText }}
                    >
                      {get(product, 'sku', '')}
                    </TableCell>
                    <TableCell
                      align='center'
                      style={{ ...styles.tableText }}
                    >
                      {get(product, 'type', '')}
                    </TableCell>
                    <TableCell
                      align='center'
                      style={{ ...styles.tableText }}
                    >
                      {get(product, 'tags', []).join(', ')}
                    </TableCell>
                    <TableCell align='right' style={{ ...styles.tableText }}>
                      <div style={{ display: 'inline-flex' }}>
                        <LinkButton
                          text='View'
                          color='green'
                          linkSrc={`/super_admin/product/${product.id}`}
                        />
                        <div style={{ margin: '0 10px' }}>
                          <DocButton
                            text={!!product.out_of_stock ? 'Out of stock' : 'In stock'}
                            color={!!product.out_of_stock ? 'pink' : 'green'}
                            onClick={async () => {
                              await adminService.switchProductStatus(token, product.id, {
                                ...product,
                                out_of_stock: !!product.out_of_stock ? 0 : 1,
                              });
                              reload();
                            }}
                          />
                        </div>
                        <DocButton
                          text={!!product.active ? 'Active' : 'Inactive'}
                          color={!!product.active ? 'green' : 'pink'}
                          onClick={async () => {
                            await adminService.switchProductStatus(token, product.id, {
                              ...product,
                              active: !!product.active ? 0 : 1,
                            });
                            reload();
                          }}
                        />
                        <DocButton
                          text="Delete"
                          color="pink"
                          style={{ marginLeft: 10 }}
                          onClick={() => {
                            setProductId(product.id);
                            setIsVisible(true);
                          }}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                )) : (
                  <TableRow>
                    <TableCell style={styles.tableText}>
                      <p>No products to display</p>
                    </TableCell>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                  </TableRow>
                )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default ProductsTable;
