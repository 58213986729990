import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { get } from 'lodash';
import Grid from '@material-ui/core/Grid';
import moment from 'moment-timezone';
import { format } from 'date-fns';
import cityTimezones from 'city-timezones';
import { ToastsStore } from 'react-toasts';
import BigWhiteContainer from '../Containers/BigWhiteContainer';
import bookingFormModel from '../B2CComponents/bookingFormModel';
import bookingService from '../../services/bookingService';
import adminService from '../../services/adminService';
import COUNTRIES from '../../helpers/countries';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import {
  FIT_TO_FLY_PCR,
  FIT_TO_FLY_ANTIGEN,
  RECONSULT_DAY_2_ANTIGEN_US,
  DAY_2_ANTIGEN_US,
} from '../../helpers/productsWithAdditionalInfo';
import CountdownTimer from '../CountdownTimer';
import Summary from '../B2CComponents/Summary';
import AttractionWorldBookingForm from './AttractionWorldBookingForm';
import useValidationSchema from './validationSchema';

const attractionworldLogo = require('../../assets/images/attractionworld-logo.png');
const floridaLogo = require('../../assets/images/floridatix-logo.png');

const AttractionWorldBooking = ({ isFlorida = false }) => {
  const [shortToken, setShortToken] = useState();
  const [products, setProducts] = useState([]);
  const [timerStart, setTimerStart] = useState();
  const [status, setStatus] = useState(); // { severity, message }
  const [isLoading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [activePassenger, setActivePassenger] = useState(0);
  const { formInitialValues } = bookingFormModel;
  const defaultTimeZone = cityTimezones.findFromCityStateProvince('Westminster')[0];
  const defaultCountryCode = COUNTRIES.find(({ country }) => country === 'United Kingdom');
  const currentValidationSchema = useValidationSchema(activeStep, isFlorida);
  const steps = [
    'Number of travellers',
    'Travel Details',
    'Booking Appointment',
    'Passenger Details',
    'Summary',
    'Booking Confirmation',
  ];

  const passengerInitialValues = {
    fillWithBookingUser: '',
    firstName: '',
    lastName: '',
    email: '',
    countryCode: defaultCountryCode,
    phone: '',
    nhs: '',
    dateOfBirth: null,
    ethnicity: '',
    sex: '',
    postal_code: '',
    street_address: '',
    extended_address: '',
    locality: '',
    region: '',
    country: '',
    passportNumber: '',
    passportNumberConfirmation: '',
  };

  function handleBack() {
    (steps[activeStep] === 'Passenger Details' && activePassenger !== 0)
      ? setActivePassenger(activePassenger - 1)
      : setActiveStep(activeStep - 1);
  }
  function handleNext() {
    setActiveStep(activeStep + 1);
  }

  const getData = async () => {
    setLoading(true);
    await adminService.getProducts()
      .then(result => {
        if (result.success && result.products) {
          setProducts(result.products);
        }
      })
      .catch(() => ToastsStore.error('Error fetching products'));
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  if (isLoading) {
    return (
      <BigWhiteContainer>
        <div className='row center'>
          <LoadingSpinner />
        </div>
      </BigWhiteContainer>
    );
  }

  return (
    <BigWhiteContainer>
      <Grid
        container
        alignItems='center'
        justify="space-between"
        className={isFlorida ? 'partners-box' : 'aw-box partners-box'}
      >
        <Grid item xs={12} md={10}>
          <h3 className={isFlorida ? 'florida-title' : 'no-margin'}>
            COVID-19 Booking form for {isFlorida ? 'Floridatix' : 'Attraction World'} customers
          </h3>
        </Grid>
        <Grid item xs={12} md={2}>
          <img src={isFlorida ? floridaLogo : attractionworldLogo} className={isFlorida ? 'partners-logo' : 'aw-logo'} />
        </Grid>
      </Grid>
      <Formik
        initialValues={{
          ...formInitialValues,
          product: '',
          purchaseCode: [{ code: ''}],
          numberOfPeople: 1,
          testType: {},
          passengers: [
            {
              ...passengerInitialValues,
            },
          ],
          city: undefined,
          timezone: undefined,
        }}
        validationSchema={currentValidationSchema}
        onSubmit={async (values, actions) => {
          if (steps[activeStep] === 'Booking Appointment') {
            const { selectedSlot } = values;
            await bookingService.updateAppointmentStatus(
              selectedSlot.id,
              { status: 'LOCKED' },
              'token',
            ).then((response) => {
              if (response.success) {
                setTimerStart(new Date());
              }
            }).catch(() => console.log('error'));
            actions.setTouched({});
            actions.setSubmitting(false);
            actions.setErrors({});
            handleNext();
          } else if (steps[activeStep] === 'Number of travellers') {
            const {
              testType: { sku },
            } = values;
            if (sku === FIT_TO_FLY_ANTIGEN)
              actions.setValues({
                ...values,
                timezone: defaultTimeZone.timezone,
                city: defaultTimeZone,
              });
            handleNext();

          } else if (steps[activeStep] === 'Passenger Details') {
            const {
              numberOfPeople,
              passengers,
            } = values;
            if (activePassenger === (numberOfPeople - 1)) {
              actions.setSubmitting(false);
              actions.setTouched({});
              actions.setErrors({});
              handleNext();
            } else {
              if (get(passengers, `[${activePassenger + 1}].firstName`, 'default') === 'default') {
                const newPassengers = [...passengers];
                newPassengers.push({ ...passengerInitialValues });
                actions.setValues({
                  ...values,
                  passengers: newPassengers,
                });
              }
              setActivePassenger(activePassenger + 1);
              actions.setSubmitting(false);
              actions.setTouched({});
              actions.setErrors({});
            }
          } else if (steps[activeStep] === 'Summary') {
            const {
              numberOfPeople,
              selectedSlot,
              travelDate,
              travelTime,
              passengers,
              timezone: timezoneValue,
              testType: { title, id, sku, type, price, bundle_id, tags = [] },
              transportNumber,
              transportType,
              landingDate,
              landingTime,
              city,
              transit,
              purchaseCode,
              tocAccept,
            } = values;
            const travelDateInTz = moment(
              new Date(
                travelDate.getFullYear(),
                travelDate.getMonth(),
                travelDate.getDate(),
                travelTime.getHours(),
                travelTime.getMinutes(),
                0,
              )).tz(timezoneValue || defaultTimeZone.timezone, true).format();
            const isBookedAfterFlight = tags.includes('after_flight');
            const isAdditionalInfo = tags.includes('additional_info');
            const isUsProduct = sku === RECONSULT_DAY_2_ANTIGEN_US || sku === DAY_2_ANTIGEN_US;
            const isPCR = sku === FIT_TO_FLY_PCR;
            let shortTokenValue = shortToken;
            if (!shortToken) {
              const passengersPhone = `${passengers[0].countryCode.label}${passengers[0].phone.trim()}`;
              await adminService
                .createOrder({
                  billing_detail: {
                    name_on_card: `${passengers[0].firstName} ${passengers[0].lastName}`,
                    card_number: '4111111111111111',
                    expiry: `12/${moment().add(2, 'y').year().toString().slice(2)}`,
                    cvv: '123',
                    first_name: passengers[0].firstName,
                    last_name: passengers[0].lastName,
                    email: passengers[0].email,
                    date_of_birth: passengers[0].dateOfBirth,
                  },
                  billing_address: {
                    country: passengers[0].country,
                    postcode: '',
                    address_1: '',
                    town: '',
                    telephone: passengersPhone,
                    county: '',
                  },
                  shipping_address: {
                    postcode: passengers[0].postal_code,
                    country: 'GB',
                    address_1: passengers[0].street_address,
                    town: passengers[0].locality,
                    telephone: passengersPhone,
                    county: passengers[0].region,
                  },
                  source: isFlorida ? 'floridatix' : 'attractionworld',
                  items: [
                    {
                      product_id: id,
                      title,
                      sku,
                      quantity: numberOfPeople,
                      price,
                    },
                  ],
                  discount: purchaseCode[0].code.trim(),
                }).then(async (result) => {
                  if (
                    result.success && result.order_details
                  ) {
                    const orderId = result.order_details.id;
                    shortTokenValue = result.order_details.short_token;
                    setShortToken(shortTokenValue);
                    await adminService.updateOrderPayment(orderId, {
                      payment_flag: 'Complete',
                      id: orderId,
                    });
                  } else {
                    setStatus({
                      severity: 'error',
                      message: result.message,
                    });
                    return;
                  }
                })
                .catch(({ error }) => {
                  setStatus({
                    severity: 'error',
                    message: error,
                  });
                  return;
                });
            }
            const booking_users = Array.from(Array(numberOfPeople).keys()).map((item) => {
              const {
                nhs,
                firstName,
                lastName,
                dateOfBirth,
                passportNumber,
                phone,
                countryCode,
                vaccineNumber,
                vaccineStatus,
                vaccineType,
                vaccineTypeName,
                ...rest
              } = passengers[item];
              return ({
                first_name: firstName,
                last_name: lastName,
                tz_location: ((isBookedAfterFlight || isPCR) && !isUsProduct) ? defaultTimeZone.timezone : timezoneValue,
                date_of_birth: moment.utc(format(dateOfBirth, 'dd/MM/yyyy'), 'DD/MM/YYYY').format(),
                language: 'EN',
                phone: `${countryCode.label}${phone.trim()}`,
                country: 'UK',
                product_id: parseInt(id),
                bundle_id: parseInt(bundle_id),
                toc_accept: tocAccept,
                ...(isAdditionalInfo ? {
                  vaccine_information: {
                    number: vaccineNumber,
                    status: vaccineStatus,
                    type: vaccineType === 'Other' ? vaccineTypeName : vaccineType,
                  }
                } : {}),
                metadata: {
                  transit,
                  source: isFlorida ? 'floridatix' : 'attractionworld',
                  discount: purchaseCode[item],
                  short_token: shortTokenValue,
                  product_id: parseInt(id),
                  passport_number: passportNumber,
                  travel_date: travelDateInTz,
                  test_type: type,
                  ...(!!nhs ? { nhs: nhs } : {}),
                },
                ...rest,
              });
            });
            for (let index = 1; index < purchaseCode.length; index++) {
              await adminService.checkPurchaseCodeInfo(purchaseCode[index].code)
                .then(async (result) => {
                  if (result.success && result.data && result.data.value && result.data.uses) {
                    await adminService.useDiscountCode(result.data.id);
                  }
                }).catch((error) => console.log(error));
            }
            const body = {
              type: 'video_gp_euro',
              booking_users,
              flight_details: {
                transport_arrival_country: isBookedAfterFlight ? 'GB' : timezoneValue,
                transport_arrival_date_time: moment(
                  new Date(
                    landingDate.getFullYear(),
                    landingDate.getMonth(),
                    landingDate.getDate(),
                    landingTime.getHours(),
                    landingTime.getMinutes(),
                    0,
                  )).tz(timezoneValue || defaultTimeZone.timezone, true).format(),
                transport_departure_country: isBookedAfterFlight ? city.iso2 : 'GB',
                transport_departure_date_time: travelDateInTz,
                transport_number: transportNumber,
                transport_type: transportType,
              },
            };
            await bookingService
              .paymentRequest(selectedSlot.id, body)
              .then(result => {
                if (result.success && result.confirmation) {
                  handleNext();
                  setTimerStart();
                } else {
                  setStatus({
                    severity: 'error',
                    message: result.message,
                  });
                }
              })
              .catch(({ error }) => {
                setStatus({
                  severity: 'error',
                  message: error === 'Something went wrong, please try again.' ? 'Unfortunately, this time slot has been already taken. Please choose another one. Thank you!' : error,
                });
              });
          } else {
            actions.setTouched({});
            actions.setSubmitting(false);
            actions.setErrors({});
            handleNext();
          }
        }}
      >
        <>
          <div className="fixed-box">
            {(activeStep < 4 && activeStep > 0) && (
              <Summary
                isPharmacy
                activeStep={activeStep}
                defaultTimezone={defaultTimeZone}
              />
            )}
            {timerStart && (
              <div className="countdown-timer">
                <p>
                  Your appointment is available for the next&nbsp;
                  <CountdownTimer
                    timerStart={timerStart.getTime()}
                    timerStop={new Date(new Date(timerStart).setMinutes(timerStart.getMinutes() + 30)).getTime()}
                    onTimeEnd={() => {
                      setTimerStart();
                      setActiveStep(2);
                      setActivePassenger(0);
                    }}
                  /> min<br />
                  If you do not complete the booking you might need to select another appointment
                </p>
              </div>
            )}
          </div>
          <AttractionWorldBookingForm
            isPharmacy
            products={products}
            activePassenger={activePassenger}
            activeStep={activeStep}
            defaultTimezone={defaultTimeZone}
            handleBack={handleBack}
            status={status}
            isFlorida={isFlorida}
            steps={steps}
            timer={timerStart}
            dropTimer={() => setTimerStart()}
          />
        </>
      </Formik>
    </BigWhiteContainer>
  );
};

export default AttractionWorldBooking;
