import React, { useState } from 'react';
import { get } from 'lodash';
import { ToastsStore } from 'react-toasts';
import { format, differenceInMilliseconds  } from 'date-fns';
import { lowerCase } from 'lodash';
import Collapse from '@material-ui/core/Collapse';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useServerDateFilter, DateFilter } from '../../helpers/hooks/useServerDateFilter';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import adminService from '../../services/adminService';
import DocButton from '../DocButton/DocButton';
import chatService from '../../services/chatService';
import { useHistory } from 'react-router-dom';
import addParticipant from '../../helpers/addParticipant';
import useTwilioClient from '../../helpers/hooks/useTwilioClient';
import TwilioVoiceCall from '../VoiceCall/TwilioVoiceCall';
import './Tables.scss';
import useTwilioVoiceCall from '../../helpers/hooks/useTwilioVoiceCall';

const styles = {
  tableText: {
    fontSize: 16,
  },
  smallCol: {
    width: '15%',
    maxWidth: '15%',
    fontSize: 16,
  },
  medCol: { width: '25%', maxWidth: '25%' },
  bntStyles: {
    marginLeft: '10px',
    marginTop: '0px',
    marginRight: '10px',
    boxSizing: 'border-box',
    maxWidth: '40%',
  },
  mainContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
};

const timeDifference = (now, start_time, status) => {
  if (start_time.getTime() > now.getTime()) {
    // Future
    return format(new Date(differenceInMilliseconds(start_time, now)), 'hh:mm:ss');
  } else {
    // Past
    if (status === 'Offline') {
      return format(new Date(differenceInMilliseconds(now, start_time)), 'hh:mm:ss') + ' Ago';
    }
  }
};

const ShiftOverview = ({ token, user, isTimeFilters = false }) => {
  const identity = `${get(user, 'first_name', '')}${get(user, 'last_name', '')}`;
  const { token: TVCToken } = useTwilioVoiceCall(identity);
  const history = useHistory();
  const userId = get(user, 'id', 0);
  const userName = `${get(user, 'first_name', '')} ${get(user, 'last_name', '')}`;
  const { client } = useTwilioClient(userName);
  const [checked, setChecked] = useState(true);
  const {
    filter,
    setFilter,
    isLoading,
    appointments: doctors,
    setEndTime,
    setStartTime,
    start_time,
    end_time,
  } = useServerDateFilter({
    token,
    query: adminService.getShiftOverview,
  });
  const sortedDoctors = doctors.sort((aDoctor, bDoctor) => ((!!aDoctor.start_time ? new Date(aDoctor.start_time).getTime() : 0) - (!!bDoctor.start_time ? new Date(bDoctor.start_time).getTime() : 0)));
  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  const createChatWithPractitioner = async (user_id, participantName) => {
    await chatService.createRoom(
      token,
      {
        type: 'internal',
        user_role: 'practitioner',
        status: 'live',
        room_members: [
          { user_id: userId },
          { user_id }
        ],
      },
    ).then(async (result) => {
      if (result.success) {
        await addParticipant({
          roomId: result.roomId.toString(),
          participantName,
          client,
        });
        history.push('/chats/internal');
      } else {
        ToastsStore.error(result.error);
      }
    })
      .catch((err) => ToastsStore.error(err.error));
  };

  return (
    <div className='doc-container tables' style={{ height: '100%', justifyContent: 'unset' }}>
      <div style={styles.mainContainer}>
        <div className='row no-margin' style={{ paddingBottom: 10, cursor: 'pointer' }} onClick={handleChange}>
          <h2>Shift Overview</h2>
          {checked ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </div>
        {(isTimeFilters && checked) && (
          <DateFilter
            filter={filter}
            setFilter={setFilter}
            appointments={doctors}
            setEndTime={setEndTime}
            setStartTime={setStartTime}
            start_time={start_time}
            end_time={end_time}
          />
        )}
      </div>
      <Collapse in={checked}>
        <TableContainer
          style={{
            marginBottom: '40px',
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align='left' style={styles.tableText}>Practitioner Name</TableCell>
                <TableCell align='center' style={styles.tableText}>Date</TableCell>
                <TableCell align='center' style={styles.tableText}>Start Time</TableCell>
                <TableCell align='center' style={styles.tableText}>End Time</TableCell>
                <TableCell align='center' style={styles.tableText}>Start in</TableCell>
                <TableCell align='center' style={styles.tableText}>1st App Time</TableCell>
                <TableCell align='center' style={styles.tableText}>Status</TableCell>
                <TableCell align='right' style={styles.tableText}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <LoadingSpinner />
                </TableRow>
              ) : (typeof sortedDoctors !== 'undefined' &&
                typeof sortedDoctors === 'object' &&
                sortedDoctors.length > 0 &&
                sortedDoctors.map((doctor, indx) => {
                  let isAppointmentSoon = false;
                  const now = new Date();
                  const start_time = new Date(doctor.start_time);
                  now.setMinutes(now.getMinutes() - 10);
                  if (start_time.getTime() < now.getTime()) {
                    if (doctor.status === 'Offline') {
                      isAppointmentSoon = true;
                    }
                  };

                  return (
                    <TableRow key={indx}>
                      <TableCell align='left' className={isAppointmentSoon && 'red-bold-text'} style={{ ...styles.tableText }}>
                        {doctor.name}
                      </TableCell>
                      <TableCell align='center' style={{ ...styles.tableText }}>
                        {format(new Date(doctor.start_time), 'dd/MM/yyyy')}
                      </TableCell>
                      <TableCell align='center' style={{ ...styles.tableText }}>
                        {format(new Date(doctor.start_time), 'p')}
                      </TableCell>
                      <TableCell align='center' style={{ ...styles.tableText }}>
                        {format(new Date(doctor.end_time), 'p')}
                      </TableCell>
                      <TableCell align='center' className={isAppointmentSoon && 'red-bold-text'} style={{ ...styles.tableText }}>
                        {timeDifference(now, start_time, doctor.status)}
                      </TableCell>
                      <TableCell align='center' style={{ ...styles.tableText }}>
                        {doctor.next_appointment ? format(new Date(doctor.next_appointment), 'p') : ''}
                      </TableCell>
                      <TableCell align='center' className={isAppointmentSoon ? 'red-bold-text' : `text-status-${lowerCase(doctor.status)}`} style={{ ...styles.tableText }}>
                        {doctor.status}
                      </TableCell>
                      <TableCell align='right' style={{ ...styles.tableText }}>
                        <div className='row'>
                          {TVCToken && (
                            <TwilioVoiceCall
                              isTable
                              token={TVCToken}
                              phoneNumber={get(doctor, 'telephone')}
                            />
                          )}
                          <DocButton
                            text="Chat"
                            color="green"
                            style={{ marginLeft: 10 }}
                            onClick={() => createChatWithPractitioner(doctor.user_id, doctor.name)}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                }))}
              {typeof sortedDoctors !== 'object' || sortedDoctors.length === 0 && (
                <TableRow>
                  <TableCell style={styles.tableText}>
                    <p>No doctors to display</p>
                  </TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Collapse>
    </div>
  );
};

export default ShiftOverview;
