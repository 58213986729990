import React, { useState } from 'react';
import clsx from 'clsx';
import {
  NavLink,
  Link,
} from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import ForumIcon from '@material-ui/icons/Forum';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import UserMenu from './UserMenu';
import ClaimableNotification from './ClaimableNotification';
import { DOCTORS_MANAGEMENT_ROLES, LIVE_APPOINTMENT_RELATED_ROLES } from '../../helpers/roles';
import './Navigation.scss';

const docIcon = require('../../assets/images/icons/dochq-logo-rect-white.svg');
const vistaLogo = require('../../assets/images/vista-logo.png');
const live = require('../../assets/images/icons/live.svg');
const liveActive = require('../../assets/images/icons/live-active.svg');
const calendar = require('../../assets/images/icons/calendar.svg');
const calendarActive = require('../../assets/images/icons/calendar-active.svg');
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  appBar: {
    padding: 0,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
	    marginRight: 36,
    color: 'white',
  },
  hide: {
	    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(8) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  appToolbar: {
    width: '100%',
    height: '100%',
    justifyContent: 'space-between',
    padding: 20,
  },
  vistaLogo: {
    paddingLeft: 25,
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  blackText: {
    color: 'black',
  },
}));

const AppointmentRelatedNavigator = ({
  role,
  title,
  isAuthenticated,
  user,
  token,
  logout,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const isVista = window.location.href.includes('vista');
  const pathname = window.location.pathname;
  const isDoctorsManagementAllowed = DOCTORS_MANAGEMENT_ROLES.includes(role);
  const isLiveAppointmentsAvailable = LIVE_APPOINTMENT_RELATED_ROLES.includes(role);

  return (
    <>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, 'top-navigation-container', {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar className={classes.appToolbar}>
          <div className={classes.logoContainer}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => setOpen(true)}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: open,
              })}
            >
              <MenuIcon fontSize="large" />
            </IconButton>
            <div className='practice-logo extended'>
              <Link to={`/${typeof role === 'object' ? role && role.name : role}/dashboard`}>
                <img src={docIcon} alt='DocHQ Icon'/>
              </Link>
            </div>
            {isVista && (
              <div className='practice-logo vista-logo extended'>
                <img src={vistaLogo} alt='Vista Health'/>
              </div>
            )}
          </div>
          <h1 className='page-title extended'>{title}</h1>
          {isAuthenticated && (
            <>
              <ClaimableNotification title={title} token={token} />
              <UserMenu
                user={user}
                logout={logout}
              />
            </>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={() => setOpen(false)}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>
          <NavLink
            to={`/${role}/dashboard`}
            activeClassName='active'
            style={{ display: 'flex' }}
          >
            <ListItem button>
              <ListItemIcon>
                <i
                  className={`fa fa-home ${pathname.indexOf('dashboard') > -1 ? 'active-icon' : 'usual-icon'}`}
                  style={{
                    fontSize: '36px',
                  }}
                >
                </i>
              </ListItemIcon>
              <ListItemText
                primary={'Home'}
                className={clsx({
                  [classes.blackText]: pathname !== `/${role}/dashboard`,
                })}
              />
            </ListItem>
          </NavLink>
          {isLiveAppointmentsAvailable && (
            <NavLink
              activeClassName='active'
              to={`/${role}/live/dashboard`}
              style={{ display: 'flex' }}
            >
              <ListItem button>
                <ListItemIcon>
                  <img
                    alt='Vista Health'
                    style={{ fontSize: '36px' }}
                    src={pathname.indexOf('live') > -1 ? liveActive : live}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={'Live Appointments'}
                  className={clsx({
                    [classes.blackText]: pathname.indexOf('live') === -1,
                  })}
                />
              </ListItem>
            </NavLink>
          )}
          {isDoctorsManagementAllowed && (
            <NavLink
              activeClassName='active'
              to={`/${role}/doctors-management`}
              style={{ display: 'flex' }}
            >
              <ListItem button>
                <ListItemIcon>
                  <img
                    alt='Vista Health'
                    style={{ fontSize: '36px', paddingLeft: 7 }}
                    src={pathname === `/${role}/doctors-management` ? calendarActive : calendar}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Doctors Management"
                  className={clsx({
                    [classes.blackText]: pathname !== `/${role}/doctors-management`,
                  })}
                />
              </ListItem>
            </NavLink>
          )}
          <NavLink
            activeClassName="active"
            to="/chats/internal"
            style={{ display: 'flex' }}
          >
            <ListItem button>
              <ListItemIcon>
                <ChatBubbleIcon
                  alt="Chats"
                  style={{ fontSize: '36px' }}
                  className={pathname.indexOf('internal') > -1 ? 'active-icon' : 'usual-icon'}
                />
              </ListItemIcon>
              <ListItemText
                primary="Internal Chats"
                className={clsx({
                  [classes.blackText]: pathname.indexOf('internal') === -1,
                })}
              />
            </ListItem>
          </NavLink>
          <NavLink
            activeClassName='active'
            to="/chats/customer"
            style={{ display: 'flex' }}
          >
            <ListItem button>
              <ListItemIcon>
                <ForumIcon
                  alt='Chat with Customers'
                  style={{ fontSize: '36px' }}
                  className={pathname.indexOf('customer') > -1 ? 'active-icon' : 'usual-icon'}
                />
              </ListItemIcon>
              <ListItemText
                primary={'Chats with Customers'}
                className={clsx({
                  [classes.blackText]: pathname.indexOf('customer') === -1,
                })}
              />
            </ListItem>
          </NavLink>
        </List>
      </Drawer>
    </>
  );
};

export default AppointmentRelatedNavigator;
