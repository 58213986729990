import { useState, useEffect } from 'react';
import { ToastsStore } from 'react-toasts';
import chatService from '../../services/chatService';

const useFetchChats = ({
  token,
  entity,
  entityValue,
  status,
  type,
}) => {
  const [chats, setChats] = useState([]);
  const [isLoading, setIsLoading] = useState([]);
  const [fetchData, setFetchData] = useState(true);

  const refetchData = () => setFetchData(!fetchData);

  const getChats = async () => {
    setIsLoading(true);
    await chatService.getRooms(token, entity, entityValue, type, status)
      .then(data => {
        if (data.success) {
          setChats(data.chats && !!data.chats.length ? data.chats : []);
        } else ToastsStore.error(data.error);
      })
      .catch((err) => ToastsStore.error(err.error));
    setIsLoading(false);
  };

  useEffect(() => {
    if (token && entityValue)
      getChats();
  }, [fetchData]);

  return ({
    chats,
    refetchData,
    isLoading,
  });
};

export default useFetchChats;
