import React, { useEffect, useState } from 'react';
import { useDebounce } from 'react-use';
import { get } from 'lodash';
import {
  Box,
  FormControl,
  TextField,
} from '@material-ui/core';
import { SearchOutlined } from '@material-ui/icons';


const InputFilter = ({
  entity,
  filters,
  fieldName,
  variant = 'outlined',
  dropOtherValues = false,
  handleFilterUpdate,
  ...restProps
}) => {
  const filterValue = get(filters, fieldName, '');
  const [value, setValue] = useState('');

  useDebounce(
    () => {
      handleFilterUpdate && handleFilterUpdate(fieldName, value, dropOtherValues);
    },
    300,
    [value]
  );

  useEffect(() => {
    if (filterValue.length === 0) {
      setValue('');
    }
  }, [filterValue]);

  return (
    <FormControl variant={variant} {...restProps}>
      <Box display='flex' alignItems='center' justifyContent='center'>
        <SearchOutlined style={{fontSize: 30, marginRight: 4}}/>
        <TextField
          labelId={`${entity}-filter`}
          id={`${entity}-filter`}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          displayEmpty
          variant='standard'
          placeholder={`Search by ${entity.toLowerCase()}...`}
        />
      </Box>
    </FormControl>
  );
};

export default InputFilter;
