import React, { useEffect, useState, memo, useRef, useContext } from 'react';
import { get } from 'lodash';
import Video from 'twilio-video';
import { ToastsStore } from 'react-toasts';
import { format } from 'date-fns';
import { Redirect } from 'react-router-dom';
import Controls from '../Controls/Controls';
import InVid from '../IncomingVideo/InVid';
import OutVid from '../OutgoingVideo/OutVid';
import DocModal from '../DocModal/DocModal';
import DocButton from '../DocButton/DocButton';
import bookingService from '../../services/bookingService';
import { AppointmentContext, useBookingUsers } from '../../context/AppointmentContext';
import nurseSvc from '../../services/nurseService';
import { ONLINE_CHECK_STATUSES } from '../Tables/status';
import './VideoCallAppointment.scss';

const dochqLogo = require('../../assets/images/icons/dochq-logo-rect-white.svg');
const dochqLogoSq = require('../../assets/images/icons/dochq-logo-sq-white.svg');
const { isSupported } = require('twilio-video');

const roomDisconnect = (room) => {
  try {
    if (!!room && !!room.disconnect)
      room.disconnect();
    room.localParticipant.tracks.forEach(function(trackPublication) {
      trackPublication.track.stop();
    });
  } catch (err) {
    console.log(err);
  }
};

const useDisconnect = (roomVal) => {
  const roomRef = useRef(roomVal);
  useEffect(() => {
    roomRef.current = roomVal;
  }, [roomVal]);

  useEffect(() => {
    return () =>
      roomDisconnect(roomRef.current);
  }, []);
};

function TwillioVideoCall ({
  isNurse,
  updateImageData,
  token,
  appointmentId,
  captureDisabled,
  authToken,
  appointmentInfo,
  hideVideoAppointment,
}) {
  const {
    storeImage,
    displayCertificates,
    status_changes,
  } = useContext(AppointmentContext);
  const [isEarly, setIsEarly] = useState((isNurse ? 0 : new Date(appointmentInfo.start_time).getTime() - new Date().getTime()) > 0);
  const patients = useBookingUsers();
  const [counter, setCounter] = useState(0);
  const [room, setRoom] = useState(null);
  const [videoDevice, setVideoDevice] = useState('');
  const [videoTracks, setVideoTracks] = useState([]);
  const [participants, setParticipants] = useState([]);
  const [userMediaDevices, setUserMediaDevices] = useState([]);
  const [isMuted, setIsMuted] = useState(false);
  const [bookingUsers, setBookingUsers] = useState(isNurse ? [...(!!patients && !!patients.length ? patients : [])] : []);
  const [isCloseCallVisible, setIsCloseCallVisible] = useState(false);
  const [isVideoClosed, setIsVideoClosed] = useState(false);
  const [scanQr, setScanQr] = useState(false);
  const [isAppointmentUnfinished, setIsAppointmentUnfinished] = useState(false);
  const [takePhoto, setTakePhoto] = useState(false);
  const statusChanges = status_changes || [];
  const isSomeoneConnected = participants.length > 0;
  const lastStatus = (get(statusChanges, `${[statusChanges.length - 1]}`, ''));
  const currentBookingUserName = `${get(bookingUsers, '[0].first_name', '')} ${get(bookingUsers, '[0].last_name', '')}`;
  const currentBookingUserId = get(bookingUsers, '[0].id', '');
  const [message, setMessage] = useState(
    isNurse
      ? (!!lastStatus && lastStatus.changed_to === ONLINE_CHECK_STATUSES.patientAttended) ? `Patient joined at ${format(new Date(lastStatus.created_at), 'dd/MM/yyyy pp')}` : 'Your patient will be with you shortly'
      : 'Your medical practitioner will be with you shortly'
  );
  const patientStatusChangesArray = status_changes.map(s => s.changed_to);
  const isPatientOnline = patientStatusChangesArray.lastIndexOf(ONLINE_CHECK_STATUSES.patientAttended) > patientStatusChangesArray.lastIndexOf(ONLINE_CHECK_STATUSES.patientLeft);
  const disconnectRoom = () => {
    setParticipants([]);
    roomDisconnect(room);
  };
  const handleHideVideoAppointment = () => {
    if (!!hideVideoAppointment) {
      hideVideoAppointment();
    }
    disconnectRoom();
  };
  const getDevices = (mediaDevices) =>
    setUserMediaDevices([...mediaDevices.filter(({ kind }) => kind === 'videoinput')]);
  function capturePhoto() {
    setTakePhoto(true);
    setTimeout(() => {
      setTakePhoto(false);
    }, 100);
  }
  const uploadImageForUser = (img) => {
    if (!!bookingUsers.length) {
      storeImage(img);
      const newBookingUsers = [...bookingUsers];
      newBookingUsers.shift();
      setBookingUsers(newBookingUsers);
    }
  };
  function updateImage(data) {
    updateImageData(data);
  }
  const attachTracks = (tracks) => {
    tracks.forEach(function(track) {
      if (track) {
        setVideoTracks(videoTracks => [track, ...videoTracks]);
      }
    });
  };
  const detachTracks = (tracks) => {
    tracks.forEach(function(track) {
		  if (track) {
        setVideoTracks(videoTracks => videoTracks.filter(v => v !== track));
		  }
    });
  };
  function stopTracks(tracks) {
    tracks.forEach(function(track) {
      if (track) { track.stop(); }
    });
  }
  const updateVideoDevice = (device) => {
    const localParticipant = room.localParticipant;
    const tracks = Array.from(localParticipant.videoTracks.values()).map(
      function(trackPublication) {
        return trackPublication.track;
      }
    );
    localParticipant.unpublishTracks(tracks);
    detachTracks(tracks);
    stopTracks(tracks);
    Video.createLocalVideoTrack({
      deviceId: { exact: device }
    }).then((localVideoTrack) => {
      localParticipant.publishTrack(localVideoTrack);
      attachTracks([localVideoTrack]);
    });
    setVideoDevice(device);
  };
  const updateAppointmentStatus = (status) =>
    bookingService.updateAppointmentStatus(appointmentId, { status }, authToken)
      .then((resp) => !!resp.error ? ToastsStore.error(resp.error, 10000) : null)
      .catch(err => isNurse ? ToastsStore.error(err.error, 10000) : console.log(err.error));

  const handleDisconnect = async () => {
    if (isNurse) {
      await nurseSvc
        .getAppointmentDetails(appointmentId, token)
        .then(result => {
          if (result.success && result.appointment) {
            const { booking_users } = result.appointment;
            if ([...booking_users].filter((patient) => (!get(patient, 'metadata.result') && !get(patient, 'metadata.sample_taken'))).length) {
              setIsAppointmentUnfinished(true);
            } else {
              setIsAppointmentUnfinished(false);
            }
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
    setIsCloseCallVisible(true);
  };
  const handlePause = async () => {
    await updateAppointmentStatus('ON_HOLD');
    handleHideVideoAppointment();
  };
  const handleToggleAudio = () => {
    if (!!room && !!room.localParticipant) {
      room.localParticipant.audioTracks.forEach(track => {
        if (track.track.isEnabled) {
          track.track.disable();
        } else {
          track.track.enable();
        }
        setIsMuted(!track.track.isEnabled);
      });
    }
  };

  useEffect(() => {
    const participantConnected = participant => {
      setMessage(isNurse ? 'Patient Connected' : 'Medical Professional Connected');
      setParticipants(prevParticipants => [...prevParticipants, participant]);
    };
    const participantDisconnected = participant => {
      setMessage(isNurse ? 'Patient Disconnected' : 'Medical Professional Left');
      if (isNurse) updateAppointmentStatus(ONLINE_CHECK_STATUSES.patientLeft);
      setParticipants(prevParticipants => prevParticipants.filter(p => p !== participant));
    };

    Video.connect(token, {
      name: appointmentId,
      audio: true,
      video: {
        video: { width: 720 },
      }
    }).then(room => {
      setRoom(room);
      room.on('participantConnected', participantConnected);
      room.on('participantDisconnected', participantDisconnected);
      room.on('trackSubscribed', function(track) {
        attachTracks([track]);
      });
      room.on('trackUnsubscribed', function(track) {
        detachTracks([track]);
      });
      room.participants.forEach(participantConnected);
    }).catch(err => console.log(err));
  }, [token]);

  useEffect(() => {
    if (!isNurse) navigator.mediaDevices.enumerateDevices().then(getDevices);
    return () => {
      setTimeout(async () => {
        if (isNurse) {
          await nurseSvc
            .getAppointmentDetails(appointmentId, token)
            .then(result => {
              if (result.success && result.appointment) {
                const { status } = result.appointment;
                if (status !== 'ON_HOLD' && status !== 'COMPLETED') {
                  updateAppointmentStatus(ONLINE_CHECK_STATUSES.practitionerLeft);
                }
              }
            })
            .catch(err => {
              console.log(err);
            });
        } else if (!isNurse) updateAppointmentStatus(ONLINE_CHECK_STATUSES.patientLeft);
      }, 2000);
    };
  }, []);

  useDisconnect(room);

  useEffect(() => {
    if (!isNurse && isEarly && !isSomeoneConnected) { // 3 min until show message
      const interval = setInterval(() => {
        const timeDifference = new Date(appointmentInfo.start_time).getTime() - new Date().getTime();
        const newIsEarly = timeDifference > 0;
        setIsEarly(newIsEarly);
        setMessage(
          <p>
            {newIsEarly && (
              <>
                Your appointment starts in {format(new Date(timeDifference), 'mm:ss')}<br />
              </>
            )}
						Please wait for your practitioner to join the call.<br />
						Thank you.
          </p>
        );
      }, 1000);
      if (!isEarly) {
        setCounter(1);
        clearInterval(interval);
      }
      return () => clearInterval(interval);
    }
  }, [isEarly]);

  useEffect(() => {
    if (counter < 180 && !isNurse && !isEarly && !isSomeoneConnected) { // 3 min until show message
      const interval = setInterval(() => {
        setCounter((prev) => prev + 1);
      }, 1000);
      return () => clearInterval(interval);
    } else if (!isNurse && counter >= 180 && !isEarly && !isSomeoneConnected) {
      setMessage(
        <p>
					Apologies for the delay. Your practitioner is running late on the previous appointment but will be with you as soon as possible. Thank you very much for your understanding.<br/><br/>
					If you are waiting for your test results, no worries, your health care professional will be back with you on time to read your test results.
        </p>
      );
    }
  }, [counter, isEarly]);

  return isSupported ? (
    <React.Fragment>
      <DocModal
        isVisible={isCloseCallVisible}
        onClose={() => setIsCloseCallVisible(false)}
        content={
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {(isAppointmentUnfinished && isNurse) && (
              <>
                <p className="red-bold-text">Warning!</p>
                <p className="red-bold-text">You have not submitted results for all patients.</p>
              </>
            )}
            <p>Are you sure you want to end this call?</p>
            <div className="row space-between">
              <DocButton
                color='green'
                text='No'
                onClick={() => setIsCloseCallVisible(false)}
                style={{ marginRight: '5px' }}
              />
              <DocButton
                color='pink'
                text='Yes'
                onClick={async () => {
                  if (isNurse) {
                    if (!!room) {
                      await updateAppointmentStatus('COMPLETED');
                      handleHideVideoAppointment();
                    }
                    setIsVideoClosed(true);
                  }
                  else if (!!room) {
                    updateAppointmentStatus(ONLINE_CHECK_STATUSES.patientLeft);
                    room.disconnect();
                  }
                  setMessage('Call has been closed');
                  setIsCloseCallVisible(false);
                }}
              />
            </div>
          </div>
        }
      />
      <DocModal
        isVisible={isVideoClosed}
        onClose={() => setIsVideoClosed(false)}
        content={
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <p>Your call is closed</p>
            <div style={{ paddingTop: '20px', textAlign: 'center' }}>
              <DocButton
                color='grey'
                text='Close'
                onClick={() => setIsVideoClosed(false)}
                style={{ margin: '5px' }}
              />
            </div>
          </div>
        }
      />
      <div className={`video-call-container ${isPatientOnline ? 'shadow-online' : ''}`}>
        <React.Fragment>
          {typeof isNurse !== 'undefined' && !isNurse ? <PatientHeader /> : null}
          <Controls
            isMuted={isMuted}
            scanQr={scanQr}
            videoDevice={videoDevice}
            updateVideoDevice={({target: { value }}) => updateVideoDevice(value)}
            userMediaDevices={userMediaDevices}
            isPause={!!hideVideoAppointment}
            updateMuted={handleToggleAudio}
            capturePhoto={capturePhoto}
            handlePause={handlePause}
            handleScanQr={() => setScanQr(!scanQr)}
            isNurse={typeof isNurse !== 'undefined' ? isNurse : false}
            handleDisconnect={handleDisconnect}
            currentBookingUserName={currentBookingUserName}
            captureDisabled={captureDisabled || !bookingUsers.length || !displayCertificates}
          />
          <React.Fragment>
            {room && (
              <OutVid
                participant={room.localParticipant}
                localVideoTracks={videoTracks}
              />
            )}
            {isSomeoneConnected &&
							participants.map((participant, indx) => (
							  <InVid
							    key={indx}
							    scanQr={scanQr}
							    takePhoto={takePhoto}
							    stopScanQr={() => setScanQr(false)}
							    participant={participant}
							    localVideoTracks={videoTracks}
							    updateImageData={updateImage}
							    storeImage={uploadImageForUser}
							    currentBookingUserName={currentBookingUserName}
							    currentBookingUserId={currentBookingUserId}
							  />
							))}
          </React.Fragment>
          <Message message={message} />
        </React.Fragment>
      </div>
    </React.Fragment>
  ) : (
    <Redirect to='/unsupported-browser' />
  );
}

export default memo(TwillioVideoCall);

export const PatientHeader = () => (
  <div className='patient-header'>
    <img src={dochqLogo} alt='DocHQ Logo' className='hide-on-sm' />
    <img src={dochqLogoSq} alt='DocHQ Logo' className='show-on-sm' />
    <h3>Video Consultation</h3>
    <div style={{ width: 150 }}/>
  </div>
);

const Message = ({ message }) => (
  <div className='message-background'>{message || 'hello world'}</div>
);
