import React, { useState, useEffect } from 'react';
import {
  Alert,
  Autocomplete,
} from '@material-ui/lab';
import { useDebounce } from 'react-use';
import { get } from 'lodash';
import {
  Field,
  useFormikContext
} from 'formik';
import {
  Radio,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Checkbox,
  FormGroup,
} from '@material-ui/core';
import Input from '../FormComponents/Input';
import bookingFormModel from './bookingFormModel';
import ADDITIONAL_PRODUCT_TEXT from './additionalProductText';
import adminService from '../../services/adminService';
import {
  ANTIGEN_CONSULTATION,
  CERTIFICATE_PRODUCTS,
  DAY_2_ANTIGEN_US,
  FIT_TO_FLY_ANTIGEN,
} from '../../helpers/productsWithAdditionalInfo';
import './BookingEngine.scss';

const Step0 = ({
  isEdit,
  source,
  items = [],
  isPharmacy,
  isBookingSkip,
  bookingUsersQuantity,
}) => {
  const isDocUsSource = source === 'dochq-us';
  const [approvedProducts, setApprovedProducts] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [products, setProducts] = useState([]);
  const {
    formField: {
      product: productField,
      numberOfPeople,
      purchaseCode,
      selectedKit,
      isAppointmentAddressSame,
      streetAddress,
      extendedAddress,
      locality,
      county,
      postalCode,
    }
  } = bookingFormModel;
  const {
    values: {
      product: productValue,
      purchaseCodeError,
      purchaseCode: purchaseCodeValue,
      isAppointmentAddressSame: isAppointmentAddressSameValue,
      testType,
    },
    touched,
    setFieldValue,
  } = useFormikContext();

  const getApprovedProducts = async () => {
    await adminService.getApprovedProducts()
      .then(result => {
        if (result.success && result.kits) {
          setApprovedProducts([...result.kits].sort(({name: nameA}, {name: nameB}) => nameA < nameB ? -1 : nameA > nameB ? 1 : 0));
        } else {
          setApprovedProducts([]);
          console.log(result.error);
        }
      }).catch((error) => {
        console.log(error.error);
        setApprovedProducts([]);
      });
  };

  const getProducts = async () => {
    await adminService.getProducts()
      .then(result => {
        if (result.success && result.products) {
          setProducts(result.products);
        }
      })
      .catch((error) => {
        console.log(error.error);
      });
  };

  const checkPurchaseCodeInfo = async () => {
    await adminService.checkPurchaseCodeInfo(purchaseCodeValue)
      .then(result => {
        if (result.success && result.data && result.data.value) {
          if (!!result.data.uses) {
            setFieldValue('purchaseCodeError', { severity: 'success', message: 'Valid purchase code' });
          } else {
            setFieldValue('purchaseCodeError', { severity: 'error', message: 'Your code is expired' });
          }
        } else {
          setFieldValue('purchaseCodeError', { severity: 'error', message: 'Your code is invalid' });
        }
      }).catch(() => setFieldValue('purchaseCodeError', { severity: 'error', message: 'Your code is invalid' }));
  };

  useDebounce(async () => {
    if (!!purchaseCodeValue && purchaseCodeValue.match(new RegExp(/^(ANT|PFF|ATE)*/))) {
      checkPurchaseCodeInfo();
    }
  }, 300, [purchaseCodeValue]);

  useEffect(() => {
    if (testType && CERTIFICATE_PRODUCTS.includes(testType.sku) || isDocUsSource) {
      setFieldValue('numberOfPeople', testType.quantity);
      if (!approvedProducts.length) getApprovedProducts();
    }
  }, [productValue]);

  useEffect(() => {
    if (isDocUsSource)
      getProducts();
  }, []);

  useEffect(() => {
    let newFilteredItems = items.filter(({ type }) => type !== 'Virtual');
    if (isDocUsSource && products.length) {
      const certificateProduct = newFilteredItems.find(({ sku }) => sku === ANTIGEN_CONSULTATION);
      const day3UsMainProduct = products.find(({ sku }) => sku === DAY_2_ANTIGEN_US);
      const fitToFlyMainProduct = products.find(({ sku }) => sku === FIT_TO_FLY_ANTIGEN);

      if (certificateProduct) {
        const day3UsProduct = {
          ...certificateProduct,
          title: day3UsMainProduct.title,
          sku: day3UsMainProduct.sku,
          type: day3UsMainProduct.type,
          id: day3UsMainProduct.id,
          tags: day3UsMainProduct.tags,
        };
        const fitToFlyProduct = {
          ...certificateProduct,
          title: fitToFlyMainProduct.title,
          sku: fitToFlyMainProduct.sku,
          type: fitToFlyMainProduct.type,
          id: fitToFlyMainProduct.id,
          tags: fitToFlyMainProduct.tags,
        };
        newFilteredItems = [
          fitToFlyProduct,
          day3UsProduct,
          ...filteredItems.filter(({ sku }) => !CERTIFICATE_PRODUCTS.includes(sku)),
        ];
      }
    }
    setFilteredItems(newFilteredItems);
  }, [items, products]);

  return (isPharmacy ? (
    <>
      <h4 className='no-margin'>
        Purchase Code
      </h4>
      <div className='row space-between'>
        <div>
          <Field
            name={purchaseCode.name}
          >
            {({ field, meta }) => (
              <Input
                error={!!meta.error}
                touched={meta.touched}
                helperText={(meta.error && meta.touched) && meta.error}
                {...purchaseCode}
                {...field}
              />
            )}
          </Field>
        </div>
      </div>
      {!!purchaseCodeError && (
        <div className='row space-between'>
          <div style={{ minWidth: '320px' }}>
            <Alert severity={purchaseCodeError.severity} variant='outlined'>
              {purchaseCodeError.message}
            </Alert>
          </div>
        </div>
      )}
    </>
  ) : (
    <div className='step-box step-0-box'>
      {(filteredItems.length && (isDocUsSource ? products.length : true)) && (
        <div className='row'>
          <div >
            <Field
              name={productField.name}
            >
              {({ field, form, meta }) => (
                <FormControl component='fieldset'>
                  <FormLabel required={productField.required} component='legend'>
                    {productField.label}
                  </FormLabel>
                  <RadioGroup
                    error={!!meta.error}
                    touched={meta.touched}
                    helperText={(meta.error && meta.touched) && meta.error}
                    aria-label={productField.name}
                    name={productField.name}
                    value={field.value}
                    style={{ padding: '8px 0px'}}
                    {...productField}
                    {...field}
                    onChange={(({ target: { value } }) => {
                      const intValue = parseInt(value);
                      const productObj = filteredItems.find(({ id }) => id === intValue);
                      form.setFieldValue(field.name, intValue);
                      form.setFieldValue('testType', productObj);
                      form.setFieldValue(numberOfPeople.name, productObj.quantity);
                    })}
                  >
                    {filteredItems.map(({ id, sku, title, quantity }) => (
                      <FormControlLabel
                        value={id}
                        key={id + title}
                        control={<Radio />}
                        disabled={quantity < 1 || isEdit}
                        label={
                          <>
                            {title}<br/>
                            <span className="additional-option-text">
                              {quantity < 1
                                ? 'you have booked all appointment for this product'
                                : !!ADDITIONAL_PRODUCT_TEXT[sku]
                                  ? ADDITIONAL_PRODUCT_TEXT[sku]
                                  : ''}
                            </span>
                          </>
                        }
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              )}
            </Field>
          </div>
        </div>
      )}
      {!!approvedProducts.length && (
        <>
          <h4 className='no-margin'>
            Which test kit are you going to use?
          </h4>
          <div className='row'>
            <Field name={selectedKit.name}>
              {({ field, meta, form }) => (
                <Autocomplete
                  {...field}
                  options={[{ name: 'NHS Test Kit (are not allowed)' }, ...approvedProducts]}
                  getOptionLabel={({ name }) => name}
                  getOptionDisabled={({ name }) => name === 'NHS Test Kit (are not allowed)'}
                  style={{ width: '100%', minWidth: 280, maxWidth: 450 }}
                  onChange={(e, newValue) => {
                    form.setFieldValue(selectedKit.name, newValue);
                  }}
                  renderInput={(params) => <Input
                    {...params}
                    {...selectedKit}
                    error={!!meta.error}
                    touched={meta.touched}
                    helperText={(meta.error && meta.touched) && meta.error}
                  />}
                />
              )}
            </Field>
          </div>
        </>
      )}
      <h4 className='no-margin'>
        How many people will take the test?<br /><br />
        You can book up to 4 people on this appointment.<br /><br />
        {!isBookingSkip && (
          <span className="red-bold-text">Please note that all people have to be in one single location</span>
        )}
      </h4>
      <div className='row space-between'>
        <div>
          <Field
            name={numberOfPeople.name}
            validate={(value) => isEdit ? ((parseFloat(value) < bookingUsersQuantity) ? 'You can\'t reduce quantity of booking users' : undefined) : undefined}
          >
            {({ field, meta }) => (
              <Input
                className='item-input'
                error={!!meta.error}
                touched={meta.touched}
                disabled={(!!testType && !!testType.sku) ? CERTIFICATE_PRODUCTS.includes(testType.sku) || isDocUsSource : false}
                helperText={(meta.error && meta.touched) && meta.error}
                inputProps={{ min: 1 }}
                {...numberOfPeople}
                {...field}
              />
            )}
          </Field>
          <h4 className='no-margin'>
            Remember that children under 16 years can only attend the online video <br /> appointment with a legal guardian (18+) present during the video consultation.
          </h4>
        </div>
      </div>
      {(!isPharmacy && !isEdit) && (
        <>
          <div className='row'>
            <Field name={isAppointmentAddressSame.name}>
              {({ field, form, meta }) => (
                <FormControl
                  component='fieldset'
                >
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...isAppointmentAddressSame}
                          {...field}
                          error={!!meta.error}
                          touched={meta.touched}
                          helperText={(meta.error && meta.touched) && meta.error}
                          onChange={event => form.setFieldValue(isAppointmentAddressSame.name, event.target.checked)}
                          checked={field.value}
                        />
                      }
                      label={isAppointmentAddressSame.label}
                    />
                  </FormGroup>
                </FormControl>
              )}
            </Field>
          </div>
          {!isAppointmentAddressSameValue && (
            <>
              <h4 className='no-margin'>
                Address Information
              </h4>
              <h6 className="grey-text" style={{ margin: 0, fontSize: 12 }}>
                Please enter your address at the time of the appointment.
              </h6>
              <div className='row'>
                <div>
                  <Field name={'appointmentAddress.postalCode'} validate={(value) => (!(value || '').trim() && get(touched, 'appointmentAddress.postalCode', false)) ? 'Input postcode' : undefined}>
                    {({ field, meta }) => (
                      <Input
                        className='item-input'
                        error={!!meta.error}
                        touched={meta.touched}
                        helperText={(meta.error && meta.touched) && meta.error}
                        {...postalCode}
                        {...field}
                      />
                    )}
                  </Field>
                </div>
              </div>
              <div className='row'>
                <div>
                  <Field name={'appointmentAddress.streetAddress'} validate={(value) => (!(value || '').trim() && get(touched, 'appointmentAddress.streetAddress', false)) ? 'Input address' : undefined}>
                    {({ field, meta }) => (
                      <Input
                        className='item-input'
                        error={!!meta.error}
                        touched={meta.touched}
                        helperText={(meta.error && meta.touched) && meta.error}
                        {...streetAddress}
                        {...field}
                      />
                    )}
                  </Field>
                </div>
              </div>
              <div className='row'>
                <div>
                  <Field name={'appointmentAddress.extendedAddress'}>
                    {({ field, meta }) => (
                      <Input
                        className='item-input'
                        error={!!meta.error}
                        touched={meta.touched}
                        helperText={(meta.error && meta.touched) && meta.error}
                        {...extendedAddress}
                        {...field}
                      />
                    )}
                  </Field>
                </div>
              </div>
              <div className='row'>
                <div>
                  <Field name={'appointmentAddress.locality'} validate={(value) => (!(value || '').trim() && get(touched, 'appointmentAddress.locality', false)) ? 'Input city' : undefined}>
                    {({ field, meta }) => (
                      <Input
                        className='item-input'
                        error={!!meta.error}
                        touched={meta.touched}
                        helperText={(meta.error && meta.touched) && meta.error}
                        {...locality}
                        {...field}
                      />
                    )}
                  </Field>
                </div>
              </div>
              <div className='row'>
                <div>
                  <Field name={'appointmentAddress.county'} validate={(value) => (!(value || '').trim() && get(touched, 'appointmentAddress.county', false)) ? 'Input county' : undefined}>
                    {({ field, meta }) => (
                      <Input
                        className='item-input'
                        error={!!meta.error}
                        touched={meta.touched}
                        helperText={(meta.error && meta.touched) && meta.error}
                        {...county}
                        {...field}
                      />
                    )}
                  </Field>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  ));
};

export default Step0;
