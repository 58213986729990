import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { get } from 'lodash';
import moment from 'moment-timezone';
import { format } from 'date-fns';
import parsePhoneNumber from 'libphonenumber-js';
import cityTimezones from 'city-timezones';
import { ToastsStore } from 'react-toasts';
import BigWhiteContainer from '../Containers/BigWhiteContainer';
import BookingEngineForm from './BookingEngineForm';
import bookingFormModel from './bookingFormModel';
import useValidationSchema from './validationSchema';
import bookingService from '../../services/bookingService';
import getURLParams from '../../helpers/getURLParams';
import LinkButton from '../DocButton/LinkButton';
import adminService from '../../services/adminService';
import COUNTRIES from '../../helpers/countries';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import {
  FIT_TO_FLY_PCR,
  ANTIGEN_CONSULTATION,
  CERTIFICATE_PRODUCT,
  VACCINES,
  RECONSULT_DAY_2_ANTIGEN_US,
  DAY_2_ANTIGEN_US,
} from '../../helpers/productsWithAdditionalInfo';
import CountdownTimer from '../CountdownTimer';
import Summary from './Summary';
import useChat from '../../helpers/hooks/useChat';

const BookingEngine = ({ skipBooking = false }) => {
  const params = getURLParams(window.location.href);
  const short_token = params['short_token'];
  const [orderInfo, setOrderInfo] = useState();
  const [timerStart, setTimerStart] = useState();
  const [appointments, setAppointments] = useState([]);
  const [items, setItems] = useState([]);
  const [status, setStatus] = useState();
  const [isLoading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [createdAppointmentId, setCreatedAppointmentId] = useState();
  const [activePassenger, setActivePassenger] = useState(0);
  const { formInitialValues } = bookingFormModel;
  const defaultTimeZone = cityTimezones.findFromCityStateProvince('Westminster')[0];
  const usersPhoneNumber = get(orderInfo, 'shipping_address.telephone', '');
  const orderId = get(orderInfo, 'id', 0);
  const isBookingSkip = items.find(({ sku }) => (sku === 'FACE-2-FACE-HOTEL' || sku === 'SELF-SWABBING')) || skipBooking;
  const parsedPhoneNumber = parsePhoneNumber(usersPhoneNumber);
  const defaultCountryCode = COUNTRIES.find(({ country }) => country === 'United Kingdom');
  const currentValidationSchema = useValidationSchema(activeStep, isBookingSkip);
  const itemsWithoutVirtual = [...items].filter(({ type }) => type !== 'Virtual');
  const defaultTestType = itemsWithoutVirtual.find(({ quantity }) => quantity > 0) || null;
  const shippingFlag = get(orderInfo, 'shipping_flag', '');
  const failedShippingFlag = ['cancelled', 'failed'];
  const isFailedStatus = failedShippingFlag.includes(shippingFlag.toLocaleLowerCase());
  const totalAvailableQuantity = itemsWithoutVirtual.filter(({ type }) => type !== 'Virtual').reduce((sum, { quantity }) => quantity + sum, 0);
  const steps = [
    'Select Test',
    'Travel Details',
    ...(isBookingSkip ? [] : ['Booking Appointment']),
    'Passenger Details',
    'Summary',
    ...(isBookingSkip ? ['Confirmation'] : ['Booking Confirmation']),
  ];

  const passengerInitialValues = {
    fillWithBookingUser: '',
    firstName: '',
    lastName: '',
    email: '',
    countryCode: defaultCountryCode,
    phone: '',
    dateOfBirth: null,
    ethnicity: '',
    sex: '',
    nhs: '',
    passportNumber: '',
    passportNumberConfirmation: '',
    vaccineStatus: '',
    vaccineNumber: '',
    vaccineTypeName: '',
    vaccineType: '',
  };

  function handleBack() {
    (steps[activeStep] === 'Passenger Details' && activePassenger !== 0)
      ? setActivePassenger(activePassenger - 1)
      : setActiveStep(activeStep - 1);
  }
  function handleNext() {
    setActiveStep(activeStep + 1);
  }

  const getData = async () => {
    setLoading(true);
    if (short_token) {
      await adminService.getOrderInfo(short_token)
        .then(data => {
          if (data.success) {
            setOrderInfo(data.order);
          }
        })
        .catch(() => ToastsStore.error('Error fetching order information'));
      await adminService.getOrderProducts(short_token)
        .then(data => {
          if (data.success) {
            let preparedItems = [...data.order];
            const consultation = preparedItems.find(({ sku }) => sku === ANTIGEN_CONSULTATION);
            const certificates = preparedItems.find(({ sku }) => sku === CERTIFICATE_PRODUCT);
            preparedItems = preparedItems.filter(({ sku }) => sku !== CERTIFICATE_PRODUCT);
            if (!!consultation && !!consultation.id && !!certificates && !!certificates.id && consultation.quantity > 0) {
              consultation.quantity = certificates.quantity;
            }
            setItems(preparedItems);
          }
        })
        .catch(() => ToastsStore.error('Error fetching order information'));

      await bookingService.getAppointmentsByShortToken(short_token)
        .then(result => {
          if (result.success && result.appointments) {
            setAppointments(result.appointments);
          }
        }).catch(err => console.log(err));
    }
    setLoading(false);
  };

  useChat();
  useEffect(() => {
    getData();
  }, []);

  if (isLoading) {
    return (
      <BigWhiteContainer>
        <div className='row center'>
          <LoadingSpinner />
        </div>
      </BigWhiteContainer>
    );
  }

  return (
    <BigWhiteContainer>
      {(short_token && !!orderInfo) ? (
        <>
          {(!!items.length && !!defaultTestType && !isFailedStatus) ? (
            <>
              <Formik
                initialValues={{
                  ...formInitialValues,
                  numberOfPeople: defaultTestType.sku === ANTIGEN_CONSULTATION ? defaultTestType.quantity : 1,
                  product: defaultTestType.id || 0,
                  testType: defaultTestType,
                  ...(!!appointments.length ? {
                    bookingUsers: appointments[0].booking_users.map(({
                      first_name,
                      date_of_birth,
                      last_name,
                      phone,
                      ethnicity,
                      sex,
                      email,
                      metadata: {
                        passport_number,
                      },
                      vaccine_information,
                    }) => {
                      const parsedPhoneNumber = parsePhoneNumber(phone);
                      const vaccineType = get(vaccine_information, 'type', '');
                      const isntOtherVaccineType = VACCINES.includes(vaccineType);
                      return ({
                        ...passengerInitialValues,
                        firstName: first_name,
                        lastName: last_name,
                        dateOfBirth: new Date(date_of_birth),
                        passportNumber: passport_number,
                        phone: !!parsedPhoneNumber ? parsedPhoneNumber.nationalNumber : phone,
                        countryCode: !!parsedPhoneNumber ? COUNTRIES.find(({ code, label }) => (code === parsedPhoneNumber.country && label === `+${parsedPhoneNumber.countryCallingCode}`)) : defaultCountryCode,
                        ethnicity,
                        sex,
                        email,
                        vaccineStatus: get(vaccine_information, 'status', ''),
                        vaccineNumber: get(vaccine_information, 'number', ''),
                        vaccineType: isntOtherVaccineType ? vaccineType : 'Other',
                        vaccineTypeName: isntOtherVaccineType ? '' : vaccineType,
                      });
                    }),
                  } : {
                    passengers: [
                      {
                        ...passengerInitialValues,
                        firstName: get(orderInfo, 'billing_detail.first_name', ''),
                        lastName: get(orderInfo, 'billing_detail.last_name', ''),
                        email: get(orderInfo, 'billing_detail.email', ''),
                        phone: !!parsedPhoneNumber ? parsedPhoneNumber.nationalNumber : usersPhoneNumber,
                        countryCode: !!parsedPhoneNumber
                          ? COUNTRIES.find(({ code, label }) => (code === parsedPhoneNumber.country && label === `+${parsedPhoneNumber.countryCallingCode}`))
                          : defaultCountryCode,
                        dateOfBirth: new Date(get(orderInfo, 'billing_detail.date_of_birth', null)),
                      },
                    ],
                  }),
                  city: undefined,
                  timezone: undefined,
                }}
                validationSchema={currentValidationSchema}
                onSubmit={async (values, actions) => {
                  if (steps[activeStep] === 'Booking Appointment') {
                    const { selectedSlot } = values;
                    await bookingService.updateAppointmentStatus(
                      selectedSlot.id,
                      { status: 'LOCKED' },
                      'token',
                    ).then((response) => {
                      if (response.success) {
                        setTimerStart(new Date());
                      }
                    }).catch(() => console.log('error'));
                    actions.setTouched({});
                    actions.setSubmitting(false);
                    actions.setErrors({});
                    handleNext();
                  } else if (steps[activeStep] === 'Passenger Details') {
                    const {
                      numberOfPeople,
                      passengers,
                    } = values;
                    if (activePassenger === (numberOfPeople - 1)) {
                      actions.setSubmitting(false);
                      actions.setTouched({});
                      actions.setErrors({});
                      handleNext();
                    } else {
                      if (get(passengers, `[${activePassenger + 1}].firstName`, 'default') === 'default') {
                        const newPassengers = [...passengers];
                        newPassengers.push({ ...passengerInitialValues });
                        actions.setValues({
                          ...values,
                          passengers: newPassengers,
                        });
                      }
                      setActivePassenger(activePassenger + 1);
                      actions.setSubmitting(false);
                      actions.setTouched({});
                      actions.setErrors({});
                    }
                  } else if (steps[activeStep] === 'Summary') {
                    const {
                      source,
                      shipping_address: {
                        address_1,
                        address_2,
                        town,
                        postcode,
                        county,
                      },
                    } = orderInfo;
                    const {
                      selectedSlot,
                      travelDate,
                      travelTime,
                      passengers,
                      timezone: timezoneValue,
                      testType: { id, sku, type, bundle_id, tags = [] },
                      transportNumber,
                      transportType,
                      landingDate,
                      landingTime,
                      city,
                      tocAccept,
                      transit,
                      selectedKit,
                      appointmentAddress,
                      isAppointmentAddressSame,
                    } = values;
                    const isUs = source === 'dochq-us';
                    const product_id = parseInt(isUs ? items.find(({ sku }) => sku === ANTIGEN_CONSULTATION).id : id);
                    const travelDateInTz = moment(
                      new Date(
                        travelDate.getFullYear(),
                        travelDate.getMonth(),
                        travelDate.getDate(),
                        travelTime.getHours(),
                        travelTime.getMinutes(),
                        0,
                      )).tz(timezoneValue || defaultTimeZone.timezone, true).format();
                    const isBookedAfterFlight = tags.includes('after_flight');
                    const isAdditionalInfo = tags.includes('additional_info');
                    const isPCR = sku === FIT_TO_FLY_PCR;
                    const isUsProduct = sku === RECONSULT_DAY_2_ANTIGEN_US || sku === DAY_2_ANTIGEN_US;
                    const booking_users = Array.from(Array(passengers.length).keys()).map((item) => {
                      const {
                        nhs,
                        firstName,
                        lastName,
                        dateOfBirth,
                        passportNumber,
                        phone,
                        countryCode,
                        vaccineNumber,
                        vaccineStatus,
                        vaccineType,
                        vaccineTypeName,
                        ...rest
                      } = passengers[item];
                      return ({
                        ...(isAppointmentAddressSame ? {
                          street_address: address_1,
                          extended_address: address_2,
                          locality: town,
                          postal_code: postcode,
                          region: county,
                        } : {
                          street_address: appointmentAddress.streetAddress,
                          extended_address: appointmentAddress.extendedAddress,
                          locality: appointmentAddress.locality,
                          postal_code: appointmentAddress.postalCode,
                          region: appointmentAddress.county,
                        }),
                        first_name: firstName,
                        last_name: lastName,
                        tz_location: ((isBookedAfterFlight || isPCR) && !isUsProduct) ? defaultTimeZone.timezone : timezoneValue,
                        date_of_birth: moment.utc(format(dateOfBirth, 'dd/MM/yyyy'), 'DD/MM/YYYY').format(),
                        language: 'EN',
                        phone: `${countryCode.label}${phone.trim()}`,
                        country: 'GB',
                        toc_accept: tocAccept,
                        bundle_id: parseInt(bundle_id),
                        product_id,
                        ...(!!selectedKit ? { selected_kit: selectedKit } : {}),
                        ...(isAdditionalInfo ? {
                          vaccine_information: {
                            number: vaccineNumber,
                            status: vaccineStatus,
                            type: vaccineType === 'Other' ? vaccineTypeName : vaccineType,
                          }
                        } : {}),
                        metadata: {
                          source,
                          transit,
                          product_id,
                          short_token,
                          order_id: parseInt(orderId),
                          passport_number: passportNumber,
                          travel_date: travelDateInTz,
                          test_type: type,
                          ...(!!nhs ? { nhs: nhs } : {}),
                        },
                        ...rest,
                      });
                    });
                    const body = {
                      type: 'video_gp_dochq',
                      booking_users,
                      flight_details: {
                        transport_arrival_country: isBookedAfterFlight ? 'GB' : timezoneValue,
                        transport_arrival_date_time: moment(
                          new Date(
                            landingDate.getFullYear(),
                            landingDate.getMonth(),
                            landingDate.getDate(),
                            landingTime.getHours(),
                            landingTime.getMinutes(),
                            0,
                          )).tz(timezoneValue || defaultTimeZone.timezone, true).format(),
                        transport_departure_country: isBookedAfterFlight ? city.iso2 : 'GB',
                        transport_departure_date_time: travelDateInTz,
                        transport_number: transportNumber,
                        transport_type: transportType,
                      },
                    };
                    await bookingService
                      .paymentRequest(isBookingSkip ? '' : selectedSlot.id, body)
                      .then(result => {
                        if (result.success && result.confirmation) {
                          handleNext();
                          setTimerStart();
                          setCreatedAppointmentId(result.confirmation.id);
                        } else {
                          setStatus({
                            severity: 'error',
                            message: result.message,
                          });
                        }
                      })
                      .catch(({ error }) => {
                        setStatus({
                          severity: 'error',
                          message: error === 'Something went wrong, please try again.' ? 'Unfortunately, this time slot has been already taken. Please choose another one. Thank you!' : error,
                        });
                      });
                  } else {
                    actions.setTouched({});
                    actions.setSubmitting(false);
                    actions.setErrors({});
                    handleNext();
                  }
                }}
              >
                <>
                  <div className="fixed-box">
                    {(activeStep < 4 && activeStep > 0) && (
                      <Summary
                        activeStep={activeStep}
                        defaultTimezone={defaultTimeZone}
                      />
                    )}
                    {timerStart && (
                      <div className="countdown-timer">
                        <p>
													Your appointment is available for the next&nbsp;
                          <CountdownTimer
                            timerStart={timerStart.getTime()}
                            timerStop={new Date(new Date(timerStart).setMinutes(timerStart.getMinutes() + 30)).getTime()}
                            onTimeEnd={() => {
                              setTimerStart();
                              setActiveStep(2);
                              setActivePassenger(0);
                            }}
                          /> min<br />
													If you do not complete the booking you might need to select another appointment
                        </p>
                      </div>
                    )}
                  </div>
                  <BookingEngineForm
                    activePassenger={activePassenger}
                    activeStep={activeStep}
                    defaultTimezone={defaultTimeZone}
                    handleBack={handleBack}
                    status={status}
                    steps={steps}
                    items={items}
                    timer={timerStart}
                    source={orderInfo.source}
                    createdAppointmentId={createdAppointmentId}
                    isBookingSkip={isBookingSkip}
                    totalAvailableQuantity={totalAvailableQuantity}
                    dropTimer={() => setTimerStart()}
                  />
                </>
              </Formik>
            </>
          ) : (
            <>
              <div className="row center">
                <h3>
                  {isFailedStatus ? `This order has been ${shippingFlag}` : 'You don\'t have available appointments for that order'}
                </h3>
              </div>
              <div className="row center">
                <LinkButton
                  text='Buy Now!'
                  color='green'
                  linkSrc={`${process.env.REACT_APP_WEBSITE_LINK}/shop`}
                />
              </div>
            </>
          )}
        </>
      ) : (
        <>
          <div className="row center">
            <h3>You haven't bought any test kit yet</h3>
          </div>
          <div className="row center">
            <LinkButton
              text='Buy Now!'
              color='green'
              linkSrc={`${process.env.REACT_APP_WEBSITE_LINK}/shop`}
            />
          </div>
        </>
      )}
    </BigWhiteContainer>
  );
};

export default BookingEngine;
