import * as Yup from 'yup';
import hasDuplicates from '../../helpers/hasDuplicates';
import { DAY_2_ANTIGEN, DAY_2_ANTIGEN_US, FIT_TO_FLY_ANTIGEN, ANTIGEN_CONSULTATION_CERTIFICATE } from '../../helpers/productsWithAdditionalInfo';
import adminService from '../../services/adminService';
import bookingFormModel from '../B2CComponents/bookingFormModel';

const {
  formField: {
    numberOfPeople,
    product,
    travelDate,
    travelTime,
    selectedSlot,
    city,
    tocAccept,
    purchaseCode,
  }
} = bookingFormModel;

const checkPurchaseCodeInfo = async (value) => {
  const err = await adminService.checkPurchaseCodeInfo(value)
    .then(result => {
      if (result.success && result.data && result.data.value) {
        if (result.data.uses) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }).catch(() => false);
  return err;
};

const useValidationSchema = (activeStep, isFlorida) => (
  [
    Yup.object().shape({
      [product.name]: Yup.string().required('Select test kit to book appointment'),
      [numberOfPeople.name]: Yup.number().required('Input number of people').min(1, 'Minimum 1 person for appointment').max(4, 'Maximum 4 people per appointment'),
      [purchaseCode.name]: Yup.array()
        .of(
          Yup.object().shape({
            code: Yup.string().required('Input your code')
              .test(
                {
                  name: 'checkCodeF2F',
                  exclusive: false,
                  params: { },
                  message: `Invalid code. This code is not a Fit to Fly code. Please enter a code starting with ${isFlorida ? 'FTF2F' : 'F2FAW'}.`,
                  test: function (value, ctx) {
                    // eslint-disable-next-line
                    const [patent1, patent2] = ctx.from;
                    const regex =  isFlorida ? new RegExp(/^(FTF2F)[A-Z, 0-9]*/) : new RegExp(/^(F2FAW)[A-Z, 0-9]*/);
                    return (!!value && patent2.value.product === FIT_TO_FLY_ANTIGEN) ? regex.test(value) : true;
                  },
                },
              )
              .test(
                {
                  name: 'checkCodeD2T',
                  exclusive: false,
                  params: { },
                  message: `Invalid code. This code is not a Day 2 Antigen code. Please enter a code starting with ${isFlorida ? 'FTD2' : 'D2AW'}.`,
                  test: function (value, ctx) {
                    // eslint-disable-next-line
                    const [patent1, patent2] = ctx.from;
                    const regex =  isFlorida ? new RegExp(/^(FTD2)[A-Z, 0-9]*/) : new RegExp(/^(D2AW)[A-Z, 0-9]*/);
                    return (!!value && patent2.value.product === DAY_2_ANTIGEN) ? regex.test(value) : true;
                  },
                },
              )
              .test(
                {
                  name: 'checkCodeUS2',
                  exclusive: false,
                  params: { },
                  message: `Invalid code. This code is not a Day 3 Antigen code. Please enter a code starting with ${isFlorida ? 'FTUS3' : 'US3AW'}.`,
                  test: function (value, ctx) {
                    // eslint-disable-next-line
                    const [patent1, patent2] = ctx.from;
                    const regex =  isFlorida ? new RegExp(/^(FTUS3)[A-Z, 0-9]*/) : new RegExp(/^(US3AW)[A-Z, 0-9]*/);
                    return (!!value && patent2.value.product === DAY_2_ANTIGEN_US) ? regex.test(value) : true;
                  },
                },
              )
              .test(
                {
                  name: 'checkCodeFT',
                  exclusive: false,
                  params: { },
                  message: `Invalid code. This code is not a Antigen Consultation code. Please enter a code starting with ${isFlorida ? 'FT1A, FT2A, FT3A or FT4A' : 'US3AW'}.`,
                  test: function (value, ctx) {
                    // eslint-disable-next-line
                    const [patent1, patent2] = ctx.from;
                    const regex =  isFlorida ? new RegExp(/^(FT)[A-Z, 0-9]A[A-Z, 0-9]*/) : new RegExp(/^(US3AW)[A-Z, 0-9]*/);
                    return (!!value && patent2.value.product === ANTIGEN_CONSULTATION_CERTIFICATE) ? regex.test(value) : true;
                  },
                },
              )
              .test(
                {
                  name: 'codesAreSame',
                  exclusive: false,
                  params: { },
                  message: 'Codes can\'t be same for different people',
                  test: function (value, ctx) {
                    // eslint-disable-next-line
                    const [patent1, patent2] = ctx.from;
                    return patent2.value.purchaseCode.length > 1 ? hasDuplicates((patent2.value.purchaseCode).map(({ code }) => code)) : true;
                  },
                },
              )
              .test('checkCodeBE', 'Your code is invalid',
                function checkCodeBE(value) {
                  return value ? checkPurchaseCodeInfo(value) : true;
                }),
          })).min(1, 'Minimum 1 person'),
    }),
    Yup.object().shape({
      [city.name]: Yup.object().typeError('Select city').shape({
        timezone: Yup.string(),
      }).required('Select city'),
      [travelDate.name]: Yup.date(),
      [travelTime.name]: Yup.date(),
    }),
    Yup.object().shape({
      [selectedSlot.name]: Yup.object().typeError('You should select appointment time').shape({
        id: Yup.string().required(),
        end_time: Yup.string().required(),
        start_time: Yup.string().required(),
      }).required('You should select appointment time'),
    }),
    Yup.object().shape(),
    Yup.object().shape({
      [tocAccept.name]: Yup.boolean().test('accept', 'You must accept this acknowledge',
        function accept(value) {
          return value;
        }),
    }),
    Yup.object().shape(),
  ][activeStep]
);

export default useValidationSchema;
