const addParticipant = async ({ roomId, participantName, client }) => {
  try {
    const newChannel = await client.createConversation({
      uniqueName: roomId,
      friendlyName: roomId,
    });
    await newChannel.add(participantName);
  } catch (err) {
    console.log(err);
  }
};

export default addParticipant;
