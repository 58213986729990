import axios from 'axios';

const baseApiUrl = process.env.REACT_APP_API_URL;
const baseWSUrl = process.env.REACT_APP_CHAT_WEB_SOCKET_WS_URL;

const chatService = {
  getToken(data) {
    return new Promise((resolve, reject) => {
      axios.post('/chat/token', data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(result => resolve({ token: result.data.token }))
        .catch(err => {
          if (err && err.response && err.response.data && err.response.data.message) {
            reject({ success: false, error: err.response.data.message, });
          } else {
            reject({ success: false, error: 'Something went wrong, please try again.' });
          }
        });
    });
  },
  getRooms(token, entity, entityValue, type, status) {
    return new Promise((resolve, reject) => {
      if (typeof token !== 'undefined') {
        axios({
          method: 'get',
          url: `${baseApiUrl}/v1/chat/rooms/${entity}/${entityValue}?type=${type}&status=${status}`,
          headers: { Authorization: `Bearer ${token}` },
        })
          .then(response => {
            if ((response.status === 200) && response.data) {
              resolve({
                success: true,
                chats: response.data.Rooms,
              });
            } else if ((response.status === 200 || response.status === 404) && response.data === null) {
              resolve({
                success: true,
                rooms: [],
              });
            } else {
              resolve({
                success: false,
                error: 'Unable to retrieve rooms.',
              });
            }
          })
          .catch(err => {
            console.log(err);
            if (err && err.response && err.response.data && err.response.data.message) {
              reject({ success: false, error: err.response.data.message, });
            } else reject({ success: false });
          });
      } else {
        resolve({ success: false, authenticated: false });
      }
    });
  },
  updateRoom({ token, id, data }) {
    return new Promise((resolve, reject) => {
		  if (typeof token !== 'undefined') {
        axios.patch(`${baseApiUrl}/v1/chat/room/${id}`, data, {
			  headers: {
            Authorization: `Bearer ${token}`,
			  },
        })
			  .then(response => {
            if ((response.status === 200) && response.data) {
				  resolve({
                success: true,
                data: response.data,
				  });
            } else {
				  reject({
                success: false,
                error: response.data.message,
				  });
            }
			  })
			  .catch(errResp => {
            if (errResp && errResp.response && errResp.response.data && errResp.response.data.message) {
				  reject({ success: false, error: errResp.response.data.message, });
            } else {
				  reject({ success: false, error: 'Something went wrong, please try again.'});
            }
			  });
		  } else {
        // return unauthorized
        resolve({ success: false, authenticated: false });
		  }
    });
  },
  createRoom(token, data) {
    return new Promise((resolve, reject) => {
      if (typeof token !== 'undefined') {
        axios.post(`${baseApiUrl}/v1/chat/room`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then(response => {
            if (response.status === 200 && response.data) {
              resolve({
                success: true,
                roomId: response.data.id,
              });
            } else {
              resolve({
                success: false,
                error: response.data.message || 'Something went wrong',
              });
            }
          })
          .catch(err => {
            if (err && err.response && err.response.data && err.response.data.message) {
              reject({ success: false, error: err.response.data.message, });
            } else {
              reject({ success: false, error: 'Something went wrong, please try again.' });
            }
          });
      } else {
        resolve({ success: false, authenticated: false });
      }
    });
  },
  joinRoom(roomId, userId) {
    return new WebSocket(`${baseWSUrl}/room/${roomId}?user_id=${userId}`);
  },
};

export default chatService;
