export const DISCOUNT_USER_NAMES = [
  'Super Admin',
  'Silva Quattrocchi',
  'Madhur Srivastava',
  'Janet Webber',
  'Graham Carter',
];

export const PRODUCT_USER_NAMES = [
  'Super Admin',
  'Silva Quattrocchi',
  'Madhur Srivastava',
];

export const FULFILLMENT_USER_NAMES = [
  'Super Admin',
  'Silva Quattrocchi',
  'Madhur Srivastava',
  'Graham Carter',
  'Ben Huffey',
];
