import React, { useState } from 'react';
import {
  AppBar,
  Tabs,
  Tab,
  Box,
} from '@material-ui/core';
import indexToProps from '../../helpers/indexToProps';
import useAppBarStyles from '../../helpers/hooks/useAppBarStyles';
import ClaimableChatsTable from '../../components/Tables/ClaimableChatsTable';
import LiveChats from '../../components/LiveChats/LiveChats.js';


const CustomerChatScreen = (props) => {
  const classes = useAppBarStyles();
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const goToLiveChats = () => setValue(1);

  return (
    <>
      <AppBar position="static">
        <Tabs value={value} onChange={handleChange}>
          <Tab
            label="Claimable Chats"
            {...indexToProps(0)}
            classes={{
              root: classes.tab,
              selected: classes.selected,
            }}
          />
          <Tab
            label="My Picked chats"
            {...indexToProps(1)}
            classes={{
              root: classes.tab,
              selected: classes.selected,
            }}
          />
        </Tabs>
      </AppBar>
      <Box className={classes.tabBox}>
        {value === 0 && (
          <ClaimableChatsTable goToLiveChats={goToLiveChats} {...props} />
        )}
        {value === 1 && (
          <LiveChats type="external" {...props} />
        )}
      </Box>
    </>
  );
};

export default CustomerChatScreen;

