const compareFunc = (i, j, newSortOrder, sortField) => {
  if (i[sortField] < j[sortField]) {
    return newSortOrder === 'asc' ? -1 : 1;
  } else {
    if (i[sortField] > j[sortField]) {
      return newSortOrder === 'asc' ? 1 : -1;
    } else {
      return 0;
    }
  }
};

export default compareFunc;
