import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import {
  AppBar,
  Tabs,
  Tab,
  Box,
} from '@material-ui/core';
import indexToProps from '../../helpers/indexToProps';
import useAppBarStyles from '../../helpers/hooks/useAppBarStyles';

const DropboxAppBar = ({ value, children }) => {
  const classes = useAppBarStyles();

  return (
    <>
      <AppBar position="static">
        <Tabs value={value} aria-label="simple tabs example">
          <Tab
            label="Pickups Management"
            {...indexToProps(0)}
            classes={{
              root: classes.tab,
              selected: classes.selected,
            }}
            component={Link}
            to="/super_admin/pickups-list"
          />
          <Tab
            label="Dropbox List"
            {...indexToProps(1)}

            classes={{
              root: classes.tab,
              selected: classes.selected,
            }}
            component={Link}
            to="/super_admin/dropbox-list"
          />
        </Tabs>
      </AppBar>
      <Box className={classes.tabBox}>
        {children}
      </Box>
    </>
  );
};

export default memo(DropboxAppBar);
