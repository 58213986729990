import React, { memo } from 'react';
import SAHomepageCards from '../../components/SAComponents/SAHomepageCards';
import '../../assets/css/PatientDashboard.scss';

const SADashboard = (props) => (
  <React.Fragment>
    <SAHomepageCards {...props} />
  </React.Fragment>
);

export default memo(SADashboard);
