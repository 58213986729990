import {
  makeStyles,
} from '@material-ui/core';

const useAppBarStyles = makeStyles(() => ({
  tab: {
    fontSize: 14,
    fontWeight: 'bold',
    textTransform: 'none',
  },
  selected: {
    color: 'white',
  },
  tabBox: {
    background: 'white',
    boxShadow: '0px 12px 20px rgba(0, 0, 0, 0.1)',
    borderRightBottomRadius: 10,
    borderLeftBottomRadius: 10,
  },
}));

export default useAppBarStyles;
