export const APPOINTMENT_STATUSES = {
  onHold: 'onHold',
  inProgress: 'inProgress',
  patientAttended: 'patientAttended',
  practitionerAttended: 'practitionerAttended',
  patientLeft: 'patientLeft'
};

export const ONLINE_CHECK_STATUSES = {
  practitionerAttended: 'PRACTITIONER_ATTENDED',
  practitionerLeft: 'PRACTITIONER_LEFT',
  patientAttended: 'PATIENT_ATTENDED',
  patientLeft: 'PATIENT_LEFT',
};

export const HUMAN_STATUSES = {
  onHold: 'On Hold',
  inProgress: 'Ongoing',
  waiting: 'Not Started',
  patientAttended: 'Patient Waiting',
  practitionerAttended: 'Doctor Waiting',
  practitionerLeft: 'Doctor Left',
  patientLeft: 'Patient Left',
};
