import React, { memo, useState } from 'react';
import {
  AppBar,
  Tabs,
  Tab,
  Box,
} from '@material-ui/core';
import LiveDoctorsManagement from './LiveDoctorsManagement';
import DoctorsManagement from './DoctorsManagement';
import indexToProps from '../../helpers/indexToProps';
import useAppBarStyles from '../../helpers/hooks/useAppBarStyles';

const NewDoctorsManagement = (props) => {
  const classes = useAppBarStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <AppBar position="static">
        <Tabs value={value} onChange={handleChange}>
          <Tab
            label="Live"
            {...indexToProps(0)}
            classes={{
              root: classes.tab,
              selected: classes.selected,
            }}
          />
          <Tab
            label="Planning"
            {...indexToProps(1)}
            classes={{
              root: classes.tab,
              selected: classes.selected,
            }}
          />
        </Tabs>
      </AppBar>
      <Box className={classes.tabBox}>
        {value === 0 && (
          <LiveDoctorsManagement {...props} />
        )}
        {value === 1 && (
          <DoctorsManagement {...props} />
        )}
      </Box>
    </>
  );
};

export default memo(NewDoctorsManagement);
