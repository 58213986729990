import React from 'react';
import clsx from 'clsx';
import { get } from 'lodash';
import moment from 'moment';
import { Box, Button, ButtonGroup, makeStyles, TableSortLabel } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DocButton from '../DocButton/DocButton';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import DocModal from '../DocModal/DocModal';
import CertificatesAaron from '../Certificates/CertificatesAaron';
import './Tables.scss';


const useStyles =  makeStyles(() => ({
  btn: {
    fontSize: 14,
    border: '1px solid #EFEFF0',
    textTransform: 'none',
  },
  activeBtn: {
    fontWeight: 500,
    color: 'white',
    background: '#00BDAF',
  },
  tableText: {
    fontSize: 16,
  },
  smallCol: {
    width: '15%',
    maxWidth: '15%',
    fontSize: 16,
  },
  medCol: { width: '25%', maxWidth: '25%' },
  bntStyles: {
    marginLeft: '10px',
    marginTop: '0px',
    marginRight: '10px',
    boxSizing: 'border-box',
    maxWidth: '40%',
  },
  mainContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const TABLE_HEADERS = [
  {
    titleName: 'Email',
    propertyName: 'email',
    isSortable: true,
  },
  {
    titleName: 'Type',
    propertyName: 'type',
    isSortable: true,
  },
  {
    titleName: 'Test Date',
    propertyName: 'created_at',
    isSortable: true,
  },
  {
    titleName: 'Sent At',
    propertyName: 'sent_at',
    isSortable: true,
  },
  {
    titleName: 'Opened At',
    propertyName: 'opened_at',
    isSortable: true,
  },
  {
    titleName: 'Actions',
    propertyName: 'actions',
    isSortable: false,
  },
];

const CertificatesTable = ({
  loading,
  filter,
  setFilter,
  sort,
  sortField,
  sortOrder,
  certificate: currentCertificate,
  editCertificate,
  approvedTestKits,
  handleCloseModal,
  resendCertificate,
  certificates = [],
}) => {
  const classes = useStyles();
  const today = moment();
  const beforeYesterday = moment().subtract(2, 'day');
  const yesterday = moment().subtract(1, 'day');
  const patient = {
    id: get(currentCertificate, 'recipient_id'),
    metadata: {
      ...get(currentCertificate, 'meta_data', {}),
    },
  };
  const appointmentId = get(currentCertificate, 'provider_id');

  return (
    <>
      <DocModal
        isVisible={!!currentCertificate}
        onClose={handleCloseModal}
        content={
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <CertificatesAaron
              patient_data={patient}
              appointmentId={appointmentId}
              approvedTestKits={approvedTestKits}
              submitCallback={handleCloseModal}
              cancelBtn={
                <DocButton
                  text="Cancel"
                  color="pink"
                  onClick={handleCloseModal}
                />
              }
            />
          </div>
        }
      />
      <div className='doc-container tables' style={{ height: '100%', justifyContent: 'unset' }}>
        <div className={classes.mainContainer}>
          <h2>Certificates Lists</h2>
          <ButtonGroup>
            <Button
              className={clsx(
                classes.btn,
                'mobile-btn',
                {[classes.activeBtn]: beforeYesterday.isSame(filter, 'date')},
              )}
              onClick={() => setFilter(beforeYesterday)}
            >
              The Day Before Yesterday
            </Button>
            <Button
              className={clsx(
                classes.btn,
                'mobile-btn',
                {[classes.activeBtn]: yesterday.isSame(filter, 'date')},
              )}
              onClick={() => setFilter(yesterday)}
            >
              Yesterday
            </Button>
            <Button
              className={clsx(
                classes.btn,
                'mobile-btn',
                {[classes.activeBtn]: today.isSame(filter, 'date')},
              )}
              onClick={() => setFilter(today)}
            >
              Today
            </Button>
          </ButtonGroup>
        </div>
        <TableContainer
          style={{
            marginBottom: '40px',
          }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {TABLE_HEADERS.map(({ titleName, propertyName, isSortable }) => (
                  <TableCell key={titleName} align="center" className={isSortable ? classes.tableTextHeader : classes.tableText}>
                    {isSortable ? (
                      <TableSortLabel
                        active={sortField === propertyName}
                        direction={sortOrder}
                        onClick={() => sort(propertyName)}
                        style={{ paddingLeft: '25px' }}
                      >
                        {titleName}
                      </TableSortLabel>
                    ) : titleName}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell className={classes.tableText}>
                    <LoadingSpinner />
                  </TableCell>
                  <TableCell />
                  <TableCell />
                </TableRow>
              ) : certificates.length > 0 ? certificates.map((certificate) => {
                const createdAt = get(certificate, 'created_at');
                const openedAt = get(certificate, 'opened_at');
                const sentAt = get(certificate, 'sent_at');
                const createdAtDateFormatted = createdAt ? moment(createdAt * 1000).format('DD/MM/yyyy hh:mm A') : '';
                const openedAtDateFormatted = openedAt ? moment(openedAt * 1000).format('DD/MM/yyyy hh:mm A') : '';
                const sentAtDateFormatted = sentAt ? moment(sentAt * 1000).format('DD/MM/yyyy hh:mm A') : '';
                const certificate_appointment_id = get(certificate, 'provider_id');
                const certificate_booking_user_id = get(certificate, 'recipient_id');
                const isCanBeResent = certificate_appointment_id && certificate_booking_user_id;

                return (
                  <TableRow key={certificate.id}>
                    <TableCell
                      align='center'
                      className={classes.tableText}
                    >
                      {get(certificate, 'email', '')}
                    </TableCell>
                    <TableCell
                      align='center'
                      className={classes.tableText}
                    >
                      {get(certificate, 'type', '')}
                    </TableCell>
                    <TableCell
                      align='center'
                      className={classes.tableText}
                    >
                      {createdAtDateFormatted}
                    </TableCell>
                    <TableCell
                      align='center'
                      className={classes.tableText}
                    >
                      {sentAtDateFormatted}
                    </TableCell>
                    <TableCell
                      align='center'
                      className={classes.tableText}
                    >
                      {openedAtDateFormatted}
                    </TableCell>
                    <TableCell
                      align='center'
                      className={classes.tableText}
                    >
                      <Box display="flex" justifyContent="space-between">
                        <a
                          target='_blank'
                          rel='noopener noreferrer'
                          href={`${process.env.REACT_APP_CERTIFICATE_UI_URL}/certificate?certificate=${certificate.id}`}
                        >
                          <DocButton text="View" color="green"/>
                        </a>
                        {isCanBeResent && (
                          <>
                            <DocButton
                              color="green"
                              text="Resend"
                              onClick={() => resendCertificate(certificate)}
                            />
                            <DocButton
                              color="green"
                              text="Edit"
                              onClick={() => editCertificate(certificate)}
                            />
                          </>
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              }) : (
                <TableRow>
                  <TableCell className={classes.tableText}>
                    <p>No certificates to display</p>
                  </TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default CertificatesTable;
