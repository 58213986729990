export const DAY_2_ANTIGEN_CONSULT = 'RECONSULT-ANT-DAY-2';
export const DAY_2_ANTIGEN_US = 'DAY-2-US-ANT-001';
export const RECONSULT_DAY_2_ANTIGEN_US = 'RECONSULT-ANT-DAY-3';
export const DAY_2_ANTIGEN = 'DAY-2-UK-ANT-001'; // Day 2 Antigen Test Kit
const RECONSULT_PCR_DAY_2 = 'RECONSULT-PCR-DAY-2';

export const PRODUCTS_WITH_ADDITIONAL_INFO = [
  'SYN-UK-PCR-SNS-004', // Test to Release
  'SYN-UK-PCR-SNS-003', // Day 8 PCR Test
  'SYN-UK-PCR-SNS-002', // Day 2 PCR Test
  'GREEN-BUNDLE', // Green Bundle
  'AMBER-V-BUNDLE', // Amber List Vaccinated
  'SYN-UK-PCR-SNS-002,SYN-UK-PCR-SNS-003', // Amber Bundle
  'SPORT-UK-PCR-SNS-001', // Day 8 PCR Test Sports
  'SPORT-UK-PCR-SNS-002', // Day 2 PCR Test Sports
  'YN-UK-PCR-SNS-002', // Amber Bundle UEFA
  'SPORT-UK-PCR-SNS-003', //Test to Release PCR Sports
  'PCR-DAY-2-UPGRADE',
  DAY_2_ANTIGEN_CONSULT,
  DAY_2_ANTIGEN,
  RECONSULT_PCR_DAY_2,
];

export const VIDEO_CONSULTATION_PRODUCTS = [
  'RECONSULT-ANT', // Antigen
  'RECONSULT-PCR', // PCR
  'RECONSULT-ANT-DAY-2', // Antigen day 2
  RECONSULT_PCR_DAY_2,
];

export const VACCINES = [
  'Pfizer',
  'Astrazeneca',
  'Moderna',
  'Johnson & Johnson',
  'Sputnik',
];

export const ANTIGEN_CONSULTATION = 'CONSULT-ANT';
export const PCR_CONSULTATION = 'CONSULT-PCR';
export const CERTIFICATE_PRODUCT = 'COVID-TEST-CERTIFICATE';

export const MASK_SKU = 'AGIS-FACE-MASK';

export const CERTIFICATE_PRODUCTS = [
  ANTIGEN_CONSULTATION, // Antigen
  PCR_CONSULTATION, // PCR
];

export const FIT_TO_FLY_PCR = 'SYN-UK-PCR-SNS-001';
export const FIT_TO_FLY_ANTIGEN = 'FLX-UK-ANT-SNS-001';
export const PRE_DEPARTURE_ANTIGEN = 'FLX-UK-ANT-SNS-002';
export const ANTIGEN_CONSULTATION_CERTIFICATE = 'FLX-UK-ANT-SNS-001-NO-SHIP';
