import { useState } from 'react';
import compareFunc from '../compareFunc';

const useSort = (items, setItems, setDefault) => {
  const [sortOrder, setSortOrder] = useState('');
  const [sortField, setSortField] = useState('');

  const sort = (sortBy) => {
    const newSortOrder = sortBy !== sortField ? 'asc' : sortOrder === 'asc' ? 'desc' : sortOrder === 'desc' ? '' : 'asc';
    if (!!newSortOrder) {
      setItems([...items].sort((i, j) => compareFunc(i, j, newSortOrder, sortBy)));
    } else {
      setDefault();
    }
    setSortField(!!newSortOrder ? sortBy : '');
    setSortOrder(newSortOrder);
  };

  return { sort, sortOrder, sortField };
};

export default useSort;
