import { Grid, Box } from '@material-ui/core';
import React, { useState } from 'react';
import { ToastsStore } from 'react-toasts';
import DocButton from '../../components/DocButton/DocButton';
import TwilioChatRoom from '../../components/TwilioChatRoom/TwilioChatRoom';
import addParticipant from '../../helpers/addParticipant';
import useTwilioClient from '../../helpers/hooks/useTwilioClient';
import chatService from '../../services/chatService';

const TestAppClientChatScreen = () => {
  const userId = 5050;
  const userName = 'MobileApp Customer';
  const [chat, setChat] = useState({});
  const { client } = useTwilioClient(userName);

  const createRoom = async () => {
    await chatService.createRoom(
      'token',
      {
        type: 'external',
        user_role: 'customer',
        status: 'claimable',
        room_members: [{ user_id: userId }],
      },
    ).then(async (result) => {
      if (result.success) {
        const roomId = result.roomId.toString();
        await addParticipant({ roomId, client, participantName: 'practitioner' });
        setChat({ id: roomId });
      } else {
        ToastsStore.error(result.error);
      }
    })
      .catch((err) => ToastsStore.error(err.error));
  };

  return (
    <Box minHeight="80vh">
      {(!!chat && !!chat.id) ? (
        <Grid container justify='center' alignContent='center'>
          <Grid item xs={6}>
            <TwilioChatRoom
              userId={userId}
              client={client}
              chat={chat}
              userName={userName}
            />
          </Grid>
        </Grid>
      ) : (
        <div className="row center" style={{ height: '80vh' }}>
          <DocButton
            text="Create a chat"
            color="green"
            onClick={createRoom}
          />
        </div>
      )}
    </Box>
  );
};

export default TestAppClientChatScreen;
