import React, { useEffect, useState, memo, useContext } from 'react';
import { get } from 'lodash';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { ToastsStore } from 'react-toasts';
import adminService from '../../services/adminService';
import { AuthContext } from '../../context/AuthContext';
import DiscountTable from '../../components/SAComponents/Tables/DiscountTable';
import DiscountAppBar from '../../components/SAComponents/DiscountAppBar';
import { DISCOUNT_USER_NAMES } from '../../helpers/permissions';
import useFilter from '../../helpers/hooks/useFilter';
import useSort from '../../helpers/hooks/useBeSort';
import usePagination from '../../helpers/hooks/usePagination';

const SADiscountManagement = ({ token, role, isAuthenticated, user }) => {
  const defaultDiscountsData = {
    discounts: [],
    totalCount: 0,
  };
  const userName = `${get(user, 'first_name', '')} ${get(user, 'last_name', '')}`;
  const { logout } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [unusedDiscountsData, setUnusedDiscountsData] = useState(defaultDiscountsData);
  const [usedDiscountsData, setUsedDiscountsData] = useState(defaultDiscountsData);
  const [projects, setProjects] = useState([]);
  const history = useHistory();
  const projectOptions = projects.map((item) => ({ id: item, name: item }));

  const {
    page: unusedPage,
    rowsPerPage: unusedRowsPerPage,
    handleChangeRowsPerPage: unusedHandleChangeRowsPerPage,
    handleChangePage: unusedHandleChangePage,
  } = usePagination(0, 10);

  const {
    page: usedPage,
    rowsPerPage: usedRowsPerPage,
    handleChangeRowsPerPage: usedHandleChangeRowsPerPage,
    handleChangePage: usedHandleChangePage,
  } = usePagination(0, 10);

  const {
    sort: unusedSort,
    sortField: unusedSortField,
    sortOrder: unusedSortOrder,
  } = useSort();

  const {
    sort: usedSort,
    sortField: usedSortField,
    sortOrder: usedSortOrder,
  } = useSort();

  const {
    filters: unusedFilters,
    handleFilterUpdate: unusedHandleFilterUpdate,
  } = useFilter();

  const {
    filters: usedFilters,
    handleFilterUpdate: usedHandleFilterUpdate,
  } = useFilter();

  const getDiscounts = async (page, rowsPerPage, filters, sortField, sortOrder, state, setDiscountsDataData) => {
    await adminService
      .getDiscounts(token, page, rowsPerPage, filters, sortField, sortOrder, state)
      .then(data => {
        if (data.success) {
          setDiscountsDataData(data);
        } else {
          ToastsStore.error('Error fetching Discounts');
        }
      })
      .catch(() => ToastsStore.error('Error fetching Discounts'));
  };

  const getProjects = async () => {
    setLoading(true);
    await adminService
      .getDiscountProjects(token)
      .then(data => {
        if (data.success) {
          setProjects(data.projects);
        } else {
          ToastsStore.error('Error fetching Projects');
        }
      })
      .catch(() => ToastsStore.error('Error fetching Projects'));
    setLoading(false);

  };

  const logoutUser = () => {
    logout();
    history.push('/login');
  };
  if ((isAuthenticated !== true && role !== 'super_admin') || (!!user && !DISCOUNT_USER_NAMES.includes(userName))) {
    logoutUser();
  }

  useEffect(() => {
    getProjects();
  }, []);

  useEffect(() => {
    getDiscounts(unusedPage, unusedRowsPerPage, unusedFilters, unusedSortField, unusedSortOrder, 'unused', setUnusedDiscountsData);
  }, [unusedPage, unusedRowsPerPage, unusedFilters, unusedSortField, unusedSortOrder]);

  useEffect(() => {
    getDiscounts(usedPage, usedRowsPerPage, usedFilters, usedSortField, usedSortOrder, 'used', setUsedDiscountsData);
  }, [usedPage, usedRowsPerPage, usedFilters, usedSortField, usedSortOrder]);

  return (
    <DiscountAppBar value={1}>
      <Grid container justify="space-between">
        <Grid item xs={12}>
          <DiscountTable
            token={token}
            loading={loading}
            discounts={unusedDiscountsData.discounts}
            page={unusedPage}
            sort={unusedSort}
            filters={unusedFilters}
            sortField={unusedSortField}
            sortOrder={unusedSortOrder}
            rowsPerPage={unusedRowsPerPage}
            totalCount={unusedDiscountsData.totalCount}
            projectOptions={projectOptions}
            handleFilterUpdate={unusedHandleFilterUpdate}
            handleChangeRowsPerPage={unusedHandleChangeRowsPerPage}
            handleChangePage={unusedHandleChangePage}
          />
        </Grid>
        <Grid item xs={12}>
          <DiscountTable
            isUsed
            token={token}
            loading={loading}
            discounts={usedDiscountsData.discounts}
            page={usedPage}
            sort={usedSort}
            filters={usedFilters}
            sortField={usedSortField}
            sortOrder={usedSortOrder}
            rowsPerPage={usedRowsPerPage}
            totalCount={usedDiscountsData.totalCount}
            projectOptions={projectOptions}
            handleFilterUpdate={usedHandleFilterUpdate}
            handleChangeRowsPerPage={usedHandleChangeRowsPerPage}
            handleChangePage={usedHandleChangePage}
          />
        </Grid>
      </Grid>
    </DiscountAppBar>
  );
};

export default memo(SADiscountManagement);
