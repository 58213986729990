import React from 'react';
import { get } from 'lodash';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import LinkButton from '../../DocButton/LinkButton';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import '../../Tables/Tables.scss';

const styles = {
  tableText: {
    fontSize: 16,
  },
  bntStyles: {
    marginLeft: '10px',
    marginTop: '0px',
    marginRight: '10px',
    boxSizing: 'border-box',
    maxWidth: '40%',
  },
  mainContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  snackbar: {
    color: '#FFF',
  }
};

const PagesManagementTable = ({
  pages = [],
  loading,
}) => (
  <div className='doc-container tables' style={{ justifyContent: 'unset' }}>
    <div style={styles.mainContainer}>
      <h2>Pages Table</h2>
    </div>
    <TableContainer
      style={{
        marginBottom: '40px',
      }}
    >
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell align='left' style={styles.tableText}>Title</TableCell>
            <TableCell align='center' style={styles.tableText}>Source</TableCell>
            <TableCell align='right' style={styles.tableText}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <>
              <TableCell />
              <TableCell>
                <LoadingSpinner />
              </TableCell>
              <TableCell />
            </>
          ) : !!pages.length > 0 ?
            pages.map(page => (
              <TableRow key={page.id}>
                <TableCell
                  align='left'
                  style={{ ...styles.tableText }}
                >
                  {get(page, 'title', '')}
                </TableCell>
                <TableCell
                  align='center'
                  style={{ ...styles.tableText }}
                >
                  {get(page, 'source', '')}
                </TableCell>
                <TableCell align='right' style={{ ...styles.tableText }}>
                  <div style={{ display: 'inline-flex' }}>
                    <LinkButton
                      newTab
                      text='View'
                      color='green'
                      linkSrc={page.url}
                    />
                  </div>
                </TableCell>
              </TableRow>
            )) : (
              <TableRow>
                <TableCell style={styles.tableText}>
                  <p>No pages to display</p>
                </TableCell>
                <TableCell />
                <TableCell />
              </TableRow>
            )}
        </TableBody>
      </Table>
    </TableContainer>
  </div>
);


export default PagesManagementTable;
