import React from 'react';
import {
  Checkbox,
  FormControl,
  ListItemText,
  MenuItem,
  Select,
} from '@material-ui/core';
import { get } from 'lodash';
import { CheckCircleOutlined } from '@material-ui/icons';

const SelectFilter = ({
  entity,
  options,
  filters,
  fieldName,
  variant = 'outlined',
  handleFilterUpdate,
  dropOtherValues = false,
  /* Object should have id: and name: key, value is considered to be id: */
  objectOptions,
  ...restProps
}) => {
  const filterValue = get(filters, fieldName, []);

  return objectOptions
    ? (
      <FormControl variant={variant} {...restProps}>
        <Select
          labelId={`${entity}-filter`}
          id={`${entity}-filter`}
          multiple
          name={fieldName}
          value={filterValue}
          onChange={(event) => {
            handleFilterUpdate(fieldName, event.target.value, dropOtherValues);
          }}
          displayEmpty
          variant='standard'
          renderValue={(selected) => {
            return(
              selected.length
                ? options.map(item => {
                  return  selected.includes(item.id)
                    ? item.name
                    : '';
                }).filter(item => item).join(', ')
                : `All ${entity}`
            );
          }}
          style={{
            width: '100%',
            overflow: 'hidden',
            height: 40,
          }}
        >
          {options.map(({id, name}, index) => (
            <MenuItem key={index} value={id}>
              <ListItemText primary={name}/>
              <Checkbox
                color="primary"
                checked={filterValue.includes(id)}
                icon={<CheckCircleOutlined />}
                checkedIcon={<CheckCircleOutlined />}
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    ) : (
      <FormControl variant={variant} {...restProps}>
        <Select
          labelId={`${entity}-filter`}
          id={`${entity}-filter`}
          multiple
          name={fieldName}
          value={filterValue}
          onChange={(event) => handleFilterUpdate(fieldName, event.target.value, dropOtherValues)}
          displayEmpty
          variant='standard'
          renderValue={(selected) => (
            selected.length ? selected.join(', ') : `All ${entity}`
          )}
        >
          {options.map((item, index) => (
            <MenuItem key={index} value={item}>
              <ListItemText primary={item}/>
              <Checkbox
                color="primary"
                checked={filterValue.includes(item)}
                icon={<CheckCircleOutlined />}
                checkedIcon={<CheckCircleOutlined />}
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
};

export default SelectFilter;
