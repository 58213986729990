

import { useEffect, useRef, useState } from 'react';

const useTwilioChat = ({ client, roomId, previousRooms }) => {
  const [channel, setChannel] = useState();
  const [messages, setMessages] = useState([]);
  const [isParticipantOnline, setIsParticipantOnline] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const messagesRef = useRef();

  messagesRef.current = messages;

  const loadMessageHistory = (rooms) => {
    rooms.map(async ({ id }) => {
      try {
        const channelName = await client.getConversationByUniqueName(id.toString());
        if (channelName.channelState.status !== 'joined') {
          await channelName.join();
        }
        const chatMessages = await channelName.getMessages();
        if (chatMessages && chatMessages.items)
          setMessages(
            [
              ...messagesRef.current,
              ...chatMessages.items,
            ].sort((a, b) => new Date(a.dateCreated).getTime() - new Date(b.dateCreated).getTime()),
          );
      } catch (err) {
        console.log(err);
      }
    });
  };

  const joinChannel = async (newChannel) => {
    if (newChannel.channelState.status !== 'joined') {
      await newChannel.join();
    }
    setChannel(newChannel);
    const chatMessages = await newChannel.getMessages();
    if (chatMessages && chatMessages.items)
      setMessages(
        [...messagesRef.current,
          ...chatMessages.items,
        ].sort((a, b) => new Date(a.dateCreated).getTime() - new Date(b.dateCreated).getTime()),
      );
  };

  const handleMessageAdded = (message) => {
    setMessages([...messagesRef.current, message]);
  };

  const initiateChanel = async (room) => {
    let newChannel;
    try {
      newChannel = await client.getConversationByUniqueName(room);
      await joinChannel(newChannel);
    } catch (err) {
      console.log(err);
      try {
        newChannel = await client.createConversation({
          uniqueName: room,
          friendlyName: room,
        });
        await joinChannel(newChannel);
      } catch (err) {
        console.log(err);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setMessages([]);
    if (client && roomId) {
      loadMessageHistory(previousRooms);
      initiateChanel(roomId);
    }
  }, [roomId, client, previousRooms]);

  useEffect(() => {
    if (channel) {
      channel.on('messageAdded', handleMessageAdded);
      channel.on('participantJoined', () => setIsParticipantOnline(true));
      channel.on('participantLeft', () => setIsParticipantOnline(false));
    }
  }, [channel]);

  const sendMessage = (text) => {
    const message = String(text).trim();
    if (!!message && channel) {
      channel.sendMessage(message);
    }
  };

  return {
    isLoading,
    channel,
    messages,
    sendMessage,
    isParticipantOnline,
  };
};

export default useTwilioChat;
