import React from 'react';
import { Field, useFormikContext } from 'formik';
import DateFnsUtils from '@date-io/date-fns';
import cityTimezones from 'city-timezones';
import {
  Radio,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Grid,
  Tooltip,
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ThemeProvider } from '@material-ui/styles';
import bookingFormModel from './bookingFormModel';
import Input from '../FormComponents/Input';
import datePickerTheme from '../../helpers/datePickerTheme';
import { FIT_TO_FLY_PCR, RECONSULT_DAY_2_ANTIGEN_US, DAY_2_ANTIGEN_US } from '../../helpers/productsWithAdditionalInfo';
import './BookingEngine.scss';

const Step1 = ({ isPharmacy }) => {
  const {
    formField: {
      transportType,
      transportNumber,
      travelDate,
      travelTime,
      landingDate,
      landingTime,
      transit,
      city,
    }
  } = bookingFormModel;
  const {
    values: {
      testType: {
        sku,
        tags = [],
      },
      transportType: transportTypeValue,
    },
    touched,
  } = useFormikContext();
  const pickerTheme = datePickerTheme();
  const isPCR = sku === FIT_TO_FLY_PCR;
  const isUsProduct = sku === RECONSULT_DAY_2_ANTIGEN_US || sku === DAY_2_ANTIGEN_US;
  const isBookedAfterFlight = tags.includes('after_flight');
  const isBookedBeforeFlight = tags.includes('before_flight');
  const isAdditionalInfo = tags.includes('additional_info');
  const transportNumberLabel = `${transportTypeValue === 'Other' ? 'Transport' : transportTypeValue} Number`;

  return (
    <div className="step-1-box">
      {isPharmacy && (
        <h4 style={{ margin: 0, paddingTop: 10 }}>
					Book your appointment for one single trip.
        </h4>
      )}
      {!isPCR && (
        <div className='row'>
          <div className="autocomplete" style={{ maxWidth: '40%', minWidth: '250px', zIndex: 3 }}>
            <h4 style={{ margin: 0, padding: '20px 0 10px 0' }}>
              {isUsProduct ? 'What is your time zone at your destination?' : 'What is your city/country of origin?'}
            </h4>
            <Field name={city.name}>
              {({ field, meta, form }) => (
                <Autocomplete
                  {...field}
                  options={cityTimezones.cityMapping}
                  getOptionLabel={({city, country}) => city ? `${city}, ${country}` : ''}
                  style={{ width: 300 }}
                  onChange={(event, newValue) => {
                    form.setFieldValue(city.name, newValue);
                    if (!!newValue && !!newValue.timezone) {
                      form.setFieldValue('timezone', newValue.timezone);
                    }
                  }}
                  renderInput={(params) => <Input
                    {...params}
                    {...city}
                    error={!!meta.error}
                    touched={meta.touched}
                    helperText={(meta.error && meta.touched) && meta.error}
                  />}
                />
              )}
            </Field>
            <h5 style={{ margin: 0, paddingTop: 10 }}>
							If you do not find your {isBookedAfterFlight ? 'arrival' : 'departure'} city in the list, simply select the nearest city in the list.
            </h5>
          </div>
        </div>
      )}
      <div className='no-margin col' style={{ maxWidth: 400 }}>
        {(isBookedBeforeFlight || isAdditionalInfo) && (
          <ThemeProvider theme={pickerTheme}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container justify="space-between">
                <Grid item xs={12} md={3}>
                  <div className='appointment-calendar-container'>
                    <h4 style={{ margin: 0, padding: '20px 0 10px 0' }}>
                      Select Departure Date
                    </h4>
                    <Field name={travelDate.name}>
                      {({ field, form }) => (
                        <KeyboardDatePicker
                          {...field}
                          {...travelDate}
                          autoOk
                          disablePast={!isBookedAfterFlight}
                          inputVariant='filled'
                          format="dd/MM/yyyy"
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                          onChange={(value) => {
                            form.setFieldValue(field.name, value);
                            form.setFieldValue(landingDate.name, value);
                          }}
                        />
                      )}
                    </Field>
                  </div>
                </Grid>
                <Grid item xs={12} md={3}>
                  <div className='appointment-calendar-container'>
                    <h4 style={{ margin: 0, padding: '20px 0 10px 0' }}>
                      Select Departure Time
                    </h4>
                    <Field name={travelTime.name}>
                      {({ field, form }) => (
                        <KeyboardTimePicker
                          autoOk
                          {...field}
                          {...travelTime}
                          inputVariant='filled'
                          onChange={(value) => {
                            form.setFieldValue(field.name, value);
                            form.setFieldValue(landingTime.name, value);
                          }}
                          KeyboardButtonProps={{
                            'aria-label': 'change time',
                          }}
                        />
                      )}
                    </Field>
                  </div>
                </Grid>
              </Grid>
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        )}
        {isAdditionalInfo && (
          <h5 style={{ margin: 0, paddingTop: 10 }}>
            The date on which you last departed from or transited through a country or territory outside of the&nbsp;
            <Tooltip
              title="The Common Travel Area is an open borders area comprising the United Kingdom, the Republic of Ireland, the Isle of Man, and the Channel Islands. The British Overseas Territories are not included."
            >
              <span className='underline'>Common Travel Area</span>
            </Tooltip>.
          </h5>
        )}
      </div>
      {(isBookedAfterFlight || isAdditionalInfo) && (
        <>
          <div className='no-margin col' style={{ maxWidth: 400 }}>
            <ThemeProvider theme={pickerTheme}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justify="space-between">
                  <Grid item xs={12} md={3}>
                    <div className='appointment-calendar-container'>
                      <h4 style={{ margin: 0, padding: '20px 0 10px 0' }}>
												Select Arrival Date
                      </h4>
                      <Field name={landingDate.name}>
                        {({ field, form }) => (
                          <KeyboardDatePicker
                            {...field}
                            {...landingDate}
                            autoOk
                            disablePast={!isBookedAfterFlight}
                            inputVariant='filled'
                            format="dd/MM/yyyy"
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                            onChange={(value) => {
                              form.setFieldValue(field.name, value);
                              form.setFieldValue('appointmentDate', value);
                            }}
                          />
                        )}
                      </Field>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <div className='appointment-calendar-container'>
                      <h4 style={{ margin: 0, padding: '20px 0 10px 0' }}>
												Select Arrival Time
                      </h4>
                      <Field name={landingTime.name}>
                        {({ field, form }) => (
                          <KeyboardTimePicker
                            autoOk
                            {...field}
                            {...landingTime}
                            inputVariant='filled'
                            onChange={(value) => form.setFieldValue(field.name, value)}
                            KeyboardButtonProps={{
                              'aria-label': 'change time',
                            }}
                          />
                        )}
                      </Field>
                    </div>
                  </Grid>
                </Grid>
              </MuiPickersUtilsProvider>
            </ThemeProvider>
            {!isUsProduct && (
              <h5 style={{ margin: 0, paddingTop: 10 }}>
                Date on which you arrived or will arrive in UK from a country or territory outside the&nbsp;
                <Tooltip
                  title="The Common Travel Area is an open borders area comprising the United Kingdom, the Republic of Ireland, the Isle of Man, and the Channel Islands. The British Overseas Territories are not included."
                >
                  <span className='underline'>Common Travel Area</span>
                </Tooltip>.
              </h5>
            )}
          </div>
        </>
      )}
      {isAdditionalInfo && (
        <>
          <div className='row' style={{ flexWrap: 'wrap', width: '60%' }}>
            <div style={{ maxWidth: '40%', minWidth: '280px' }}>
              <h4 style={{ margin: 0, padding: '20px 0 10px 0' }}>
                {transit.label}
              </h4>
              <Field name={transit.name}>
                {({ field, meta }) => (
                  <Input
                    {...field}
                    {...transit}
                    label={transit.label}
                    error={!!meta.error}
                    touched={meta.touched}
                    helperText={(meta.error && meta.touched) && meta.error}
                  />
                )}
              </Field>
            </div>
          </div>
          <h5 style={{ margin: 0, paddingBottom: 10 }}>
						The country or territory you were, or will be travelling from when you arrived in the UK, and any country or territory you transited through as part of that journey.
          </h5>
          <div className='row' style={{ flexWrap: 'wrap', width: '60%' }}>
            <div style={{ maxWidth: '40%', minWidth: '280px' }}>
              <Field
                name={transportType.name}
              >
                {({ field, form, meta }) => (
                  <FormControl component='fieldset' style={{ width: '100%' }}>
                    <FormLabel required={transportType.required} component='legend'>
                      {transportType.label}
                    </FormLabel>
                    <RadioGroup
                      error={!!meta.error}
                      touched={meta.touched}
                      helperText={(meta.error && meta.touched) && meta.error}
                      aria-label={transportType.name}
                      name={transportType.name}
                      value={field.value}
                      {...transportType}
                      {...field}
                      onChange={(({ target: { value } }) => {
                        form.setFieldValue(field.name, value);
                      })}
                    >
                      <FormControlLabel value="Flight" control={<Radio />} label="Flight" />
                      <FormControlLabel value="Train" control={<Radio />} label="Train" />
                      <FormControlLabel value="Other" control={<Radio />} label="Other" />
                    </RadioGroup>
                  </FormControl>
                )}
              </Field>
            </div>
          </div>
          <div className='row' style={{ flexWrap: 'wrap', width: '60%' }}>
            <div style={{ maxWidth: '40%', minWidth: '280px' }}>
              <h4 style={{ margin: 0, padding: '20px 0 10px 0' }}>
                {transportNumberLabel}
              </h4>
              <Field name={transportNumber.name} validate={(value) => (!(value || '').trim() && touched.transportNumber) ? `Input ${transportNumberLabel}` : undefined}>
                {({ field, meta }) => (
                  <Input
                    {...field}
                    {...transportNumber}
                    label={transportNumberLabel}
                    error={!!meta.error}
                    touched={meta.touched}
                    helperText={(meta.error && meta.touched) && meta.error}
                  />
                )}
              </Field>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Step1;
