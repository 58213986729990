import React, { memo, useEffect, useState, useContext } from 'react';
import { ToastsStore } from 'react-toasts';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import OrderDetails from '../OrderDetails/OrderDetails';
import { AuthContext } from '../../context/AuthContext';
import adminService from '../../services/adminService';
import { useParams } from 'react-router';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 'unset',
  },
  data_grid: {
    marginTop: 30,
    height: 2800,
    width: '100%',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '40ch',
  },
  formControl: {
    width: '30ch',
  },
}));

const PickupManagement = () => {
  const classes = useStyles();
  const { id } = useParams();
  const { user, role, token } = useContext(AuthContext);
  const [collected, setCollected] = useState(false);
  const [collectionInfo, setCollectionInfo] = useState();
  const [orderDetail, setOrderDetail] = useState();
  const [searchBox, setSearchBox] = useState('');

  const updateCollected = async (value) => {
    await adminService.switchCollectionInfo(collectionInfo.id, token, {...collectionInfo, collected: value }).then(res => {
      if (res.success && res.data) {
        setCollected(value);
        ToastsStore.success('Order collection status has been changed successfully');
      } else {
        ToastsStore.error(res.error);
      }
    }).catch(res => {
      ToastsStore.error(res.error);
    });
  };

  const fetchCollectionInfoData = async () => {
    if (!!orderDetail && !!orderDetail.id) {
      await adminService.getCollectionInfo(orderDetail.id, token).then(res => {
        if (res.success && res.data) {
          setCollectionInfo(res.data);
        } else {
          ToastsStore.error(res.error);
        }
      }).catch(res => {
        ToastsStore.error(res.error);
      });
    }
  };

  const searchButtonClick = ({ orderId }) => {
    const newOrderId = searchBox || orderId;
    if (newOrderId === '') return;

    if (searchBox !== newOrderId)
      setSearchBox(newOrderId);
    setOrderDetail({ id: newOrderId });
  };

  useEffect(() => {
    if (!!orderDetail)
      fetchCollectionInfoData();
  }, [orderDetail]);

  useEffect(() => {
    if (!!orderDetail && !!collectionInfo)
      updateCollected(true);
  }, [collectionInfo]);

  useEffect(() => {
    if (id && id !== '0')
      searchButtonClick({ orderId: id });
  }, []);

  return (
    <Container className={classes.root}>
      <Grid container spacing={3} direction="column">
        <Grid container item xs={12} justify="space-between" alignItems="center">
          <Grid item xs={12} container alignItems="center" justify="center">
            <div className="row center">
              <TextField
                className={classes.textField}
                id="standard-basic"
                label="Search"
                value={searchBox}
                onChange={(e) => setSearchBox(e.target.value)}
              />
              <Button variant="contained" onClick={searchButtonClick}>Search</Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
      {(!!orderDetail && !!collectionInfo) && (
        <>
          <div className='row center'>
            <FormControl
              component='fieldset'
            >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={event => {
                        updateCollected(event.target.checked);
                      }}
                      checked={collected}
                    />
                  }
                  label="This order has been collected"
                />
              </FormGroup>
            </FormControl>
          </div>
          <OrderDetails
            shortInfo
            user={user}
            role={role}
            token={token}
            order={orderDetail}
            closeHandler={() => {
              setOrderDetail();
              setCollectionInfo();
              setCollected(false);
              setSearchBox('');
            }}
          />
        </>
      )}
    </Container>
  );
};

export default memo(PickupManagement);
