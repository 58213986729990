import React, { useContext, memo } from 'react';
import { get } from 'lodash';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { AuthContext } from '../../context/AuthContext';
import ProductsTable from '../../components/SAComponents/Tables/ProductsTable';
import ProductAppBar from '../../components/SAComponents/ProductAppBar';
import { PRODUCT_USER_NAMES } from '../../helpers/permissions';

const ProductManagement = ({ token, role, isAuthenticated, user }) => {
  const userName = `${get(user, 'first_name', '')} ${get(user, 'last_name', '')}`;
  const { logout } = useContext(AuthContext);
  const history = useHistory();
  const logoutUser = () => {
    logout();
    history.push('/login');
  };

  if ((isAuthenticated !== true && role !== 'super_admin') || (!!user && !PRODUCT_USER_NAMES.includes(userName))) {
    logoutUser();
  }

  return (
    <ProductAppBar value={0}>
      <Grid container justify="space-between">
        <Grid item xs={12}>
          <ProductsTable
            token={token}
          />
        </Grid>
      </Grid>
    </ProductAppBar>
  );
};

export default memo(ProductManagement);
