const ADDITIONAL_PRODUCT_TEXT = {
  'FLX-UK-ANT-SNS-001': 'Book an appointment within 24 hours of your travel.', // Fit to Travel [Antigen]
  'SYN-UK-PCR-SNS-001': 'Appointments available between 72 hours and 57 hours prior to your flight.', // Fit to Travel [PCR]
  'SYN-UK-PCR-SNS-002': 'Book an appointment within 2 days of your arrival in the UK.', // Day 2 PCR Test'
  'GREEN-BUNDLE': 'Book an appointment within 2 days of your arrival in the UK.', // Green Bundle
  'SYN-UK-PCR-SNS-003': 'Book an appointment on the 8th day after your arrival in the UK.', // Day 8 PCR Test
  'FLX-UK-ANT-SNS-002': 'Book an appointment with 48 hours of your travel.', // Pre Departure Antigen Test [to England]
  'SYN-UK-PCR-SNS-004': 'Book an appointment on the 5th day after your arrival in the UK.', // Test to Release
  'SPORT-UK-PCR-SNS-001': 'Book an appointment on the 8th day after your arrival in the UK.', // Day 8 PCR Test Sports
  'SPORT-UK-PCR-SNS-002': 'Book an appointment within 2 days of your arrival in the UK.', // Day 2 PCR Test Sports
  'SPORT-UK-PCR-SNS-003': 'Book an appointment on the 5th day after your arrival in the UK.', // Test to Release PCR Sports
};

export default ADDITIONAL_PRODUCT_TEXT;
