import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';

const useFilter = (defaultValue = {}) => {
  const [filters, setFilters] = useState(defaultValue);

  const handleFilterUpdate = (field, value, dropOtherValues = false) => {
    if (dropOtherValues)
      setFilters({ [field]: value });
    else
      setFilters({ ...filters, [field]: value });
  };

  const dropFilterAction = () => {
    setFilters(defaultValue);
  };

  useEffect(() => {
    if(Object.values(filters).filter(item => item !== '').length === 0 && !isEmpty(filters))
      dropFilterAction();
  }, [filters]);

  return {
    filters,
    setFilters,
    dropFilterAction,
    handleFilterUpdate,
  };
};

export default useFilter;
