import React from 'react';
import { Form, useFormikContext } from 'formik';
import { Stepper, Step, StepLabel, StepContent } from '@material-ui/core';
import Step0 from './Step0';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import DocButton from '../DocButton/DocButton';
import LinkButton from '../DocButton/LinkButton';
import PAGES from '../../helpers/hooks/pages';
import './BookingEngine.scss';

const BookingEngineForm = ({
  activePassenger,
  activeStep,
  handleBack,
  steps,
  items,
  defaultTimezone,
  bookingUsersQuantity = 0,
  isEdit = false,
  status,
  dropTimer,
  timer,
  source = '',
  isCustomerEdit = false,
  createdAppointmentId,
  isPharmacy = false,
  isBookingSkip = false,
  totalAvailableQuantity = 0,
  ...restProps
}) => {
  const isLastStep = activeStep === steps.length - 1;
  const { values: { numberOfPeople }, isSubmitting } = useFormikContext();
  const page = PAGES.find(({ orderSource }) => orderSource === source);
  const buyLink = page && page.url ? page.url : `${process.env.REACT_APP_WEBSITE_LINK}/shop`;
  const stepsComponents = [
    ...(isCustomerEdit ? [] : [<Step0
      source={source}
      items={items}
      isEdit={isEdit}
      isPharmacy={isPharmacy}
      isBookingSkip={isBookingSkip}
      bookingUsersQuantity={bookingUsersQuantity}
    />]),
    <Step1 />,
    ...(isBookingSkip ? [] : [
      <Step2
        defaultTimezone={defaultTimezone}
        isPharmacy={isPharmacy}
        dropTimer={dropTimer}
        timer={timer}
      />,
    ]),
    ...(isCustomerEdit ? [] : [<Step3
      activePassenger={activePassenger}
      isPharmacy={isPharmacy}
      isEdit={isEdit}
    />]),
    <Step4
      isBookingSkip={isBookingSkip}
      status={status}
      defaultTimezone={defaultTimezone}
    />,
    <Step5
      isBookingSkip={isBookingSkip}
      defaultTimezone={defaultTimezone}
    />,
  ];

  return (
    <Form {...restProps}>
      <Stepper activeStep={activeStep} orientation='vertical'>
        {steps.map((label, i) => (
          <Step key={label}>
            <StepLabel>{label}{(steps[activeStep] === 'Passenger Details' && i === activeStep) && ` ${activePassenger + 1}`}</StepLabel>
            <StepContent>
              {stepsComponents[activeStep]}
              <div className='row form-action-buttons flex-end'>
                {(activeStep > 0 && !steps[activeStep].includes('Confirmation')) && (
                  <DocButton
                    flat
                    text='Back'
                    color="grey first-btn"
                    disabled={isSubmitting}
                    onClick={handleBack}
                  />
                )}
                {isLastStep ? (
                  <>
                    {isCustomerEdit ? (
                      <LinkButton
                        text="Buy new one"
                        color='green action-btn'
                        linkSrc={buyLink}
                      />
                    ) : (
                      <LinkButton
                        text={isBookingSkip ? 'Activate your kit' : 'Back to Home'}
                        color='green action-btn'
                        linkSrc={isBookingSkip ? `/register-kit/${createdAppointmentId}` : isEdit ? '/customer_services/dashboard' : process.env.REACT_APP_WEBSITE_LINK}
                      />
                    )}
                    {(totalAvailableQuantity > numberOfPeople && !isEdit) && (
                      <DocButton
                        text='Book next appointment'
                        color='green action-btn last-btn'
                        onClick={() => {
                          if (typeof window !== 'undefined') window.location.reload();
                        }}
                      />
                    )}
                  </>
                ) : (
                  <DocButton
                    text='Confirm'
                    disabled={isSubmitting}
                    type="submit"
                    color="green action-btn"
                  />
                )}
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Form>
  );
};

export default BookingEngineForm;
