export const PRACTITIONER = 'practitioner';
export const CUSTOMER_SERVICE = 'customer_services';
export const SHIFT_MANAGER= 'shift_manager';
export const SUPER_ADMIN= 'super_admin';

export const APPOINTMENT_RELATED_ROLES = [
  PRACTITIONER,
  SUPER_ADMIN,
  SHIFT_MANAGER,
];

export const LIVE_APPOINTMENT_RELATED_ROLES = [
  PRACTITIONER,
  SHIFT_MANAGER,
];

export const DOCTORS_MANAGEMENT_ROLES = [
  SUPER_ADMIN,
  SHIFT_MANAGER,
];
