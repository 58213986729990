import { useEffect, useState } from 'react';

const useTwilioVoiceCall = (identity = 'Practitioner') => {
  const [token, setToken] = useState();

  const getToken = async () => {
    const data = await fetch('/voice/token', {
      method: 'POST',
      body: JSON.stringify({
        identity: encodeURIComponent(identity),
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(res => res.json()).catch((err) => console.log(err));
    setToken(data.token);
  };

  useEffect(() => {
    getToken();
  }, [identity]);


  return ({
    token,
  });
};

export default useTwilioVoiceCall;
