import React from 'react';
import { Link } from 'react-router-dom';
import UserMenu from './UserMenu';
import './Navigation.scss';
import ClaimableNotification from './ClaimableNotification';

const docIcon = require('../../assets/images/icons/dochq-logo-rect-white.svg');
const vistaLogo = require('../../assets/images/vista-logo.png');

const TopNavigation = ({
  title,
  isAuthenticated,
  user,
  role = '',
  logout,
  token,
  // eslint-disable-next-line no-unused-vars
  ...rest
}) => {
  const isVista = window.location.href.includes('vista');

  return (
    <div className='top-navigation-container'>
      <div style={{ display: 'flex' }}>
        <div className='practice-logo'>
          <Link to={`/${typeof role === 'object' ? role && role.name : role}/dashboard`}>
            <img src={docIcon} alt='DocHQ Icon' />
          </Link>
        </div>
        {isVista && (
          <div className='practice-logo vista-logo'>
            <img src={vistaLogo} alt='Vista Health'/>
          </div>
        )}
      </div>
      <h1 className='page-title'>{title}</h1>
      {isAuthenticated && (
        <>
          <ClaimableNotification title={title} token={token} />
          <UserMenu
            user={user}
            logout={logout}
          />
        </>
      )}
    </div>
  );
};

export default TopNavigation;
