import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { get } from 'lodash';
import FullScreenOverlay from '../components/FullScreenOverlay/FullScreenOverlay';
import DocButton from '../components/DocButton/DocButton';
import { PatientHeader } from '../components/VideoCall/TwillioVideoCall';
import LinkButton from '../components/DocButton/LinkButton';
import Box from '../components/TwilioVideo/Box';
import bookingService from '../services/bookingService';
import { ddMMyyyy } from '../helpers/formatDate';
import getURLParams from '../helpers/getURLParams';
import AppointmentContextProvider from '../context/AppointmentContext';
import '../assets/css/Meeting.scss';

const Meeting = () => {
  const [step, setStep] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [invalidLink, setIsInvalidLink] = useState(false);
  const [videoCallToken, setVideoCallToken] = useState('');
  const [isEarly, setIsEarly] = useState();
  const [userMedia, setUserMedia] = useState(false);
  const [questionsVisible, setQuestionsVisible] = useState(true);
  const [isEnglish, setIsEnglish] = useState(true);
  const [isVista, setIsVista] = useState(false);
  const [appointmentInfo, setAppointmentInfo] = useState();
  const params = getURLParams(window.location.href);
  const appointmentId = params['appointmentId'];
  const videoType = get(appointmentInfo, 'type', '');
  const skiptime = params['skiptime'];
  const toc_accept = true;
  const marketing_accept = false;

  const getAppointmentInfo = async () => {
    setIsLoading(true);
    await bookingService.getAppointmentInfo(appointmentId)
      .then(result => {
        if (result.success && result.appointments) {
          setAppointmentInfo(result.appointments);
          const now = new Date();
          const appointmentTime = new Date(result.appointments.start_time);
          setIsEarly(Math.round((((appointmentTime.getTime() - now.getTime()) / 1000) / 60)) > 30);
          const language = !!result.appointments && result.appointments.language;
          const isVistaType = (!!result.appointments && result.appointments.type) === 'video_gp';
          setIsVista(isVistaType);
          setIsEnglish(isVistaType ? true : language === 'EN');
          setIsLoading(false);
        } else setIsInvalidLink(true);
      }).catch(err => {
        console.log(err);
        setIsInvalidLink(true);
      });
    setIsLoading(false);
  };

  useEffect(() => {
    getAppointmentInfo();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    if (isEarly === false || !!skiptime) {
      navigator.getUserMedia({
        video: true,
        audio: true,
      },
      () => setUserMedia(true),
      () => setUserMedia(false),
      );
    }
    setIsLoading(false);
  }, [isEarly]);

  useEffect(() => {
    if (videoType && appointmentInfo && !isVista) {
      videoType === 'video_gp_dochq' ? setStep(2) : setStep(1);
    }
  }, [appointmentInfo]);

  const increaseStep = (value) => setStep(step + value);
  const displayContent = () => {
    if (questionsVisible) {
      if (invalidLink) {
        return (
          <InvalidLinkMessage />
        );
      }
      if ((isEarly && !skiptime)) {
        return (
          <AppointmentSummary
            isVista={isVista}
            isEnglish={isEnglish}
            date={!!appointmentInfo && appointmentInfo.start_time}
          />
        );
      }

      if (!userMedia) {
        return (
          <CameraMicrophoneCheck
            isEnglish={isEnglish}
          />
        );
      }
      if (isVista) {
        switch (step) {
        case 1: return <QuietSpace isEnglish={isEnglish} next={() => increaseStep(1)} />;
        case 2: return <TestKit isEnglish={isEnglish} next={() => {
          increaseStep(1);
          bookingService
            .updateTerms(appointmentId, {
              toc_accept,
            });
        }} />;
        default:
          setQuestionsVisible(false);
          return null;
        }
      } else {
        switch (step) {
        // case 1: return <DelphinDataSharingPolicies isEnglish={isEnglish} next={(value) => {
        // 	setMarketing_accept(value);
        // 	increaseStep(1);
        // }} />;
        case 1: return <QuietSpace isEnglish={isEnglish} next={() => increaseStep(1)} />;
        case 2: return <TestKit isEnglish={isEnglish} next={() => {
          increaseStep(1);
          bookingService
            .updateTerms(appointmentId, {
              toc_accept: toc_accept.toString(),
              marketing_accept: marketing_accept.toString(),
            });
        }} />;
        default:
          setQuestionsVisible(false);
          return null;
        }
      }
    }
  };

  return !isLoading && (
    <AppointmentContextProvider>
      {questionsVisible ? (
        <React.Fragment>
          <PatientHeader />
          <FullScreenOverlay
            isVisible={questionsVisible}
            content={displayContent()}
          />
        </React.Fragment>
      ) : (
        <Box
          token="token"
          isNurse={false}
          appointmentInfo={appointmentInfo}
          isEnglish={isEnglish}
          videoCallToken={videoCallToken}
          setVideoCallToken={setVideoCallToken}
        />
      )}
    </AppointmentContextProvider>
  );
};

export default Meeting;

const AppointmentSummary = ({ date, isVista, isEnglish }) => (
  <div>
    <h2>{isEnglish ? 'Appointment Summary' : 'Terminübersicht'}</h2>
    <p><b>{isEnglish ? 'Selected date' : 'Ausgewähltes Datum'}: </b>{ddMMyyyy(date)}</p>
    <p><b>{isEnglish ? 'Selected date' : 'Ausgewählte Zeit'}: </b>{format(new Date(date || ''), 'p')} ({get(Intl.DateTimeFormat().resolvedOptions(), 'timeZone', 'local time')})</p>
    {isEnglish ? (
      <p>Please, make sure you click on this link at least <b>5 minutes before</b> your actual appointment.</p>
    ) : (
      <p>	Bitte stellen Sie sicher, dass Sie mindestens <b>5 Minuten vor</b> Ihrem eigentlichen Termin auf diesen Link klicken.</p>
    )}
    {isVista && (
      <>
        <p>If you need to cancel or modify your appointment, please contact us at: <b>vistasupport@dochq.co.uk</b></p>
        <div className='row center'>
          <LinkButton
            text='Back to Home'
            color='green'
            linkSrc='/patient/dashboard'
          />
        </div>
      </>
    )}
  </div>
);

const CameraMicrophoneCheck = ({ isEnglish }) => (
  <h3>
    {isEnglish
      ? 'Please make sure you enable your camera and microphone before the appointment.'
      : 'Bitte stellen Sie sicher, dass Sie Ihre Kamera und Ihr Mikrofon vor dem Termin aktivieren.'
    }
  </h3>
);

const InvalidLinkMessage = () => (
  <h3>
		This appointment has been cancelled.<br />
		Please look for your new appointment link in your emails from DocHQ.<br />
		If you believe this is an error, please contact customer services at support@dochq.co.uk
  </h3>
);

const TestKit = ({ isEnglish, next }) => {
  const [ready, setReady] = useState(true);
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {ready ? (
        <h3>{isEnglish ? 'Do you have your test kit with you?' : 'Haben Sie Ihr Testkit dabei?'}</h3>
      ) : (
        isEnglish ? (
          <h3>
						Your test kit is required for this appointment.<br />
						Have you got it with you now?
          </h3>
        ) : (
          <h3>
						Ihr Testkit wird für diesen Termin benötigt.<bt />
						Haben Sie es jetzt bei sich?
          </h3>
        )
      )}
      <div style={{ paddingTop: '20px', textAlign: 'center' }}>
        {ready && (
          <DocButton
            color='pink'
            text={isEnglish ? 'No' : 'Nein'}
            onClick={() => setReady(false)}
            style={{ margin: '5px' }}
          />
        )}
        <DocButton
          color='green'
          text={isEnglish ? 'Yes' : 'Ja'}
          onClick={next}
          style={{ margin: '5px' }}
        />
      </div>
    </div>
  );
};

const QuietSpace = ({ isEnglish, next }) => {
  const [ready, setReady] = useState(true);
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {ready ? (
        <h3>{isEnglish ? 'Are you positioned in a quiet space?' : 'Sind Sie an einem ruhigen Ort positioniert?'}</h3>
      ) : (
        <h3>{isEnglish ? 'I am in the quietest space I can find.' : 'Ich bin in dem ruhigsten Raum, den ich finden kann.'}</h3>
      )}
      <div style={{ paddingTop: '20px', textAlign: 'center' }}>
        {ready && (
          <DocButton
            color='pink'
            text={isEnglish ? 'No' : 'Nein'}
            onClick={() => setReady(false)}
            style={{ margin: '5px' }}
          />
        )}
        <DocButton
          color='green'
          text={isEnglish ? 'Yes' : 'Ja'}
          onClick={next}
          style={{ margin: '5px' }}
        />
      </div>
    </div>
  );
};

