import React, { useState, useEffect } from 'react';
import { Form, Field, useFormikContext, FieldArray } from 'formik';
import { Box, Grid, InputLabel, Select, FormControl, MenuItem, Divider, Typography, FormGroup, FormControlLabel, Checkbox, FormLabel, RadioGroup, Radio } from '@material-ui/core';
import LinkButton from '../../DocButton/LinkButton';
import DocButton from '../../DocButton/DocButton';
import Input from '../../FormComponents/Input';
import adminService from '../../../services/adminService';
import './ProductForm.scss';

const ProductForm = ({
  token,
  isView = false,
  isEdit,
  setIsEdit,
  ...restProps
}) => {
  const [tagsOptions, setTagsOptions] = useState([]);
  const [colourOptions, setColourOptions] = useState([]);
  const [isBookable, setIsBookable] = useState(false);
  const { values: { points, bookable }, setFieldValue } = useFormikContext();

  const getOptions = async () => {
    await adminService.getTags(token)
      .then((result) => {
        if (result.success && result.tags)
          setTagsOptions(result.tags);
      }).catch((err) => console.log(err.error));
    await adminService.getColours(token)
      .then((result) => {
        if (result.success && result.colours)
          setColourOptions(result.colours);
      }).catch((err) => console.log(err.error));
  };

  useEffect(() => {
    getOptions();
  }, []);

  useEffect(() => {
    setIsBookable(bookable);
  }, [bookable, setFieldValue]);

  return (
    <Form {...restProps}>
      <Box p={8} className='product-form'>
        <Grid container justify="space-between" alignItems="flex-end">
          {isView && (
            <div className='row space-between'>
              <LinkButton
                text="Back to Product list"
                color="green"
                linkSrc="/super_admin/product-management"
              />
              <DocButton
                text={isEdit ? 'Cancel' :'Edit'}
                color={isEdit ? 'pink' : 'green'}
                onClick={() => setIsEdit(!isEdit)}
              />
            </div>
          )}
          <Grid item xs={12}>
            <Typography>Internal Product Info</Typography>
            <div className="row">
              <Field name="country" >
                {({ field, meta }) => (
                  <Input
                    error={!!meta.error}
                    touched={meta.touched}
                    helperText={(meta.error && meta.touched) && meta.error}
                    label="Country"
                    id="country"
                    required
                    disabled={!isEdit}
                    variant="standard"
                    type="text"
                    {...field}
                  />
                )}
              </Field>
            </div>
            <div className="row">
              <Field name="tags">
                {({ field, meta }) => (
                  <FormControl
                    variant='filled'
                    style={{ width: '100%' }}
                  >
                    <InputLabel
                      htmlFor="grouped-select"
                    >
                      Tags
                    </InputLabel>
                    <Select
                      multiple
                      error={!!meta.error}
                      touched={meta.touched}
                      disabled={!isEdit}
                      placeholder="Tags"
                      helperText={(meta.error && meta.touched) && meta.error}
                      {...field}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 250,
                          },
                        },
                      }}
                    >
                      {tagsOptions.map((name) => (
                        <MenuItem
                          key={name}
                          value={name}
                        >
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Field>
            </div>
            <Divider style={{ marginTop: 10, marginBottom: 10 }} />
            <Typography component="h2">Shop page Product Info</Typography>
            <div className="row">
              <Field name="card_colour">
                {({ field, meta }) => (
                  <FormControl
                    variant='filled'
                    style={{ width: '100%' }}
                  >
                    <InputLabel
                      htmlFor="grouped-select"
                    >
                      Card Colour
                    </InputLabel>
                    <Select
                      error={!!meta.error}
                      touched={meta.touched}
                      disabled={!isEdit}
                      placeholder="Card Colour"
                      classes={{
                        root: `color--${field.value}`,
                      }}
                      helperText={(meta.error && meta.touched) && meta.error}
                      {...field}
                    >
                      {colourOptions.map(({ name }) => (
                        <MenuItem
                          key={name}
                          value={name}
                          classes={{
                            root: `color--${name}`,
                          }}
                        >
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Field>
            </div>
            <div className="row">
              <Field name="service" >
                {({ field, meta }) => (
                  <Input
                    error={!!meta.error}
                    touched={meta.touched}
                    helperText={(meta.error && meta.touched) && meta.error}
                    label="Product Service"
                    id="title"
                    disabled={!isEdit}
                    variant="standard"
                    type="text"
                    {...field}
                  />
                )}
              </Field>
            </div>
            <div className="row">
              <Field name="title" >
                {({ field, meta }) => (
                  <Input
                    error={!!meta.error}
                    touched={meta.touched}
                    helperText={(meta.error && meta.touched) && meta.error}
                    label="Product Title"
                    id="title"
                    required
                    disabled={!isEdit}
                    variant="standard"
                    type="text"
                    {...field}
                  />
                )}
              </Field>
            </div>
            <div className="row">
              <Field name="sub_title" >
                {({ field, meta }) => (
                  <Input
                    error={!!meta.error}
                    touched={meta.touched}
                    helperText={(meta.error && meta.touched) && meta.error}
                    label="Product Sub Title"
                    id="title"
                    disabled={!isEdit}
                    variant="standard"
                    type="text"
                    {...field}
                  />
                )}
              </Field>
            </div>
            <div className="row">
              <Field name="description" >
                {({ field, meta }) => (
                  <Input
                    error={!!meta.error}
                    touched={meta.touched}
                    helperText={(meta.error && meta.touched) && meta.error}
                    label="Product Description"
                    id="description"
                    multiline
                    maxRows={4}
                    disabled={!isEdit}
                    variant="standard"
                    type="text"
                    {...field}
                  />
                )}
              </Field>
            </div>
            <FieldArray name="points">
              {(arrayHelpers) => (
                <>
                  <div className="row space-between">
                    <Typography component="h3">
                      Bullets
                    </Typography>
                    {isEdit && (
                      <DocButton text="Add item" color="green" onClick={() => arrayHelpers.push({ text: '', colour: 'doc-black' })} />
                    )}
                  </div>
                  {points.map((_, indx) => (
                    <>
                      <div className='row space-between'>
                        <div style={{ maxWidth: '40%'}}>
                          <Field name={`points[${indx}].text`}>
                            {({ field, meta }) => (
                              <Input
                                {...field}
                                error={!!meta.error && meta.touched}
                                touched={meta.touched}
                                helperText={(meta.error && meta.touched) && meta.error}
                                type="text"
                                multiline
                                variant="standard"
                                maxRows={4}
                                disabled={!isEdit}
                                required
                                label="Text"
                              />
                            )}
                          </Field>
                        </div>
                        <div style={{ minWidth: '40%' }}>
                          <Field name={`points[${indx}].colour`}>
                            {({ field, meta }) => (
                              <FormControl
                                variant='filled'
                                style={{ width: '100%' }}
                              >
                                <InputLabel
                                  required
                                  htmlFor="grouped-select"
                                >
                                  Text Colour
                                </InputLabel>
                                <Select
                                  error={!!meta.error}
                                  touched={meta.touched}
                                  disabled={!isEdit}
                                  placeholder="Colour"
                                  variant="standard"
                                  defaultValue="doc-black"
                                  classes={{
                                    root: `color--${field.value}`,
                                  }}
                                  helperText={(meta.error && meta.touched) && meta.error}
                                  {...field}
                                >
                                  {colourOptions.map(({ name }) => (
                                    <MenuItem
                                      key={name}
                                      value={name}
                                      classes={{
                                        root: `color--${name}`,
                                      }}
                                    >
                                      {name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            )}
                          </Field>
                        </div>
                      </div>
                      {isEdit && (
                        <div className='row flex-end'>
                          <DocButton
                            text="Delete"
                            color="pink"
                            onClick={() => arrayHelpers.remove(indx)}
                          />
                        </div>
                      )}
                    </>
                  ))}
                </>
              )}
            </FieldArray>
            <div className="row">
              <Field name="msrp">
                {({ field, meta }) => (
                  <Input
                    error={!!meta.error}
                    touched={meta.touched}
                    helperText={(meta.error && meta.touched) && meta.error}
                    label="Maximum retail price (MRP)"
                    id="msrp"
                    type="number"
                    disabled={!isEdit}
                    variant="standard"
                    required
                    {...field}
                  />
                )}
              </Field>
            </div>
            <div className="row">
              <Field name="price">
                {({ field, meta }) => (
                  <Input
                    error={!!meta.error}
                    touched={meta.touched}
                    helperText={(meta.error && meta.touched) && meta.error}
                    label="Price"
                    id="price"
                    type="number"
                    disabled={!isEdit}
                    variant="standard"
                    required
                    {...field}
                  />
                )}
              </Field>
            </div>
            <div className="row">
              <Field name="footer" >
                {({ field, meta }) => (
                  <Input
                    error={!!meta.error}
                    touched={meta.touched}
                    helperText={(meta.error && meta.touched) && meta.error}
                    label="Product Footer Text"
                    id="footer"
                    multiline
                    maxRows={6}
                    disabled={!isEdit}
                    variant="standard"
                    type="text"
                    {...field}
                  />
                )}
              </Field>
            </div>
            <div className="row">
              <Field name="discount_exempt">
                {({ field, form }) => (
                  <FormControl
                    component='fieldset'
                  >
                    <FormLabel>Is this product discount exempt?</FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            {...field}
                            onChange={event => form.setFieldValue('discount_exempt', event.target.checked)}
                            checked={field.value}
                          />
                        }
                        disabled={!isEdit}
                        label="Discount exempt"
                      />
                    </FormGroup>
                  </FormControl>
                )}
              </Field>
            </div>
            <div className="row">
              <Field name="vat_exempt">
                {({ field, form }) => (
                  <FormControl
                    component='fieldset'
                  >
                    <FormLabel>Is this product VAT exempt?</FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            {...field}
                            onChange={event => form.setFieldValue('vat_exempt', event.target.checked)}
                            checked={field.value}
                          />
                        }
                        disabled={!isEdit}
                        label="VAT exempt"
                      />
                    </FormGroup>
                  </FormControl>
                )}
              </Field>
            </div>
            <div className="row">
              <Field name="shippable">
                {({ field, form }) => (
                  <FormControl
                    component='fieldset'
                  >
                    <FormLabel>Is this a physical product that requires shipping?</FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            {...field}
                            onChange={event => form.setFieldValue('shippable', event.target.checked)}
                            checked={field.value}
                          />
                        }
                        disabled={!isEdit}
                        label="Shippable"
                      />
                    </FormGroup>
                  </FormControl>
                )}
              </Field>
            </div>
            <div className="row">
              <Field name="bookable">
                {({ field, form }) => (
                  <FormControl
                    component='fieldset'
                  >
                    <FormLabel>Does this product include a video consultation?</FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            {...field}
                            onChange={event => {
                              form.setFieldValue('bookable', event.target.checked);
                            }}
                            checked={field.value}
                          />
                        }
                        disabled={!isEdit}
                        label="Bookable"
                      />
                    </FormGroup>
                  </FormControl>
                )}
              </Field>
            </div>
            
            {isBookable === true && (
              <div className="row">
                <Field name="pre_book">
                  {({ form }) => {
                    return (
                      <FormControl
                        component='fieldset'
                      >
                        <FormLabel>When is this appointment bookable?</FormLabel>
                        <RadioGroup
                          defaultValue="after_booking"
                          onChange={event => {
                            if (event.target.value === 'pre_book') {
                              form.setFieldValue('pre_book', true);
                            } else {
                              form.setFieldValue('pre_book', false);
                            }
                          }}
                        >
                          <FormControlLabel
                            control={
                              <Radio
                                disabled={!isEdit}
                              />
                            }
                            label="Before the purchase"
                            value="pre_book"
                          />
                          <FormControlLabel
                            control={
                              <Radio
                                disabled={!isEdit}
                              />
                            }
                            label="After the purchase"
                            value="after_booking"
                          />
                        </RadioGroup>
                      </FormControl>
                    );
                  }
                  }
                </Field>
              </div>
            )}
          </Grid>
        </Grid>
        {isEdit && (
          <div className='row flex-end'>
            <DocButton
              text='Save'
              color='green'
              type="submit"
            />
          </div>
        )}
      </Box>
    </Form>
  );
};

export default ProductForm;
