import React from 'react';
import { get } from 'lodash';
import { format } from 'date-fns';
import { Field, useFormikContext } from 'formik';
import { Alert } from '@material-ui/lab';
import {
  Divider,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from '@material-ui/core';
import { ddMMyyyy, formatTimeSlotWithTimeZone } from '../../helpers/formatDate';
import { FIT_TO_FLY_PCR, RECONSULT_DAY_2_ANTIGEN_US, DAY_2_ANTIGEN_US } from '../../helpers/productsWithAdditionalInfo';
import bookingFormModel from './bookingFormModel';

const Step4 = ({ isBookingSkip, status, defaultTimezone }) => {
  const {
    formField: {
      tocAccept,
    }
  } = bookingFormModel;
  const {
    values: {
      appointmentDate,
      selectedSlot,
      passengers,
      testType: {
        sku,
        title,
        tags = [],
      },
      travelDate,
      travelTime,
      timezone: timezoneValue,
    },
  } = useFormikContext();
  const isPCR = sku === FIT_TO_FLY_PCR;
  const isBookedAfterFlight = tags.includes('after_flight');
  const isUsProduct = sku === RECONSULT_DAY_2_ANTIGEN_US || sku === DAY_2_ANTIGEN_US;
  const timezone = ((isBookedAfterFlight || isPCR) && !isUsProduct) ? defaultTimezone.timezone : timezoneValue;

  return (
    <React.Fragment>
      {!isBookingSkip && (
        <>
          <div className='row no-margin'>
            <p>
              <strong>Selected Product:&nbsp;</strong>
              {title}
            </p>
          </div>
          {!isPCR && (
            <div className='row no-margin'>
              <p>
                <strong>Traveling from:&nbsp;</strong>
                {timezone}
              </p>
            </div>
          )}
          <div className='row no-margin'>
            <p>
              <strong>Travel Date and Time:&nbsp;</strong>
              {ddMMyyyy(travelDate)} {format(travelTime, 'p')}
            </p>
          </div>
          <div className='row no-margin'>
            <p>
              <strong>Appointment Date and Time:&nbsp;</strong>
              {ddMMyyyy(appointmentDate)} {formatTimeSlotWithTimeZone(selectedSlot.start_time, timezone)} - {formatTimeSlotWithTimeZone(selectedSlot.end_time, timezone)} ({timezone})
            </p>
          </div>
          <Divider style={{ width: '45%' }} />
        </>
      )}
      {passengers.map(({
        firstName,
        lastName,
        email,
        phone,
        dateOfBirth,
        ethnicity,
        sex,
        countryCode,
        passportNumber,
      }, i) => (
        <div key={i}>
          <div>
            <div className='row no-margin' id={`passenger-name-${i + 1}`}>
              <p>
                <strong>Passenger Name {i + 1}:&nbsp;</strong>
                {firstName} {lastName}
              </p>
            </div>
            <div className='row no-margin' id={`passenger-email-${i + 1}`}>
              <p>
                <strong>Email:&nbsp;</strong>
                {email}
              </p>
            </div>
            <div className='row no-margin' id={`passenger-phone-${i + 1}`}>
              <p>
                <strong>Phone:&nbsp;</strong>
                {get(countryCode, 'label', '')}{phone.trim()}
              </p>
            </div>
            <div className='row no-margin' id={`passenger-date-of-birth-${i + 1}`}>
              <p>
                <strong>Date Of Birth:&nbsp;</strong>
                {format(dateOfBirth, 'dd/MM/yyyy')}
              </p>
            </div>
            <div className='row no-margin' id={`passenger-ethnicity-${i + 1}`}>
              <p>
                <strong>Ethnicity:&nbsp;</strong>
                {ethnicity}
              </p>
            </div>
            <div className='row no-margin' id={`passenger-sex-${i + 1}`}>
              <p>
                <strong>Sex:&nbsp;</strong>
                {sex}
              </p>
            </div>
            <div className='row no-margin' id={`passenger-passport-${i + 1}`}>
              <p>
                <strong>Passport/National identity card number:&nbsp;</strong>
                {passportNumber}
              </p>
            </div>
          </div>
          <Divider style={{ width: '45%' }} />
        </div>
      ))}
      <div className='row'>
        <Field name={tocAccept.name}>
          {({ field, form, meta }) => (
            <FormControl
              component='fieldset'
            >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      {...tocAccept}
                      {...field}
                      error={!!meta.error}
                      touched={meta.touched}
                      helperText={(meta.error && meta.touched) && meta.error}
                      onChange={event => form.setFieldValue(tocAccept.name, event.target.checked)}
                      value={field.value}
                    />
                  }
                  label={
                    <p>
											I have read and agree to{' '}
                      <a
                        target='_blank'
                        rel='noopener noreferrer'
                        href='/en/consultation/terms'
                      >
												DocHQ Limited Medical Terms and Conditions
                      </a>
                    </p>
                  }
                />
              </FormGroup>
            </FormControl>
          )}
        </Field>
      </div>
      {typeof status !== 'undefined' && (
        <div className='row center'>
          <Alert severity={status.severity} variant='outlined'>
            {status.message}
          </Alert>
        </div>
      )}
    </React.Fragment>
  );
};

export default Step4;
