import React, { useState } from 'react';
import { get } from 'lodash';
import { format } from 'date-fns';
import Collapse from '@material-ui/core/Collapse';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TablePagination from '@material-ui/core/TablePagination';
import Grid from '@material-ui/core/Grid';
import LinkButton from '../../DocButton/LinkButton';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import InputFilter from '../../InputFilter/InputFilter';
import SelectFilter from '../../SelectFilter/SelectFilter';
import '../../Tables/Tables.scss';

const styles = {
  tableText: {
    fontSize: 16,
  },
  tableTextHeader: {
    fontSize: 18,
    cursor: 'pointer',
  },
  bntStyles: {
    marginLeft: '10px',
    marginTop: '0px',
    marginRight: '10px',
    boxSizing: 'border-box',
    maxWidth: '40%',
  },
  mainContainer: {
    width: '100%',
    cursor: 'pointer',
  },
  snackbar: {
    color: '#FFF',
  }
};

const TABLE_HEADERS = [
  {
    titleName: 'Project',
    propertyName: 'project',
    isSortable: true,
    align: 'left',
  },
  {
    titleName: 'Code',
    propertyName: 'code',
    isSortable: true,
    align: 'left',
  },
  {
    titleName: 'Total uses',
    propertyName: 'uses',
    isSortable: true,
    align: 'left',
  },
  {
    titleName: 'Used times',
    propertyName: 'uses',
    isSortable: true,
    align: 'left',
    isUsed: true,
  },
  {
    titleName: 'Left uses',
    propertyName: 'uses',
    isSortable: true,
    align: 'left',
    isUsed: true,
  },
  {
    titleName: 'Value',
    propertyName: 'value',
    isSortable: true,
    align: 'left',
  },
  {
    titleName: 'Generated',
    propertyName: 'created_at',
    isSortable: true,
    align: 'left',
  },
  {
    titleName: 'Valid From',
    propertyName: 'active_from',
    isSortable: true,
    align: 'left',
  },
  {
    titleName: 'Valid Until',
    propertyName: 'active_to',
    isSortable: true,
    align: 'left',
  },
  {
    titleName: 'Actions',
    propertyName: '',
    isSortable: false,
    align: 'right',
    isUsed: true,
  },
];

const DiscountTable = ({
  isUsed = false,
  discounts = [],
  loading,
  page,
  sort,
  filters,
  sortField,
  sortOrder,
  rowsPerPage,
  totalCount,
  projectOptions,
  handleFilterUpdate,
  handleChangeRowsPerPage,
  handleChangePage,
}) => {
  const [checked, setChecked] = useState(false);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  const preparedTableHeaders = TABLE_HEADERS.filter((item) => {
    const isUsedCell = get(item, 'isUsed');
    if (isUsedCell && isUsed)
      return true;
    else if (!isUsed && isUsedCell)
      return false;
    else return true;
  });

  return (
    <div className='doc-container tables' style={{ justifyContent: 'unset' }}>
      <Grid container alignItems='center' justify="space-between" style={styles.mainContainer}>
        <h2 style={{ minWidth: 200 }}>{isUsed && 'Used '}Discount List</h2>
        <InputFilter
          entity="Code"
          dropOtherValues
          filters={filters}
          fieldName="code"
          handleFilterUpdate={handleFilterUpdate}
        />
        <SelectFilter
          objectOptions
          dropOtherValues
          entity="Project"
          filters={filters}
          fieldName="project"
          options={projectOptions}
          style={{ maxWidth: 200 }}
          handleFilterUpdate={handleFilterUpdate}
        />
        {checked ? <KeyboardArrowUpIcon onClick={handleChange} /> : <KeyboardArrowDownIcon onClick={handleChange} />}
      </Grid>
      <Collapse in={checked}>
        <TableContainer
          style={{
            marginBottom: '40px',
          }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {preparedTableHeaders.map(({ titleName, propertyName, isSortable, align }, index) => (
                  <TableCell
                    key={index}
                    align={align}
                    style={isSortable ? styles.tableTextHeader : styles.tableText}
                  >
                    {isSortable ? (
                      <TableSortLabel
                        active={sortField === propertyName}
                        direction={sortField === propertyName ? sortOrder : ''}
                        onClick={() => sort(propertyName)}
                      >
                        {titleName}
                      </TableSortLabel>
                    ) : titleName}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell>
                    <LoadingSpinner />
                  </TableCell>
                  <TableCell />
                  <TableCell />
                  {isUsed && (
                    <TableCell />
                  )}
                </>
              ) : discounts.length > 0 ? (
                discounts.map((discount, indx) => {
                  const uses = get(discount, 'uses', 0);
                  const used = get(discount, 'used_times', 0);
                  const left = uses - used;
                  return (
                    <TableRow key={indx}>
                      <TableCell
                        align='left'
                        style={{ ...styles.tableText }}
                      >
                        {get(discount, 'project', '')}
                      </TableCell>
                      <TableCell
                        align='left'
                        style={{ ...styles.tableText }}
                      >
                        {get(discount, 'code', '')}
                      </TableCell>
                      <TableCell
                        align='left'
                        style={{ ...styles.tableText }}
                      >
                        {uses}
                      </TableCell>
                      {isUsed && (
                        <>
                          <TableCell
                            align='left'
                            style={{ ...styles.tableText }}
                          >
                            {used}
                          </TableCell>
                          <TableCell
                            align='left'
                            style={{ ...styles.tableText }}
                          >
                            {left}
                          </TableCell>
                        </>
                      )}
                      <TableCell
                        align='left'
                        style={{ ...styles.tableText }}
                      >
                        {get(discount, 'value', '')}{get(discount, 'type', '') === 'percentage' ? '%' : '£'}
                      </TableCell>
                      <TableCell
                        align='left'
                        style={{ ...styles.tableText }}
                      >
                        {format(new Date(get(discount, 'created_at', new Date().getTime()) * 1000), 'dd/MM/yyyy p')}
                      </TableCell>
                      <TableCell
                        align='left'
                        style={{ ...styles.tableText }}
                      >
                        {format(new Date(get(discount, 'active_from', new Date())), 'dd/MM/yyyy')}
                      </TableCell>
                      <TableCell
                        align='left'
                        style={{ ...styles.tableText }}
                      >
                        {format(new Date(get(discount, 'active_to', new Date())), 'dd/MM/yyyy')}
                      </TableCell>
                      {isUsed && (
                        <TableCell align='right' style={{ ...styles.tableText }}>
                          <div style={{ display: 'inline-flex' }}>
                            <div style={{ margin: '0 10px' }}>
                              <LinkButton
                                text="View Order"
                                color='green'
                                linkSrc={`/super_admin/order-list?discount=${discount.code}`}
                              />
                            </div>
                          </div>
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })) : (
                <TableRow>
                  <TableCell style={styles.tableText}>
                    <p>No discounts to display</p>
                  </TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          page={page}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          onChangePage={handleChangePage}
          rowsPerPageOptions={[10, 25, 50, 100]}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Collapse>
    </div>
  );
};


export default DiscountTable;
