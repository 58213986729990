import React, { useState, useEffect } from 'react';
import { Device } from 'twilio-client';
import PhoneIcon from '@material-ui/icons/Phone';
import { IconButton } from '@material-ui/core';
import parsePhoneNumber from 'libphonenumber-js';
import DocButton from '../DocButton/DocButton';
import DocModal from '../DocModal/DocModal';

const states = {
  CONNECTING: 'Connecting',
  READY: 'Ready',
  ON_CALL: 'On call',
  OFFLINE: 'Offline',
};

const TwilioVoiceCall = ({ token, phoneNumber, noBtnClass, isTable }) => {
  const parsedPhoneNumber = !!phoneNumber && parsePhoneNumber(phoneNumber);
  const formattedPhoneNumber = !!parsedPhoneNumber
    && `${parsedPhoneNumber.countryCallingCode}${parsedPhoneNumber.nationalNumber}`;
  const [state, setState] = useState(states.CONNECTING);
  const [device, setDevice] = useState(null);

  useEffect(() => {
    const device = new Device();
    device.setup(token, { debug: true });

    device.on('ready', () => {
      setDevice(device);
      setState(states.READY);
    });
    device.on('connect', () => {
      console.log('Connect event');
      setState(states.ON_CALL);
    });
    device.on('disconnect', () => {
      setState(states.READY);
    });
    device.on('cancel', () => {
      setState(states.READY);
    });
    device.on('reject', () => {
      setState(states.READY);
    });

    return () => {
      device.destroy();
      setDevice(null);
      setState(states.OFFLINE);
    };
  }, [token]);

  const handleCall = () => {
    device.connect({ To: formattedPhoneNumber });
  };
  const handleHangup = () => {
    device.disconnectAll();
  };

  return (
    <div className='row space-between no-margin'>
      {!isTable && (
        <p style={{ minWidth: 'max-content' }} className='tab-row-text title-info no-margin'>Phone No Client:</p>
      )}
      <div className={noBtnClass ? '' : 'row flex-end no-margin'}>
        {isTable ? (
          !!device && !!formattedPhoneNumber && (
            <DocButton
              text="Call"
              style={{ minWidth: 60, padding: 0, height: 30, }}
              color="green"
              onClick={(event) => {
                event.preventDefault();
                try {
                  handleCall(formattedPhoneNumber);
                } catch (error) {
                  console.log(error);
                }
              }}
            />
          )
        ) : (
          <>
            <p className='no-margin'>{formattedPhoneNumber}</p>
            {!!device && !!formattedPhoneNumber && (
              <IconButton
                style={{ padding: 5 }}
                onClick={(event) => {
                  event.preventDefault();
                  try {
                    handleCall(formattedPhoneNumber);
                  } catch (error) {
                    console.log(error);
                  }
                }}
              >
                <PhoneIcon fontSize="small" className="green-text" />
              </IconButton>
            )}
          </>
        )}
      </div>
      {(device && state === states.ON_CALL) && (
        <DocModal
          isVisible
          onClose={handleHangup}
          title={`Calling to ${formattedPhoneNumber}`}
          content={
            <div className="row space-between" style={{ width: '100%' }}>
              <div>
                <p>Call status: {state}</p>
              </div>
              <DocButton
                color='pink'
                text={
                  (
                    state === 'completed'
                        || state === 'rejected'
                        || state === 'unanswered'
                        || state === 'failed'
                  ) ? 'Close' : 'Hang up'
                }
                onClick={handleHangup}
              />
            </div>
          }
        />
      )}
    </div>
  );
};

export default TwilioVoiceCall;
