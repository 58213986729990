import React, { memo, useContext } from 'react';
import { get } from 'lodash';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { AuthContext } from '../../context/AuthContext';
import PagesManagementTable from '../../components/SAComponents/Tables/PagesManagementTable';
import { PRODUCT_USER_NAMES } from '../../helpers/permissions';
import PAGES from '../../helpers/hooks/pages';

const PagesManagement = ({ role, isAuthenticated, user }) => {
  const userName = `${get(user, 'first_name', '')} ${get(user, 'last_name', '')}`;
  const { logout } = useContext(AuthContext);
  const history = useHistory();
  const logoutUser = () => {
    logout();
    history.push('/login');
  };
  if ((isAuthenticated !== true && role !== 'super_admin') || (!!user && !PRODUCT_USER_NAMES.includes(userName))) {
    logoutUser();
  }

  return (
    <Grid container justify="space-between">
      <Grid item xs={12}>
        <PagesManagementTable
          loading={false}
          pages={PAGES}
        />
      </Grid>
    </Grid>
  );
};

export default memo(PagesManagement);
