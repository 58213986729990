import React, { useState } from 'react';
import {
  Box,
  TextField,
} from '@material-ui/core';
import { get } from 'lodash';
import { ToastsStore } from 'react-toasts';
import DocButton from '../DocButton/DocButton';
import adminService from '../../services/adminService';


const UpdateProductQuantityControl = ({
  orderDetails,
  product,
  token,
  reload,
}) => {
  const oldQuantity = get(product, 'quantity');
  const productId = get(product, 'product_id');
  const [newQuantity, setNewQuantity] = useState(oldQuantity);

  const updateQuantity = () => {
    const newOrderDetails = {...orderDetails};
    newOrderDetails.items.find(({ product_id }) => product_id === productId).quantity = +newQuantity;
    newOrderDetails.basket.find(({ product_id }) => product_id === productId).quantity = +newQuantity;

    adminService.updateOrder(newOrderDetails, orderDetails.id, token).then((result) => {
      if (result.success) {
        ToastsStore.success('Quantity has been updated successfully!');
        reload();
      }
    }).catch(err => ToastsStore.error(err.error));
  };

  return (
    <Box display="flex" justifyContent="space-around" alignItems="center">
      <TextField
        autoFocus
        margin="dense"
        id="quantity"
        label="Quantity"
        type="number"
        inputProps={{
          min: 0,
        }}
        style={{ width: 115 }}
        value={newQuantity}
        onChange={(e) => setNewQuantity(e.target.value)}
      />
      {oldQuantity !== newQuantity && (
        <DocButton
          text="Update"
          color="green"
          onClick={updateQuantity}
        />
      )}
    </Box>
  );
};

export default UpdateProductQuantityControl;
