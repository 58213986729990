import { Grid, Box } from '@material-ui/core';
import React from 'react';
import { get } from 'lodash';
import { ToastsStore } from 'react-toasts';
import TwilioChatRoom from '../TwilioChatRoom/TwilioChatRoom';
import LoadingSpinner from '..//LoadingSpinner/LoadingSpinner';
import useFetchChats from '../../helpers/hooks/useFetchChats';
import chatService from '../../services/chatService';
import useTwilioClient from '../../helpers/hooks/useTwilioClient';

const LiveChats = ({ token, user, type }) => {
  const userId = get(user, 'id', 0);
  const userName = `${get(user, 'first_name', '')} ${get(user, 'last_name', '')}`;
  const {
    chats,
    loading,
    refetchData,
  } = useFetchChats({
    token,
    entity: 'member',
    entityValue: userId,
    status: 'live',
    type,
  });
  const { client } = useTwilioClient(type === 'external' ? 'practitioner' : userName);

  const closeRoom = async (chat) => {
    await chatService.updateRoom({
      token,
      id: chat.id,
      data: {
        ...chat,
        status: 'closed',
      },
    }).then(data => {
      if (data.success) {
        refetchData();
      } else ToastsStore.error(data.error);
    })
      .catch((err) => ToastsStore.error(err.error));
  };

  return (
    <Box minHeight="80vh">
      {loading ? (
        <div className="row center" style={{ height: '80vh' }}>
          <LoadingSpinner />
        </div>
      ) : (
        !!chats.length ? (
          <Grid container>
            {chats.map((chat) => (
              <Grid item key={chat.id} xs={6}>
                <TwilioChatRoom
                  token={token}
                  chat={chat}
                  userId={userId}
                  client={client}
                  userName={userName}
                  closeRoom={() => closeRoom(chat)}
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          <div className="row center" style={{ height: '80vh' }}>
            <h3>No active chats</h3>
          </div>
        )
      )}
    </Box>
  );
};

export default LiveChats;
