import React, { memo } from 'react';
import { useHistory } from 'react-router-dom';
import RotaPastAppointmentsTable from '../../components/Tables/RotaPastAppointmentsTable';

const RotaManagement = props => {
  const doctors = [
    {
      id: 1,
      first_name: 'Bruce',
      last_name: 'Wayne',
      start_time: '2021-03-19T08:30:00Z',
      end_time: '2021-03-19T17:00:00Z',
      start_in: {
        minutes: 5,
        seconds: 59,
      },
      status: 'online',
    },
    {
      id: 2,
      first_name: 'Mike',
      last_name: 'Johnson',
      start_time: '2021-03-19T08:30:00Z',
      end_time: '2021-03-19T17:00:00Z',
      start_in: {
        minutes: 4,
        seconds: 23,
      },
      status: 'offline',
    },
    {
      id: 3,
      first_name: 'Taylor',
      last_name: 'Xavier',
      start_time: '2021-03-19T08:30:00Z',
      end_time: '2021-03-19T17:00:00Z',
      start_in: {
        minutes: 20,
        seconds: 15,
      },
      status: 'offline',
    },
    {
      id: 4,
      first_name: 'Lady',
      last_name: 'Young',
      start_time: '2021-03-19T08:30:00Z',
      end_time: '2021-03-19T17:00:00Z',
      start_in: {
        minutes: 3,
        seconds: 10,
      },
      status: 'online',
    },
    {
      id: 5,
      first_name: 'Loureen',
      last_name: 'Ling',
      start_time: '2021-03-19T08:30:00Z',
      end_time: '2021-03-19T17:00:00Z',
      start_in: {
        minutes: 15,
        seconds: 11,
      },
      status: 'offline',
    },
  ];

  const history = useHistory();
  if (props.isAuthenticated !== true && props.role !== 'practitioner') {
    history.push('/login');
  }

  return (
    <RotaPastAppointmentsTable
      doctors={doctors}
    />
  );
};

export default memo(RotaManagement);
