import React, { useEffect, useState, memo } from 'react';
import moment from 'moment';
import { Grid } from '@material-ui/core';
import { ToastsStore } from 'react-toasts';
import { get } from 'lodash';
import adminService from '../../services/adminService';
import CertificatesTable from '../../components/Tables/CertificatesTable';
import bookingService from '../../services/bookingService';
import useSort from '../../helpers/hooks/useSort';
import compareFunc from '../../helpers/compareFunc';

const CertificateManagement = ({ token }) => {
  const [filter, setFilter] = useState(moment());
  const [certificate, setCertificate] = useState();
  const [certificates, setCertificates] = useState([]);
  const [approvedTestKits, setApprovedTestKits] = useState([]);
  const [loading, setLoading] = useState(false);

  const getCertificates = async () => {
    setLoading(true);
    await adminService
      .getCertificates(token, {
        from: moment(filter).utc(0).startOf('day').unix(),
        to: moment(filter).utc(0).endOf('day').unix(),
      })
      .then(data => {
        if (data.success)
          setCertificates((data.certificates || []).sort((i, j) => compareFunc(i, j, 'asc', 'created_at')));
        else
          ToastsStore.error('Error fetching certificates');
      })
      .catch(err => ToastsStore.error(err.error));
    setLoading(false);
  };

  const { sort, sortField, sortOrder } = useSort(certificates, setCertificates, getCertificates);

  const getApprovedProducts = async () => {
    await adminService.getApprovedProducts()
      .then(result => {
        if (result.success && result.kits) {
          setApprovedTestKits([...result.kits].sort(({ name: nameA }, { name: nameB }) => nameA < nameB ? -1 : nameA > nameB ? 1 : 0));
        }
      }).catch((error) => {
        console.log(error);
      });
  };

  const resendCertificate = async (certificate) => {
    const kit_id = get(certificate, 'meta_data.kit_id', '');
    const lot_id = get(certificate, 'meta_data.lot_id', '');
    const reject_notes = get(certificate, 'meta_data.kit_id', '');
    const body = {
      forename: get(certificate, 'meta_data.forename', ''),
      surname: get(certificate, 'meta_data.surname', ''),
      email: get(certificate, 'meta_data.email', ''),
      date_of_birth: get(certificate, 'meta_data.date_of_birth', ''),
      sex: get(certificate, 'meta_data.sex', ''),
      security_checked: get(certificate, 'meta_data.security_checked', ''),
      result: get(certificate, 'meta_data.result', ''),
      passport_number: get(certificate, 'meta_data.passport_number', ''),
      kit_provider: get(certificate, 'meta_data.kit_provider', ''),
      specificity: get(certificate, 'meta_data.specificity', ''),
      sensitivity:  get(certificate, 'meta_data.sensitivity', ''),
      ...(!!kit_id ? { kit_id } : {}),
      ...(!!lot_id ? { lot_id } : {}),
      ...(!!reject_notes ? { reject_notes } : {}),
    };

    await bookingService.sendResult(
      token,
      certificate.provider_id,
      body,
      certificate.recipient_id,
    ).then(data => {
      if (data.success) {
        ToastsStore.success('Certificate has been resent');
      } else {
        ToastsStore.error('Error resending certificate');
      }
    }).catch(err => ToastsStore.error(err.error));
    await getCertificates();
  };

  const editCertificate = async (certificate) => {
    setCertificate(certificate);
  };

  const handleCloseModal = async () => {
    setCertificate();
    await getCertificates();
  };

  useEffect(() => {
    getApprovedProducts();
  }, []);

  useEffect(() => {
    getCertificates();
  }, [filter]);

  return (
    <Grid container justify="space-between">
      <Grid item xs={12}>
        <CertificatesTable
          filter={filter}
          loading={loading}
          sort={sort}
          sortField={sortField}
          sortOrder={sortOrder}
          setFilter={setFilter}
          certificate={certificate}
          certificates={certificates}
          editCertificate={editCertificate}
          handleCloseModal={handleCloseModal}
          approvedTestKits={approvedTestKits}
          resendCertificate={resendCertificate}
        />
      </Grid>
    </Grid>
  );
};

export default memo(CertificateManagement);
