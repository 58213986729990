const PAGES = [
  {
    id: 1,
    title: 'DocHQ shop',
    source: 'Website',
    orderSource: 'dochq',
    url: `${process.env.REACT_APP_WEBSITE_LINK}/shop`,
  },
  {
    id: 2,
    title: 'DocHQ DE',
    source: 'Website',
    orderSource: 'dochq-de',
    url: `${process.env.REACT_APP_WEBSITE_DE_LINK}/`,
  },
  {
    id: 3,
    title: 'DocHQ US',
    source: 'Website',
    orderSource: 'dochq-us',
    url: `${process.env.REACT_APP_WEBSITE_US_LINK}/`,
  },
  {
    id: 4,
    title: 'Gov shop',
    source: 'Website',
    orderSource: 'gov',
    url: `${process.env.REACT_APP_WEBSITE_LINK}/partners/gov`,
  },
  {
    id: 5,
    title: 'Insurefor shop',
    source: 'Website',
    orderSource: 'ROCS',
    url: `${process.env.REACT_APP_WEBSITE_LINK}/partners/insurefor`,
  },
  {
    id: 6,
    title: 'OFL shop',
    source: 'Website',
    orderSource: 'ofl',
    url: `${process.env.REACT_APP_WEBSITE_LINK}/partners/ofl`,
  },
  {
    id: 7,
    title: 'CTN shop',
    source: 'Website',
    orderSource: 'ctn',
    url: `${process.env.REACT_APP_WEBSITE_LINK}/partners/ctn`,
  },
  {
    id: 8,
    title: 'AMEX UK shop',
    source: 'Website',
    orderSource: 'amex-uk',
    url: `${process.env.REACT_APP_WEBSITE_LINK}/partners/amex-uk`,
  },
  {
    id: 9,
    title: 'AMEX IT shop',
    source: 'Website',
    orderSource: 'amex-it',
    url: `${process.env.REACT_APP_WEBSITE_LINK}/partners/amex-it`,
  },
  {
    id: 10,
    title: 'GTC shop',
    source: 'Website',
    orderSource: 'gtc',
    url: `${process.env.REACT_APP_WEBSITE_LINK}/partners/global_travel_collection`,
  },
  {
    id: 11,
    title: 'OFL Booking page',
    source: 'MyHealth',
    url: '/book_test_OFL',
    orderSource: 'ofl',
  },
  {
    id: 12,
    title: 'Attraction World Booking page',
    source: 'MyHealth',
    orderSource: 'attractionworld',
    url: '/book_test_attraction_world',
  },
  {
    id: 13,
    title: 'Floridatix Booking page',
    source: 'MyHealth',
    url: '/book_test_florida_tix',
    orderSource: 'floridatix',
  },
];

export default PAGES;
